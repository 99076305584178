import React from "react";
import { useDispatch } from "react-redux";
import LoadingPage from "../../components/LoadingPage";
import ErrorPage from "../../components/ErrorPage";
import ComingSoonPage from "../../components/ComingSoonPage";
import { useTaskAdmin } from "../redux/hooks";
import { actions } from "../redux/model";
import { SRD } from "srd";
import { InputTask, MentorshipTask } from "../../server/serverTypes";
import EditTask from "../views/EditTask";

interface IAdminEditTaskContainer {
  id: string;
  userHash: string;
}

const AdminEditTaskContainer = ({ id, userHash }: IAdminEditTaskContainer) => {
  const dispatch = useDispatch();
  const taskRd = useTaskAdmin(Number(id), userHash);

  const handleUpdate = (val: {
    id: number;
    title: string;
    description: string;
    dueDate: Date;
  }) => {
    const taskObj: InputTask = {
      id: val.id,
      title: val.title,
      description: val.description,
      dueDate: val.dueDate,
      courseId: 1,
    };
    dispatch(actions.editRisingStarTask({ task: taskObj, hash: userHash }));
  };

  return SRD.match(
    {
      notAsked: () => <LoadingPage />,
      loading: () => <LoadingPage />,
      failure: (msg: Error) => <ErrorPage />,
      success: (task: MentorshipTask) => (
        <EditTask task={task} updateTask={handleUpdate} />
      ),
    },
    taskRd
  );
};

export default AdminEditTaskContainer;

import React from "react";
import { useRisingStarsProgramRD } from "../redux/hooks";
import AdminDashboard from "../views/AdminDashboard";
import { SRD, RD, notAsked, loading, failure, success } from "srd";
import { RisingStarProgram } from "../../server/serverTypes";
import LoadingPage from "../../components/LoadingPage";
import ErrorPage from "../../components/ErrorPage";

interface IAdminDashboardContainer {
  id: string;
}

const AdminDashboardContainer = ({ id }: IAdminDashboardContainer) => {
  const rd = useRisingStarsProgramRD(id, false);

  return SRD.match(
    {
      notAsked: () => <LoadingPage />,
      loading: () => <LoadingPage />,
      failure: (msg: Error) => <ErrorPage />,
      success: (risingStarProgram: RisingStarProgram) => {
        return (
          <AdminDashboard
            tasks={risingStarProgram.tasks}
            sessions={risingStarProgram.sessions}
            student={risingStarProgram.student}
            courseId={risingStarProgram.courseId}
          />
        );
      },
    },
    rd
  );
  // return <AdminDashboard />;
};

export default AdminDashboardContainer;

import React from "react";
import {
  Card,
  Grid,
  Typography,
  CardContent,
  IconButton,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemIcon,
  Popper,
  Grow,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AttachmentOutlinedIcon from "@material-ui/icons/AttachmentOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { DeleteIcon as CustomDeleteIcon, MenuDot } from "./Icons";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions as taskActions } from "../task/redux/model";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // maxWidth: 370,
    height: "100px",
    background: "#FCFAF5",
    borderRadius: "5px",
    margin: "10px 0px",
    filter: "drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.25))",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    padding: "16px !important",
    height: "100%",
  },
  cover: {
    width: 151,
  },
  header: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "140%",
    color: "#00664F",
  },
  main: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    color: "#272727",
  },
  documentText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    textDecorationLine: "underline",
    color: "#757575",
    lineHeight: "22px",
    marginLeft: "10px",
  },
  menuBtn: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  subContent: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

interface ICompletedTaskComponent {
  taskName: string;
  id: number;
  isMentee?: boolean;
}

export const CompletedTaskComponent = ({
  taskName,
  id,
  isMentee = false,
}: ICompletedTaskComponent) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any, action: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);

    if (action === "delete") {
      handleDelete();
    }
    if (action === "complete") {
      handleUpdate();
    }
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleDelete = () => {
    dispatch(taskActions.deleteMentorshipTask({ id: id }));
  };

  const handleUpdate = () => {
    //
  };

  return (
    <Card className={classes.root}>
      <Grid container>
        <Grid item xs={8}>
          <CardContent className={classes.content}>
            <Grid container>
              <Typography className={classes.header}>Completed Task</Typography>
            </Grid>
            <Grid container>
              <Typography className={classes.main}>{taskName}</Typography>
            </Grid>
            <Grid container direction="row" alignItems="flex-start">
              <Grid item>
                <AttachmentOutlinedIcon />
              </Grid>
              <Grid item>
                {/* <Typography className={classes.documentText}>
                  htmlassignment.docx
                </Typography> */}
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
        <Grid item xs={4}>
          <CardContent className={classes.content}>
            <div style={{ position: "relative", left: "75px" }}>
              {isMentee === true ? (
                <IconButton
                  classes={{ root: classes.menuBtn }}
                  onClick={handleDelete}
                >
                  <CustomDeleteIcon />
                </IconButton>
              ) : (
                <>
                  <IconButton
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    classes={{ root: classes.menuBtn }}
                  >
                    <MenuDot />
                  </IconButton>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClickAway}>
                            <MenuList
                              autoFocusItem={open}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              <Link
                                to="/edit-task"
                                style={{ textDecoration: "none" }}
                              >
                                <MenuItem
                                  onClick={(e) => {
                                    handleClose(e, "edit");
                                  }}
                                >
                                  <ListItemIcon style={{ minWidth: "30px" }}>
                                    <EditIcon fontSize="small" />
                                  </ListItemIcon>
                                  <Typography className={classes.subContent}>
                                    Edit
                                  </Typography>
                                </MenuItem>
                              </Link>
                              <MenuItem
                                onClick={(e) => {
                                  handleClose(e, "edit");
                                }}
                              >
                                <ListItemIcon style={{ minWidth: "30px" }}>
                                  <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography className={classes.subContent}>
                                  Delete
                                </Typography>
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              )}
            </div>
            <div style={{ position: "relative", left: "20px", bottom: "10px" }}>
              <CheckCircleIcon
                style={{ width: 41, height: 41, fill: "#4DC591" }}
              />
            </div>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

import { useDispatch } from "react-redux";
import { SRD } from "srd";
import ComingSoonPage from "../../components/ComingSoonPage";
import ErrorPage from "../../components/ErrorPage";
import LoadingPage from "../../components/LoadingPage";
import { InputTask, MentorshipTask } from "../../server/serverTypes";
import { useTaskAdmin } from "../redux/hooks";
import AssignmentPage from "../views/AssignmentPage";
import { actions } from "../redux/model";

interface IAdminAssignmentPageContainer {
  assignmentId: string;
  userHash: string;
}

const AdminAssignmentPageContainer = ({
  assignmentId,
  userHash,
}: IAdminAssignmentPageContainer) => {
  const dispatch = useDispatch();
  const taskRd = useTaskAdmin(Number(assignmentId), userHash);

  const updateTask = (val: {
    id: number;
    title: string;
    description: string;
    dueDate: Date;
    resources?: string;
    notes?: string;
    assignmentLink?: string;
  }) => {
    const taskObj: InputTask = {
      id: val.id,
      title: val.title,
      description: val.description,
      dueDate: val.dueDate,
      courseId: 1,
      resources: val.resources,
      notes: val.notes,
      assignmentLink: val.assignmentLink,
    };
    dispatch(actions.editRisingStarTask({ task: taskObj, hash: userHash }));
  };

  return SRD.match(
    {
      notAsked: () => <LoadingPage />,
      loading: () => <LoadingPage />,
      failure: (msg: Error) => <ErrorPage />,
      success: (task: MentorshipTask) => {
        return (
          <AssignmentPage task={task} isMentor={true} updateTask={updateTask} />
        );
      },
    },
    taskRd
  );
};

export default AdminAssignmentPageContainer;

import firebase from "firebase";
import app from "firebase/app";
// import * as firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/analytics";
import config from "../config/firebase";

app.initializeApp(config);

// app.analytics().logEvent("login");

class Firebase {
  db = app.database();

  store = app.firestore();

  auth = app.auth();

  storage = app.storage();

  // analytics = app.analytics();

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  user = (uid) => this.store.collection("users").doc(uid);
  mentor = (uid) => this.store.collection("mentors").doc(uid);
  userByEmail = (email) =>
    this.store.collection("users").where("email", "==", email);
}

export default new Firebase();

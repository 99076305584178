import firebase from "firebase";
import Firebase from "../common/Firebase";
import { UserType } from "../server/serverTypes";
import { CredentialsInput } from "./redux/model";

interface FailedAuth {
  success: false;
}
interface SuccessAuth {
  success: true;
  info: {
    name: string;
    id: string;
    email: string;
    admin: boolean;
    isMentor: boolean;
  };
}

type AuthResponse = SuccessAuth | FailedAuth;

export const loginFunc = async (c: CredentialsInput) => {
  try {
    const authUserSnapshot = await Firebase.doSignInWithEmailAndPassword(
      c.email,
      c.password
    );

    const u = authUserSnapshot.user;

    const userPromise = await Firebase.user(u?.uid)
      .get()
      .then(async (authUserDetails) => {
        const d = authUserDetails.data();
        if (d) {
          const obj: AuthResponse = {
            success: true,
            info: {
              name: d.personalInfo.name ? d.personalInfo.name : d.username,
              id: d.id,
              email: d.email,
              isMentor: d.mentor,
              admin: d.admin ? d.admin : false,
            },
          };
          return obj;
        } else {
          const obj: AuthResponse = {
            success: false,
          };
          return obj;
        }
      });

    const mentorPromise = await Firebase.mentor(u?.uid)
      .get()
      .then(async (authUserDetails) => {
        const d = authUserDetails.data();
        if (d) {
          const obj: AuthResponse = {
            success: true,
            info: {
              name: d.name,
              id: d.id,
              email: d.email,
              isMentor: true,
              admin: false,
            },
          };
          return obj;
        } else {
          const obj: AuthResponse = {
            success: false,
          };
          return obj;
        }
      });
    if (userPromise.success === false && mentorPromise.success === false) {
      throw new Error("User not found");
    }
    if (userPromise.success) {
      return {
        firebaseAuth: authUserSnapshot,
        user: userPromise.success ? userPromise.info : null,
        admin: userPromise.success ? userPromise.info.admin : false,
      };
    }
    if (mentorPromise.success) {
      return {
        firebaseAuth: authUserSnapshot,
        user: mentorPromise.success ? mentorPromise.info : null,
        admin: mentorPromise.success ? mentorPromise.info.admin : false,
      };
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const loginWithGoogleFunc = async () => {
  try {
    var provider = new firebase.auth.GoogleAuthProvider();

    const authUserSnapshot = await Firebase.auth.signInWithPopup(provider);

    const u = authUserSnapshot.user;

    const userPromise = await Firebase.user(u?.uid)
      .get()
      .then(async (authUserDetails) => {
        const d = authUserDetails.data();
        if (d) {
          const obj: AuthResponse = {
            success: true,
            info: {
              name: d.personalInfo.name ? d.personalInfo.name : d.username,
              id: d.id,
              email: d.email,
              isMentor: d.mentor,
              admin: d.admin ? d.admin : false,
            },
          };
          return obj;
        } else {
          const obj: AuthResponse = {
            success: false,
          };
          return obj;
        }
      });

    const mentorPromise = await Firebase.mentor(u?.uid)
      .get()
      .then(async (authUserDetails) => {
        const d = authUserDetails.data();
        if (d) {
          const obj: AuthResponse = {
            success: true,
            info: {
              name: d.name,
              id: d.id,
              email: d.email,
              isMentor: true,
              admin: d.admin ? d.admin : false,
            },
          };
          return obj;
        } else {
          const obj: AuthResponse = {
            success: false,
          };
          return obj;
        }
      });

    if (userPromise.success === false && mentorPromise.success === false) {
      throw new Error("User not found");
    }
    if (userPromise.success) {
      return {
        firebaseAuth: authUserSnapshot,
        user: userPromise.success ? userPromise.info : null,
        admin: userPromise.success ? userPromise.info.admin : false,
      };
    }
    if (mentorPromise.success) {
      return {
        firebaseAuth: authUserSnapshot,
        user: mentorPromise.success ? mentorPromise.info : null,
        admin: mentorPromise.success ? mentorPromise.info.admin : false,
      };
    }

    // if (!u) {
    //   throw new Error("no user");
    // }
    // const userPromise = await findUser(u.uid);
    // console.log(userPromise);
    // return {
    //   firebaseAuth: authUserSnapshot,
    //   user: userPromise,
    //   admin: userPromise ? userPromise.admin : null,
    // };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const findUser = async (uid: string) => {
  try {
    const userPromise = await Firebase.user(uid)
      .get()
      .then(async (authUserDetails) => {
        const d = authUserDetails.data();
        if (d) {
          return {
            name: d.personalInfo.name,
            id: d.id,
            email: d.email,
            isMentor: d.mentor,
            admin: d.admin ? d.admin : false,
          };
        } else {
          throw new Error("error");
        }
      });
    return userPromise;
  } catch (e) {
    return null;
  }
};

export const findUserV2 = async (uid: string) => {
  try {
    const userPromise = await Firebase.user(uid)
      .get()
      .then(async (authUserDetails) => {
        const d = authUserDetails.data();
        if (d) {
          const obj: AuthResponse = {
            success: true,
            info: {
              name: d.personalInfo.name ? d.personalInfo.name : d.username,
              id: d.id,
              email: d.email,
              isMentor: d.mentor,
              admin: d.admin ? d.admin : false,
            },
          };
          return obj;
        } else {
          const obj: AuthResponse = {
            success: false,
          };
          return obj;
        }
      });

    const mentorPromise = await Firebase.mentor(uid)
      .get()
      .then(async (authUserDetails) => {
        const d = authUserDetails.data();
        if (d) {
          const obj: AuthResponse = {
            success: true,
            info: {
              name: d.name,
              id: d.id,
              email: d.email,
              isMentor: true,
              admin: d.admin ? d.admin : false,
            },
          };
          return obj;
        } else {
          const obj: AuthResponse = {
            success: false,
          };
          return obj;
        }
      });
    if (userPromise.success === false && mentorPromise.success === false) {
      throw new Error("User not found");
    }
    if (userPromise.success) {
      return {
        name: userPromise.info.name,
        firebaseId: userPromise.info.id,
        email: userPromise.info.email,
        type: userPromise.info.admin
          ? UserType.admin
          : userPromise.info.isMentor
          ? UserType.mentor
          : UserType.student,
      };
    }
    if (mentorPromise.success) {
      return {
        name: mentorPromise.info.name,
        firebaseId: mentorPromise.info.id,
        email: mentorPromise.info.email,
        type: mentorPromise.info.admin
          ? UserType.admin
          : mentorPromise.info.isMentor
          ? UserType.mentor
          : UserType.student,
      };
    }
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const stripTime = (d: Date) => new Date(d).toString().split("GMT")[0];

export const stripDate = (d: Date) =>
  new Date(d).toLocaleDateString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

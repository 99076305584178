import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
// import { AuthUserContext, useAuth } from "../../../../Providers";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import VideoLibraryOutlinedIcon from "@material-ui/icons/VideoLibraryOutlined";
import QueueOutlinedIcon from "@material-ui/icons/QueueOutlined";
// import {
//   HomeIcon,
//   BookmarkIcon,
//   FavoriteIcon,
//   SettingIcon,
//   PlayIcon,
//   PersonIcon,
//   BriefcaseIcon,
//   GraduationIcon,
// } from "./NavBarIcons";

import { BlackMintLogo } from "./BlackMintIcon";
import { useHistory, useLocation } from "react-router-dom";
// import WithAuthorizationElement from "../../../Auth/WithAuthorization/WithAuthorizationElement";
// import { ROUTES } from "../../../../constants";
// import { blackmintTheme } from "../../../../style/theme";

import { blackmintTheme } from "../styles/theme";
import { useMenorMentees, useMentorInfo } from "../mentor/redux/hooks";
import { actions as userActions } from "../user/redux/model";
import { useDispatch } from "react-redux";
import { useRelatedPrograms, useUserObj } from "../user/redux/hooks";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useMenteeMentors } from "../mentee/redux/hooks";
import { UserType } from "../server/serverTypes";
import Firebase from "../common/Firebase";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    navBar: {
      padding: "0 var(--padding-screen-current-side)",
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "#272727",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "1.7rem",
      height: "60px",
      width: "100%",
      zIndex: 100,
      boxShadow: "0 1px 5px 0px rgba(0, 0, 0, 0.4)",
    },
    logoBox: {},
    dropTrigger: {
      width: "30px",
      height: "30px",
      cursor: "pointer",
      color: "white",
      margin: "15px",
    },
    back: {
      padding: "1rem",
      width: "30px",
      height: "30px",
      color: "white",
      margin: "15px",
    },
    list: {
      width: 293,
    },
    sidebarText: {
      // ...blackmintTheme.typographyBlackmint.link,
      textDecorationLine: "underline",
    },
    sidebarHeader: {
      // ...blackmintTheme.typographyBlackmint.h3,
      color: "#4DC591",
      margin: "0.5rem 2rem",
    },
    menuIcon: {
      // fontSize: "4rem",
      "&:hover": {
        color: "#4DC591",
      },
    },
    username: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "24px",
    },
    listIcon: {
      minWidth: "44px",
      placeContent: "center",
      alignItems: "center",
    },
    btn: {
      width: "100%",
      alignItems: "center",
      height: "38px",
      background: "#4DC591",
      borderRadius: "8px",
      margin: "40px 0px",
    },
    h4: {
      // ...blackmintTheme.typographyBlackmint.h4,
    },
    accordionRoot: {
      boxShadow: "none",
    },
    accordionSummary: {
      padding: "0px",
    },
  })
);

const NavBar = () => {
  const classes = useStyles();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const userInfo = useUserObj();
  const isMentor = userInfo?.user.type === UserType.mentor ? true : false;

  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation();

  const navigateToPage = (route: string) => {
    history.push(route);
    setShowDropdown(false);
  };

  const [expanded, setExpanded] = React.useState<boolean>(false);

  const programs = useRelatedPrograms();

  return (
    <React.Fragment>
      <AppBar>
        <Toolbar className={classes.navBar}>
          {userInfo && location.pathname !== "/" ? (
            <IconButton
              onClick={() => {
                history.goBack();
              }}
              className={classes.back}
            >
              <ArrowBackIosRoundedIcon fontSize={"large"} />
            </IconButton>
          ) : (
            <IconButton className={classes.back}></IconButton>
          )}
          <div
            className={classes.logoBox}
            onClick={() => {
              history.push("/");
            }}
          >
            <BlackMintLogo />
          </div>
          {userInfo ? (
            <IconButton
              onClick={() => {
                setShowDropdown(!showDropdown);
              }}
              className={classes.dropTrigger}
            >
              <MenuRoundedIcon
                fontSize={"large"}
                classes={{ root: classes.menuIcon }}
              />
            </IconButton>
          ) : (
            <IconButton className={classes.dropTrigger}></IconButton>
          )}
          <Drawer
            anchor={"right"}
            open={showDropdown}
            onClose={() => {
              setShowDropdown(false);
            }}
          >
            {userInfo ? (
              <>
                <div className={classes.list} role="presentation">
                  <List
                    style={{
                      padding: "2rem",
                      backgroundColor: "rgba(39, 39, 39, 0.1)",
                    }}
                  >
                    <ListItem style={{ padding: 0 }}>
                      <Typography component="h2" className={classes.username}>
                        {userInfo ? userInfo.user.name : "Please sign in"}
                      </Typography>
                    </ListItem>
                    <ListItem style={{ padding: 0 }}>
                      <div
                        onClick={() => {
                          dispatch(userActions.signOut());
                          setShowDropdown(false);
                          Firebase.auth.signOut();
                        }}
                        className={classes.sidebarText}
                        style={{ textDecorationLine: "underline" }}
                      >
                        Sign Out
                      </div>
                    </ListItem>
                  </List>
                  <List>
                    <ListItem
                      button
                      onClick={() => {
                        //   navigateToPage(ROUTES.home);
                        history.push("/");
                        setShowDropdown(false);
                      }}
                    >
                      <ListItemIcon className={classes.listIcon}>
                        <HomeOutlinedIcon style={{ color: "black" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography className={classes.sidebarText}>
                            Home
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        //   navigateToPage(ROUTES.home);
                      }}
                    >
                      <Accordion
                        expanded={expanded}
                        onChange={() => {
                          setExpanded(!expanded);
                        }}
                        classes={{ root: classes.accordionRoot }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          classes={{ root: classes.accordionSummary }}
                        >
                          <ListItemIcon className={classes.listIcon}>
                            <PersonOutlineOutlinedIcon
                              style={{ color: "black" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography className={classes.sidebarText}>
                                {isMentor ? "Mentees" : "Mentors"}
                              </Typography>
                            }
                          />
                        </AccordionSummary>
                        <AccordionDetails style={{ paddingLeft: "44px" }}>
                          <Grid container>
                            {isMentor
                              ? programs.map((m) => (
                                  <Grid
                                    item
                                    xs={12}
                                    onClick={() =>
                                      navigateToPage("mentor/" + m.firebaseHash)
                                    }
                                  >
                                    <Typography>{m.name}</Typography>
                                  </Grid>
                                ))
                              : programs.map((m) => (
                                  <Grid
                                    item
                                    xs={12}
                                    onClick={() =>
                                      navigateToPage("mentee/" + m.firebaseHash)
                                    }
                                  >
                                    <Typography>{m.name}</Typography>
                                  </Grid>
                                ))}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        history.push("/videos");
                        setShowDropdown(false);
                      }}
                    >
                      <ListItemIcon className={classes.listIcon}>
                        <VideoLibraryOutlinedIcon style={{ color: "black" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography className={classes.sidebarText}>
                            Videos
                          </Typography>
                        }
                      />
                    </ListItem>
                    {userInfo.user.type === UserType.admin ? (
                      <ListItem
                        button
                        onClick={() => {
                          history.push("/add-video");
                          setShowDropdown(false);
                        }}
                      >
                        <ListItemIcon className={classes.listIcon}>
                          <QueueOutlinedIcon style={{ color: "black" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography className={classes.sidebarText}>
                              Add Video
                            </Typography>
                          }
                        />
                      </ListItem>
                    ) : null}
                  </List>
                </div>
                <div style={{ padding: "0px 16px" }}>
                  <Button
                    className={classes.btn}
                    href={"https://risingstar.blackmint.io/"}
                  >
                    <Typography className={classes.h4}>
                      BACK TO RISING STAR
                    </Typography>
                  </Button>
                </div>
              </>
            ) : (
              <></>
            )}
          </Drawer>
          {/* </WithAuthorizationElement> */}
        </Toolbar>
      </AppBar>
      <Toolbar style={{ marginBottom: "4px" }} />
    </React.Fragment>
  );
};

export default NavBar;

import React from "react";
import { SRD } from "srd";
import ErrorPage from "../../components/ErrorPage";
import LoadingPage from "../../components/LoadingPage";
import { MentorshipProgramSlim } from "../../server/serverTypes";
import { useUserPrograms } from "../../user/redux/hooks";
import AdminHome from "../views/AdminHome";

const AdminHomeContainer = () => {
  const studentsRemoteData = useUserPrograms();

  return SRD.match(
    {
      notAsked: () => <LoadingPage />,
      loading: () => <LoadingPage />,
      failure: (msg: Error) => <ErrorPage />,
      success: (students: MentorshipProgramSlim[]) => {
        return <AdminHome students={students} />;
      },
    },
    studentsRemoteData
  );
};

export default AdminHomeContainer;

import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  OutlinedInput,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { makeStyles } from "@material-ui/core/styles";
import { blackmintTheme, deviceDesktopBreakpoint } from "../../styles/theme";
import SessionComponent from "../../components/SessionComponent";
import {
  InputMentorshipSession,
  MentorshipSession,
} from "../../server/serverTypes";
import { useProgramInfo } from "../../mentor/redux/hooks";
import { useDispatch } from "react-redux";
import { actions } from "../redux/model";
import { useMenteeProgramInfo } from "../../mentee/redux/hooks";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up(deviceDesktopBreakpoint)]: {
      padding: "0px 240px",
      height: "100vh",
    },
  },
  inputOutline: {
    border: "1px solid #4DC591 !important",
    borderRadius: "5px",
  },
  inputOutlineTwo: {
    border: "2px solid #4DC591 !important",
    borderRadius: "10px",
  },
  btn: {
    alignItems: "center",
    width: "212px",
    height: "38px",
    background: "#4DC591",
    borderRadius: "8px",
    margin: "40px 0px",
  },
  h4: {
    ...blackmintTheme.typographyBlackmint.h4,
    fontWeight: 600,
  },
  feebackBox: {
    background: "#FCFAF5",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    height: "50px",
    padding: "14px 10px",
    margin: "20px 0px",
  },
}));

interface ISessionPage {
  session: MentorshipSession;
  isMentor: boolean;
  updateSession: (val: {
    id: number;
    topic: string;
    description?: string;
    sessionDate: Date;
    notes?: string;
    sessionLink?: string;
    rating?: number;
  }) => void;
  rating?: number;
}

const SessionPage = ({
  session,
  isMentor,
  updateSession,
  rating,
}: ISessionPage) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [description, setDescription] = useState(session.description);
  const [notes, setNotes] = useState(session.notes);
  const [sessionLink, setSessionLink] = useState(session.sessionLink);
  const [sessionRating, setSessionRating] = useState(rating);

  const d = new Date();

  const handleUpdate = () => {
    updateSession({
      id: session.id,
      topic: session.topic,
      description: description,
      sessionDate: session.sessionDate,
      notes: notes,
      sessionLink: sessionLink,
      rating: sessionRating,
    });
    // if (mp) {
    //   const sessionObj: InputMentorshipSession = {
    //     // ...session,
    //     id: session.id,
    //     topic: session.topic,
    //     description: description,
    //     sessionDate: session.sessionDate,
    //     notes: notes,
    //     sessionLink: sessionLink,
    //     fkMentorshipId: mp.id,
    //   };
    //   dispatch(actions.editMentorshipSession(sessionObj));
    // } else if (menteeProgram) {
    //   const sessionObj: InputMentorshipSession = {
    //     id: session.id,
    //     topic: session.topic,
    //     description: description,
    //     sessionDate: session.sessionDate,
    //     notes: notes,
    //     sessionLink: sessionLink,
    //     fkMentorshipId: menteeProgram.id,
    //   };
    //   dispatch(actions.editMentorshipSession(sessionObj));
    // }
  };

  return (
    <Container className={classes.container}>
      <SessionComponent
        session={session}
        completed={d > new Date(session.sessionDate)}
      />
      <Grid container>
        <Typography className={classes.h4}>
          What’s the agenda of this session?
        </Typography>
      </Grid>
      <Grid container>
        <OutlinedInput
          id="standard-multiline-static"
          label="Multiline"
          multiline
          placeholder={"Description of session"}
          value={description}
          fullWidth
          notched={false}
          rows={6}
          classes={{
            notchedOutline: classes.inputOutline,
          }}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          readOnly={!isMentor}
        />
      </Grid>
      <Grid container>
        <Typography className={classes.h4}>Meeting notes</Typography>
      </Grid>
      <Grid container>
        <OutlinedInput
          id="standard-multiline-static"
          label="Multiline"
          multiline
          placeholder="Notes"
          value={notes}
          fullWidth
          notched={false}
          rows={6}
          classes={{
            notchedOutline: classes.inputOutline,
          }}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
        />
      </Grid>
      <Grid container>
        <Typography className={classes.h4}>Session recording</Typography>
      </Grid>
      <Grid container>
        <OutlinedInput
          id="standard-multiline-static"
          label="Multiline"
          fullWidth
          placeholder="Google drive shared link"
          value={sessionLink}
          notched={false}
          classes={{
            notchedOutline: classes.inputOutlineTwo,
          }}
          onChange={(e) => {
            setSessionLink(e.target.value);
          }}
        />
      </Grid>
      <Grid container className={classes.feebackBox} justify="space-between">
        <Grid item>
          <Typography className={classes.h4}>Session feedback</Typography>
        </Grid>
        <Grid item>
          <Rating
            name="half-rating"
            // defaultValue={2.5}
            value={sessionRating ? sessionRating : 2.5}
            // precision={0.5}
            onChange={(e, val) => {
              if (val) setSessionRating(val);
            }}
            // readOnly={isMentor}
          />
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Button className={classes.btn} onClick={handleUpdate}>
          <Typography className={classes.h4}>Save Changes</Typography>
        </Button>
      </Grid>
    </Container>
  );
};

export default SessionPage;

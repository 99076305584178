import React from "react";
import { useDispatch } from "react-redux";
import { InputSession } from "../../server/serverTypes";
import { actions } from "../redux/model";
import AddSession from "../views/AddSession";

interface IAdminAddSessionContainer {
  id: string;
}

const AdminAddSessionContainer = ({ id }: IAdminAddSessionContainer) => {
  const dispatch = useDispatch();

  const [name, setName] = React.useState<string>("");
  const [date, setDate] = React.useState<Date>(new Date());
  const [time, setTime] = React.useState("13:30");

  const onSubmit = () => {
    if (typeof Number(id) === "number") {
      const createDate = new Date(date.toDateString() + " " + time);
      const sessionObj: InputSession = {
        title: name,
        description: "",
        sessionLink: "string",
        sessionDate: createDate,
        courseId: Number(id),
      };
      dispatch(actions.addRisingStarSession(sessionObj));
    }
  };

  const handleDateChange = (dateParam: Date) => {
    if (dateParam !== null) setDate(dateParam);
  };

  const handleNameChange = (val: string) => {
    setName(val);
  };

  const handleTimeChange = (val: string) => {
    setTime(val);
  };

  return (
    <AddSession
      name={name}
      date={date}
      time={time}
      handleNameChange={handleNameChange}
      handleDateChange={handleDateChange}
      handleTimeChange={handleTimeChange}
      addSampleSession={onSubmit}
    />
  );
};

export default AdminAddSessionContainer;

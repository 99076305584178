import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ComeBackLater } from "../components/Icons";
import MentorComponent from "../components/MentorComponent";

const useStyles = makeStyles((theme) => ({
  container: {
    // padding: "0px 172px",
    [theme.breakpoints.up("md")]: {
      padding: "0px 240px",
    },
  },
  title: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "40px",
    color: "#00664F",
  },
  h3: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    color: "#272727",
  },
  main: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    color: "#757575",
  },
}));

const MenteeInvitationDashboard = () => {
  const classes = useStyles();

  const hasMentor = true;

  return (
    <Container className={classes.container}>
      <Typography className={classes.title}>Welcome Kwame! </Typography>
      <Grid container style={{ margin: "10px 0px" }}>
        <Typography className={classes.h3}>
          At Rising Star’s mentorship portal you are able to keep track of your
          progress as well as any discussions you had at your sessions.
        </Typography>
      </Grid>
      {hasMentor ? (
        // <MentorComponent />
        <div>placeholder</div>
      ) : (
        <React.Fragment>
          <Grid container style={{ margin: "10px 0px" }}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <ComeBackLater />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography className={classes.h3} style={{ color: "#000000" }}>
                Did you receive an invite from an mentor?
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{ margin: "10px 0px" }}>
            <Typography className={classes.main}>
              Check your email to see if your mentor has sent you an invitation
              email. Once you accept the invitation you’ll have access to the
              mentorship portal!
            </Typography>
          </Grid>
        </React.Fragment>
      )}
    </Container>
  );
};

export default MenteeInvitationDashboard;

import { ApolloError, useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { getMentorshipProgramConnection } from "../../server/queryBuilder";
import {
  Mentor,
  MentorshipProgramConnection,
  MentorshipSession,
  MentorshipTask,
  Student,
} from "../../server/serverTypes";
import MentorMainPage from "../Main";
import { actions } from "../redux/model";
import { SRD, RD, notAsked, loading, failure, success } from "srd";
import { useMentorshipProgramRD } from "../redux/hooks";
import { AxiosError } from "axios";
import LoadingPage from "../../components/LoadingPage";
import ErrorPage from "../../components/ErrorPage";

interface IMentorContainer {
  id: string;
}

const MentorContainer = ({ id }: IMentorContainer) => {
  const rd = useMentorshipProgramRD(id);

  return SRD.match(
    {
      notAsked: () => <LoadingPage />,
      loading: () => <LoadingPage />,
      failure: (msg: AxiosError) => <ErrorPage />,
      success: (mentorshipProgram: MentorshipProgramConnection) => (
        <MentorMainPage
          tasks={mentorshipProgram.tasks}
          sessions={mentorshipProgram.sessions}
          student={mentorshipProgram.student}
        />
      ),
    },
    rd
  );
};

export default MentorContainer;

import React, { useEffect, useState } from "react";
import Firebase from "../Firebase";
import firebase from "firebase";
import { useDispatch } from "react-redux";
import { actions } from "../../user/redux/model";
import LoadingPage from "../../components/LoadingPage";

export const AuthContext = React.createContext<any>(null);

export const AuthProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    Firebase.auth.onAuthStateChanged((user: firebase.User | null) => {
      setCurrentUser(user);
      setPending(false);
      if (user) {
        dispatch(actions.verifyToken(user.uid));
      }
    });
  }, []);

  if (pending) {
    return <LoadingPage />;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser: currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

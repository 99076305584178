import React from "react";
import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Grid,
  Chip,
  Typography,
} from "@material-ui/core";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import { makeStyles } from "@material-ui/core/styles";
import { Mentor } from "../server/serverTypes";

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 370,
    maxHeight: 110,
    background: "#F1F1EE",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    margin: "10px 0px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  avatar: {
    color: "white",
    background: "#6C63FF",
    border: "2px solid #4135FF",
  },
  chip: {
    background:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #4DC591",
    borderRadius: "50px",
    color: "#00664F",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "14px",
  },
  cardAction: {
    margin: 0,
  },
  h3: {
    fontFamily: "Inter",
    fontWeight: "bold",
    fontSize: "20px",
    color: "#272727",
  },
  content: {
    fontFamily: "Inter",
    fontWeight: "normal",
    fontSize: "16px",
    color: "#272727",
  },
}));

interface IMentorComponent {
  withArrow?: boolean;
  name: string;
  email: string;
}

const MentorComponent = ({
  withArrow = true,
  name,
  email,
}: IMentorComponent) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader
        classes={{ action: classes.cardAction }}
        avatar={<Avatar alt={name} className={classes.avatar} src="./" />}
        action={
          withArrow ? (
            <IconButton aria-label="settings">
              <ChevronRightRoundedIcon fontSize="small" />
            </IconButton>
          ) : null
        }
        title={
          <React.Fragment>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography className={classes.h3}>{name}</Typography>
              </Grid>
              <Grid item>
                <Chip label="Mentor" className={classes.chip} />
              </Grid>
            </Grid>
          </React.Fragment>
        }
        subheader={<Typography className={classes.content}>{email}</Typography>}
      />
    </Card>
  );
};

export default MentorComponent;

import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { blackmintTheme } from "../../styles/theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    textField: {
      border: "2px solid #4DC591",
      borderRadius: "10px",
    },
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    btn: {
      backgroundColor: "#4DC591",
      borderRadius: theme.spacing(1),
      height: "38px",
      width: "300px",
      color: "#272727",
      margin: "4px 0px",
    },
    btnText: { ...blackmintTheme.typographyBlackmint.h3, fontWeight: 600 },
  })
);

interface IAddVideo {
  title: string;
  videoLink: string;
  description: string;
  addVideo: () => void;
  handleTitleChange: (p: string) => void;
  handleVideoLinkChange: (p: string) => void;
  handleDescriptionChange: (p: string) => void;
}

const AddVideo = ({
  title,
  videoLink,
  description,
  addVideo,
  handleTitleChange,
  handleVideoLinkChange,
  handleDescriptionChange,
}: IAddVideo) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <TextField
        placeholder="Video Title"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        className={classes.textField}
        InputProps={{ classes }}
        inputProps={{
          style: {
            padding: "6px 10px 7px",
            borderRadius: "10px",
          },
        }}
        value={title}
        onChange={(e) => {
          handleTitleChange(e.target.value);
        }}
      />
      <TextField
        placeholder="Video Description"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        className={classes.textField}
        InputProps={{ classes }}
        inputProps={{
          style: {
            padding: "6px 10px 7px",
            borderRadius: "10px",
          },
        }}
        value={description}
        onChange={(e) => {
          handleDescriptionChange(e.target.value);
        }}
      />
      <TextField
        placeholder="Youtube Link"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        className={classes.textField}
        InputProps={{ classes }}
        inputProps={{
          style: {
            padding: "6px 10px 7px",
            borderRadius: "10px",
          },
        }}
        value={videoLink}
        onChange={(e) => {
          handleVideoLinkChange(e.target.value);
        }}
      />
      <Grid container justify="center">
        <Grid item>
          <Button
            onClick={(e) => {
              e.preventDefault();
              addVideo();
            }}
            className={classes.btn}
            variant="contained"
            disabled={
              title.length === 0 ||
              description.length === 0 ||
              videoLink.length === 0
            }
          >
            <Typography className={classes.btnText}>Add Video</Typography>
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddVideo;

import { Switch, Route } from "react-router-dom";
import MenteeInvitationDashboard from "./mentee";
import connectedWithFooter from "./components/connectedWithFooter";
import EditTaskContainer from "./task/containers/EditTaskContainer";
import MentorContainer from "./mentor/containers/MentorContainer";
import MenteeContainer from "./mentee/MenteeContainer";
import HomeContainer from "./mentor/containers/HomeContainer";
import LoginContainer from "./user/containers/LoginContainer";
import { useHasPermissions, useLoggedInUser } from "./user/redux/hooks";
import React, { useContext } from "react";
import InitialPageContainer from "./user/containers/InitialPageContainer";
import AddMenteeContainer from "./mentor/containers/AddMenteeContainer";
import AddTaskContainer from "./task/containers/AddTaskContainer";
import ToasterComponent from "./toaster/ToasterComponent";
import { compose } from "redux";
import { useDispatch } from "react-redux";
import { actions as toasterActions } from "./toaster/redux/model";
import AddSessionContainer from "./sessions/container/AddSessionContainer";
import MentorSessionPageContainer from "./sessions/container/MentorSessionPageContainer";
import MentorAssignmentPageContainer from "./task/containers/MentorAssignmentPageContainer";
import MenteeAssignmentPageContainer from "./task/containers/MenteeAssignmentPageContainer";
import { useMediaQuery, useTheme } from "@material-ui/core";
import VideosDashboardContainer from "./videos/containers/VideosDashboardContainer";
import AddVideoContainer from "./videos/containers/AddVideoContainer";
import RisingStarDashboardContainer from "./admin/containers/RisingStarDashboardContainer";
import AdminDashboardContainer from "./admin/containers/AdminDashboardContainer";
import { AuthContext, AuthProvider } from "./common/auth/Auth";
import AddRisingStarContainer from "./admin/containers/AddRisingStarContainer";
import AdminAddTaskContainer from "./task/containers/AdminAddTaskContainer";
import AdminAddSessionContainer from "./sessions/container/AdminAddSessionContainer";
import AdminAssignmentPageContainer from "./task/containers/AdminAssignmentPageContainer";
import RisingStarAssignmentPageContainer from "./task/containers/RisingStarAssignmentPageContainer";
import AdminEditTaskContainer from "./task/containers/AdminEditTaskContainer";
import RisingStarSessionPageContainer from "./sessions/container/RisingStarSessionPageContainer";
import AdminSessionPageContainer from "./sessions/container/AdminSessionPageContainer";

// TODO: Handle check for mentor and mentee
const App = () => {
  const dispatch = useDispatch();
  const isloggedIn = useLoggedInUser();
  const setOpen = compose(dispatch, toasterActions.setOpen);
  const theme = useTheme();

  const { currentUser } = useContext(AuthContext);

  return (
    <div>
      <ToasterComponent setOpen={setOpen} />
      <Switch>
        {isloggedIn ? (
          <Switch>
            <Route
              path="/mentor/"
              exact
              render={() => {
                return <HomeContainer />;
              }}
            />
            <Route
              path="/mentor-onboard"
              render={() => {
                return <AddMenteeContainer />;
              }}
            />
            <Route
              path="/admin-onboard"
              render={() => {
                return <AddRisingStarContainer />;
              }}
            />
            <Route
              exact
              path="/mentor/:id"
              render={({ match }) => {
                return <MentorContainer id={match.params.id} />;
              }}
            />
            <Route
              exact
              path="/mentee/:id"
              render={({ match }) => {
                return <MenteeContainer id={match.params.id} />;
              }}
            />
            <Route
              exact
              path="/admin/:id"
              render={({ match }) => {
                return <AdminDashboardContainer id={match.params.id} />;
              }}
            />
            <Route
              exact
              path="/rising-star/:id"
              render={({ match }) => {
                return <RisingStarDashboardContainer id={match.params.id} />;
              }}
            />
            <Route
              exact
              path="/mentor/:id/assignment/:assignmentId"
              render={({ match }) => {
                return (
                  <MentorAssignmentPageContainer
                    id={match.params.assignmentId}
                  />
                );
              }}
            />
            <Route
              exact
              path="/mentee/:id/assignment/:assignmentId"
              render={({ match }) => {
                return (
                  <MenteeAssignmentPageContainer
                    id={match.params.assignmentId}
                  />
                );
              }}
            />
            <Route
              exact
              path="/admin/:id/assignment/:assignmentId"
              render={({ match }) => {
                return (
                  <AdminAssignmentPageContainer
                    assignmentId={match.params.assignmentId}
                    userHash={match.params.id}
                  />
                );
              }}
            />
            <Route
              exact
              path="/rising-star/:id/assignment/:assignmentId"
              render={({ match }) => {
                return (
                  <RisingStarAssignmentPageContainer
                    assignmentId={match.params.assignmentId}
                    userHash={match.params.id}
                  />
                );
              }}
            />
            <Route
              path="/mentor/:id/session/:sessionId"
              render={({ match }) => {
                return (
                  <MentorSessionPageContainer
                    id={match.params.sessionId}
                    isMentor={true}
                  />
                );
              }}
            />
            <Route
              path="/mentee/:id/session/:sessionId"
              render={({ match }) => {
                return (
                  <MentorSessionPageContainer
                    id={match.params.sessionId}
                    isMentor={false}
                  />
                );
              }}
            />
            <Route
              path="/admin/:id/session/:sessionId"
              render={({ match }) => {
                return (
                  <AdminSessionPageContainer
                    sessionId={match.params.sessionId}
                    adminHash={match.params.id}
                  />
                );
              }}
            />
            <Route
              path="/rising-star/:id/session/:sessionId"
              render={({ match }) => {
                return (
                  <RisingStarSessionPageContainer
                    sessionId={match.params.sessionId}
                    adminHash={match.params.id}
                  />
                );
              }}
            />
            <Route
              path="/add-session"
              render={() => {
                return <AddSessionContainer />;
              }}
            />
            <Route
              exact
              path="/add-task"
              render={() => {
                return <AddTaskContainer />;
              }}
            />
            <Route
              exact
              path="/:id/add-task"
              render={({ match }) => {
                return <AdminAddTaskContainer id={match.params.id} />;
              }}
            />
            <Route
              exact
              path="/:id/add-session"
              render={({ match }) => {
                return <AdminAddSessionContainer id={match.params.id} />;
              }}
            />
            <Route
              path="/mentor/:firebaseId/edit-task/:id"
              render={({ match }) => {
                return <EditTaskContainer id={match.params.id} />;
              }}
            />
            <Route
              path="/admin/:firebaseId/edit-task/:id"
              render={({ match }) => {
                return (
                  <AdminEditTaskContainer
                    id={match.params.id}
                    userHash={match.params.firebaseId}
                  />
                );
              }}
            />
            <Route
              path="/mentor/:id/edit-session/:id"
              render={({ match }) => {
                return (
                  <MentorSessionPageContainer
                    id={match.params.id}
                    isMentor={true}
                  />
                );
              }}
            />
            <Route
              path="/invitation"
              render={() => {
                return <MenteeInvitationDashboard />;
              }}
            />
            <Route
              path="/mentee/dashboard"
              render={() => {
                return <MenteeContainer id={""} />;
              }}
            />
            <Route
              path="/videos"
              render={() => {
                return <VideosDashboardContainer />;
              }}
            />
            <Route
              exact
              path="/add-video"
              render={() => {
                return <AddVideoContainer />;
              }}
            />
            <Route
              exact
              path="/"
              render={() => {
                return <InitialPageContainer />;
              }}
            />
          </Switch>
        ) : (
          <Route
            render={() => {
              return <LoginContainer />;
            }}
          />
        )}
      </Switch>
    </div>
  );
};

export default connectedWithFooter(App);
// export default App;

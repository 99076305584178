import { useDispatch, useSelector } from "react-redux";
import { SRD } from "srd";
import { RootState } from "../../common/redux/reducers";
import { MentorshipProgramSlim, UserType } from "../../server/serverTypes";
import { actions } from "./model";

export const useLoggedInUser = () => {
  const user = useSelector((state: RootState) => state.user.userRemoteData);
  if (SRD.isSuccess(user)) {
    return true;
  }
  return false;
};

export const useUserInfo = () =>
  useSelector((state: RootState) => state.user.userRemoteData);

export const useUserObj = () => {
  const user = useSelector((state: RootState) => state.user.userRemoteData);
  if (SRD.isSuccess(user)) {
    return user.data;
  } else return null;
};

export const useAuthenticating = () =>
  useSelector((state: RootState) => state.user.authenticating);

export const useUserPrograms = () => {
  const dispatch = useDispatch();
  const programs = useSelector(
    (state: RootState) => state.user.userProgramsRemoteData
  );
  const userRemoteData = useSelector(
    (state: RootState) => state.user.userRemoteData
  );

  if (SRD.isNotAsked(programs) && SRD.isSuccess(userRemoteData)) {
    dispatch(actions.fetchUserPrograms({ id: userRemoteData.data.user.id }));
  }

  return programs;
};

export const useRelatedPrograms = () => {
  const programs = useSelector(
    (state: RootState) => state.user.userProgramsRemoteData
  );
  const res = SRD.match(
    {
      notAsked: () => [],
      loading: () => [],
      failure: (msg: Error) => [],
      success: (programs: MentorshipProgramSlim[]) => programs,
    },
    programs
  );
  return res as MentorshipProgramSlim[];
};

export const useHasPermissions = (role: UserType) => {
  const userRemoteData = useSelector(
    (state: RootState) => state.user.userRemoteData
  );

  if (SRD.isSuccess(userRemoteData)) {
    return userRemoteData.data.user.type === role;
  }
  return false;

  // return match(userRemoteData, {
  //   _: () => {
  //     return false;
  //   },
  //   Success: (u) => {
  //     return (
  //       (developerAccess && u.user.roles.includes(UserRoles.developer)) ||
  //       u.user.roles.includes(role) ||
  //       u.user.roles.includes(UserRoles.superAdmin)
  //     );
  //   },
  // });
};

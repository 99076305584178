import React from "react";
import { SRD } from "srd";
import ErrorPage from "../../components/ErrorPage";
import LoadingPage from "../../components/LoadingPage";
import { RisingStarVideo } from "../../server/serverTypes";
import { useVideos } from "../redux/hooks";
import VideosDashboard from "../views/VideosDashboard";

const VideosDashboardContainer = () => {
  const videosRemoteData = useVideos();
  return SRD.match(
    {
      notAsked: () => <LoadingPage />,
      loading: () => <LoadingPage />,
      failure: (msg: Error) => <ErrorPage />,
      success: (videos: RisingStarVideo[]) => {
        return <VideosDashboard videos={videos} />;
      },
    },
    videosRemoteData
  );
};

export default VideosDashboardContainer;

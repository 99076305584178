// import { ApolloError, useQuery } from "@apollo/client";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import { useDispatch } from "react-redux";
import { useMentorshipProgram } from "../../mentor/redux/hooks";
import AddTask from "../views/AddTask";
// import { getMentorshipTaskById } from "../../server/queryBuilder";
import { InputMentorshipTask, MentorshipTask } from "../../server/serverTypes";
import { actions } from "../redux/model";
// import EditTask from "../views/EditTask";

interface IEditTaskContainer {
  id: string;
}

const AddTaskContainer = () => {
  const dispatch = useDispatch();
  const [name, setName] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [date, setDate] = React.useState<Date>(new Date());
  const mp = useMentorshipProgram();

  const onSubmit = () => {
    if (mp.tag === "Success") {
      const taskObj: InputMentorshipTask = {
        title: name,
        description: description,
        dueDate: date,
        fkMentorshipId: mp.data.id,
      };
      dispatch(actions.addMentorshipTask(taskObj));
    }
  };

  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (date !== null) setDate(date);
  };

  const handleNameChange = (val: string) => {
    setName(val);
  };

  const handleDescriptionChange = (val: string) => {
    setDescription(val);
  };

  return (
    <AddTask
      name={name}
      description={description}
      date={date}
      addSampleTask={onSubmit}
      handleNameChange={handleNameChange}
      handleDateChange={handleDateChange}
      handleDescriptionChange={handleDescriptionChange}
    />
  );
};

export default AddTaskContainer;

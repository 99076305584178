import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centered: {
      minHeight: "calc(100vh - 204px)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    h1: {
      marginBottom: "50px",
      fontFamily: "'Lato', sans-serif",
      fontSize: "50px",
    },
  })
);

const LoadingPage = () => {
  const classes = useStyles();

  return (
    <section className={classes.centered}>
      <CircularProgress size={120} style={{ color: "#67c385" }} />
    </section>
  );
};

export default LoadingPage;

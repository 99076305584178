import Home from "../Home";
import { useMentorStudentsRD } from "../redux/hooks";
import { SRD, RD, notAsked, loading, failure, success } from "srd";
import {
  GqlUser,
  MentorshipProgramSlim,
  MentorshipProgramStudent,
  Student,
} from "../../server/serverTypes";
import { AxiosError } from "axios";
import { useUserPrograms } from "../../user/redux/hooks";
import ErrorPage from "../../components/ErrorPage";
import LoadingPage from "../../components/LoadingPage";

const HomeContainer = () => {
  const studentsRemoteData = useUserPrograms();

  return SRD.match(
    {
      notAsked: () => <LoadingPage />,
      loading: () => <LoadingPage />,
      failure: (msg: Error) => <ErrorPage />,
      success: (students: MentorshipProgramSlim[]) => (
        <Home students={students} />
      ),
    },
    studentsRemoteData
  );
};

export default HomeContainer;

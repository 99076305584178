import { AxiosError } from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SRD } from "srd";
import { RootState } from "../../common/redux/reducers";
import { MentorshipProgramConnection } from "../../server/serverTypes";
import { actions } from "./model";

export const useStudentProgramsRD = () => {
  const dispatch = useDispatch();
  const studentPrograms = useSelector(
    (state: RootState) => state.mentee.menteePrograms
  );
  const userRemoteData = useSelector(
    (state: RootState) => state.user.userRemoteData
  );

  if (SRD.isNotAsked(studentPrograms) && SRD.isSuccess(userRemoteData)) {
    dispatch(actions.fetchMenteePrograms({ id: userRemoteData.data.user.id }));
  }

  return studentPrograms;
};

export const useMentorshipProgramFromStudentRD = (firebaseId: string) => {
  const rd = useSelector((state: RootState) => state.mentee.menteeProgramRD);
  const userRemoteData = useSelector(
    (state: RootState) => state.user.userRemoteData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    SRD.match(
      {
        notAsked: () => {
          if (SRD.isSuccess(userRemoteData)) {
            dispatch(
              actions.fetchMentorshipProgramMentee({
                id: userRemoteData.data.user.id,
                firebaseHash: firebaseId,
              })
            );
          }
        },
        loading: () => {
          //
        },
        failure: (msg: AxiosError) => {
          //
        },
        success: (mentorshipProgram: MentorshipProgramConnection) => {
          if (
            SRD.isSuccess(userRemoteData) &&
            firebaseId !== mentorshipProgram.mentor.firebaseHash
          ) {
            dispatch(
              actions.fetchMentorshipProgramMentee({
                id: userRemoteData.data.user.id,
                firebaseHash: firebaseId,
              })
            );
          }
        },
      },
      rd
    );
  }, [rd, dispatch, firebaseId]);

  return rd;
};

export const useMenteeMentors = () =>
  useSelector((state: RootState) => state.mentee.menteeMentors);

export const useMenteeProgramInfo = () =>
  useSelector((state: RootState) => state.mentee.menteeProgramInfo);

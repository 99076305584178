import { ActionType, action } from "typesafe-actions";
import {
  GqlUser,
  RisingStarProgram,
  RisingStarSession,
  UserType,
} from "../../server/serverTypes";
import { failure, loading, notAsked, RD, SRD, success } from "srd";
import { actions as taskActions } from "../../task/redux/model";
import { actions as userActions } from "../../user/redux/model";
import { actions as sesssionActions } from "../../sessions/redux/model";

interface IModel {
  risingStarProgramRemoteData: RD<Error, RisingStarProgram>;
}

const initialState: IModel = {
  risingStarProgramRemoteData: notAsked(),
};

export const actions = {
  fetchRisingStarsProgram: (p: {
    id: number;
    firebaseHash: string;
    isStudent: boolean;
  }) => action("FETCH_RISING_STAR_PROGRAM", p),
  receiveRisingStarsProgram: (p: RisingStarProgram) =>
    action("RECEIVE_RISING_STAR_PROGRAM", p),
  fetchRisingStarsProgramError: (p: Error) =>
    action("FETCH_RISING_STAR_PROGRAM_ERROR", p),

  createRisingStarProgram: (p: {
    id: number;
    student: {
      name: string;
      firebaseId: string;
      email: string;
      type: UserType;
    };
  }) => action("CREATE_RISING_STAR_PROGRAM", p),
  receiveCreatedRisingStarProgram: (p: GqlUser) =>
    action("RECEIVE_CREATED_RISING_STAR_PROGRAM", p),
  createRisingStarProgramError: (p: Error) =>
    action("CREATE_RISING_STAR_PROGRAM_ERROR", p),
};

export type AdminActionType =
  | ActionType<typeof actions>
  | ActionType<typeof taskActions>
  | ActionType<typeof userActions>
  | ActionType<typeof sesssionActions>;

export const reducer = (
  state: IModel = initialState,
  action: AdminActionType
): IModel => {
  switch (action.type) {
    case "SIGN_OUT":
      return {
        ...state,
        risingStarProgramRemoteData: notAsked(),
      };
    case "FETCH_RISING_STAR_PROGRAM":
      return {
        ...state,
        risingStarProgramRemoteData: loading(),
      };
    case "RECEIVE_RISING_STAR_PROGRAM":
      return {
        ...state,
        risingStarProgramRemoteData: success(action.payload),
      };
    case "FETCH_RISING_STAR_PROGRAM_ERROR":
      return {
        ...state,
        risingStarProgramRemoteData: failure(action.payload),
      };

    case "RECEIVE_CREATED_RISING_STAR_PROGRAM":
      return {
        ...state,
      };

    case "RECEIVE_ADDED_RISING_STAR_TASK":
      const upt = SRD.match(
        {
          notAsked: () => {
            return state.risingStarProgramRemoteData;
          },
          loading: () => {
            return state.risingStarProgramRemoteData;
          },
          failure: (msg: Error) => {
            return state.risingStarProgramRemoteData;
          },
          success: (risingStarProgram: RisingStarProgram) => {
            return success({
              ...risingStarProgram,
              tasks: [...risingStarProgram.tasks, action.payload],
            });
          },
        },
        state.risingStarProgramRemoteData
      );
      return {
        ...state,
        risingStarProgramRemoteData: upt,
      };

    case "RECEIVE_DELETED_RISING_STAR_TASK":
      return {
        ...state,
        risingStarProgramRemoteData: SRD.match(
          {
            notAsked: () => {
              return state.risingStarProgramRemoteData;
            },
            loading: () => {
              return state.risingStarProgramRemoteData;
            },
            failure: (msg: Error) => {
              return state.risingStarProgramRemoteData;
            },
            success: (risingStarProgram: RisingStarProgram) => {
              return success({
                ...risingStarProgram,
                tasks: risingStarProgram.tasks.filter(
                  (t) => t.id !== action.payload.id
                ),
              });
            },
          },
          state.risingStarProgramRemoteData
        ),
      };

    case "RECEIVE_COMPLETED_RISING_STAR_TASK":
      return {
        ...state,
        risingStarProgramRemoteData: SRD.match(
          {
            notAsked: () => {
              return state.risingStarProgramRemoteData;
            },
            loading: () => {
              return state.risingStarProgramRemoteData;
            },
            failure: (msg: Error) => {
              return state.risingStarProgramRemoteData;
            },
            success: (risingStarProgram: RisingStarProgram) => {
              return success({
                ...risingStarProgram,
                tasks: risingStarProgram.tasks.map((t) => {
                  {
                    if (t.id === action.payload.id) {
                      return {
                        ...t,
                        completed: true,
                      };
                    } else {
                      return t;
                    }
                  }
                }),
              });
            },
          },
          state.risingStarProgramRemoteData
        ),
      };

    case "RECEIVE_ADDED_RISING_STAR_SESSION":
      const uptSessions = SRD.match(
        {
          notAsked: () => {
            return state.risingStarProgramRemoteData;
          },
          loading: () => {
            return state.risingStarProgramRemoteData;
          },
          failure: (msg: Error) => {
            return state.risingStarProgramRemoteData;
          },
          success: (risingStarProgram: RisingStarProgram) => {
            return success({
              ...risingStarProgram,
              sessions: [
                ...risingStarProgram.sessions,
                action.payload as RisingStarSession,
              ],
            });
          },
        },
        state.risingStarProgramRemoteData
      );
      return {
        ...state,
        risingStarProgramRemoteData: uptSessions,
      };
    default:
      return state;
  }
};

import { push } from "connected-react-router";
import { all, put, takeLatest } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { actions } from "./model";

function* toRoute(action: ActionType<typeof actions.toRoute>) {
  yield put(push(action.payload));
}
function* watchToRouteAsync() {
  yield takeLatest("TO_ROUTE", toRoute);
}
export default function* rootSaga() {
  yield all([watchToRouteAsync()]);
}

import { AxiosResponse } from "axios";
import { push } from "connected-react-router";
import gql from "graphql-tag";
import { all, put, call, takeEvery } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { axiosGqlRequest } from "../../server/common";
import { RisingStarVideo } from "../../server/serverTypes";

import { actions } from "./model";

function* fetchVideos(action: ActionType<typeof actions>) {
  try {
    const gqlRes: AxiosResponse<{ data: { queryVideos: RisingStarVideo[] } }> =
      yield call(axiosGqlRequest, { query: VIDEOS_QUERY });
    yield put(actions.receiveVideos(gqlRes.data.data.queryVideos));
  } catch (error) {
    yield put(actions.fetchVideosError(error as Error));
  }
}

const VIDEOS_QUERY = gql`
  query queryVideos {
    queryVideos {
      id
      title
      link
      description
    }
  }
`;

function* watchFetchVideos() {
  yield takeEvery("FETCH_VIDEOS", fetchVideos);
}

function* createVideo(action: ActionType<typeof actions.addVideo>) {
  try {
    const gqlRes: AxiosResponse<{ data: { createVideo: RisingStarVideo } }> =
      yield call(axiosGqlRequest, {
        query: ADD_VIDEO,
        variables: { param: action.payload },
      });
    yield put(actions.receiveAddedVideo(gqlRes.data.data.createVideo));
    yield put(push("/videos"));
  } catch (error) {
    yield put(actions.addVideoError(error as Error));
  }
}

const ADD_VIDEO = gql`
  mutation createVideo($param: InputVideo!) {
    createVideo(param: $param) {
      id
      title
      link
      description
    }
  }
`;

function* watchCreateVideo() {
  yield takeEvery("CREATE_VIDEO", createVideo);
}

export default function* rootSaga() {
  yield all([watchFetchVideos(), watchCreateVideo()]);
}

import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centered: {
      minHeight: "calc(100vh - 204px)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    h1: {
      marginBottom: "50px",
      fontFamily: "'Lato', sans-serif",
      fontSize: "50px",
    },
  })
);

const ErrorPage = () => {
  const classes = useStyles();

  return (
    <section className={classes.centered}>
      <h1 className={classes.h1}>500 Error</h1>
      <div>
        <div>Whoops. It appears something went wrong. Apologies</div>
      </div>
    </section>
  );
};

export default ErrorPage;

import React from "react";
import { useDispatch } from "react-redux";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import AddTask from "../views/AddTask";
import { InputTask } from "../../server/serverTypes";
import { actions } from "../redux/model";

interface IAdminAddTaskContainer {
  id: string;
}

const AdminAddTaskContainer = ({ id }: IAdminAddTaskContainer) => {
  const dispatch = useDispatch();
  const [name, setName] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [date, setDate] = React.useState<Date>(new Date());

  const onSubmit = () => {
    if (typeof Number(id) === "number") {
      const taskObj: InputTask = {
        title: name,
        description: description,
        dueDate: date,
        courseId: Number(id),
      };
      dispatch(actions.addRisingStarTask(taskObj));
    }
  };

  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (date !== null) setDate(date);
  };

  const handleNameChange = (val: string) => {
    setName(val);
  };

  const handleDescriptionChange = (val: string) => {
    setDescription(val);
  };

  return (
    <AddTask
      name={name}
      description={description}
      date={date}
      addSampleTask={onSubmit}
      handleNameChange={handleNameChange}
      handleDateChange={handleDateChange}
      handleDescriptionChange={handleDescriptionChange}
    />
  );
};

export default AdminAddTaskContainer;

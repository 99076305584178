import { Close as CloseIcon } from "@material-ui/icons";
import React from "react";
import { IModel as IToastModel } from "./redux/model";
import { Func1 } from "redux";
import { useToaster } from "./hooks";
import { Snackbar, Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      //   backgroundColor: theme.palette.heyauto.secondaryBlue,
    },
    undo: {
      //   color: theme.palette.heyauto.mainYellow,
      cursor: "pointer",
    },
    closeIcon: {
      //   color: theme.palette.heyauto.mainYellow,
      cursor: "pointer",
    },
  })
);

interface IToaster {
  setOpen: Func1<
    boolean,
    {
      type: "SET_OPEN";
      payload: boolean;
    }
  >;
}

const ToasterComponent = ({ setOpen }: IToaster) => {
  const toast = useToaster();
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClose = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway" && toast.autoHideDuration === null) {
      return;
    }
    setOpen(false);
  };
  return (
    <Snackbar
      style={{ height: "100%" }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={toast.open}
      message={toast.message}
      autoHideDuration={toast.autoHideDuration}
      action={<CloseIcon onClick={handleClose} />}
      onClose={handleClose}
    />
  );
};

export default ToasterComponent;

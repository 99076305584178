import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Calendar from "react-calendar";
import "./Calendar.css";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import clsx from "clsx";
import { deviceDesktopBreakpoint } from "../../styles/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 204px)",
    [theme.breakpoints.up(deviceDesktopBreakpoint)]: {
      padding: "0px 240px",
      height: "100vh",
    },
  },
  h3: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    color: "#00664F",
    lineHeight: "22px",
    margin: "20px 0px",
  },
  h4: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    color: "#272727",
  },
  mainContent: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    color: "#272727",
    lineHeight: "22px",
    margin: "20px 0px",
  },
  mainContentDate: {
    fontWeight: 400,
    margin: 0,
  },
  dateContainer: {
    margin: "20px 0px",
  },
  calendar: {
    border: "none",
    background: "#FCFAF5",
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
  },
  calendarTile: {},
  btn: {
    background: "#4DC591",
    borderRadius: "8px",
    width: "233px",
    height: "38px",
    margin: "10px 0px",
  },
  sessionName: {
    width: "100%",
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(0, 0, 0, 0.6)",
    },
  },
  textfield: {},
}));

const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

interface IAddSession {
  name: string;
  date: Date;
  time: string;
  handleNameChange: (val: string) => void;
  handleDateChange: (date: Date) => void;
  handleTimeChange: (val: string) => void;
  addSampleSession: () => void;
}

const AddSession = ({
  name,
  date,
  time,
  handleNameChange,
  handleDateChange,
  handleTimeChange,
  addSampleSession,
}: IAddSession) => {
  const classes = useStyles();

  const dateString = `${
    weekdays[date.getDay()]
  }, ${date.getDate()} ${date.getFullYear()}`;

  return (
    <Container className={classes.container}>
      <Typography className={classes.h3}>Create a session</Typography>
      <Typography className={classes.mainContent}>
        Choose a date, time, and frequency of the sessions.
      </Typography>
      <div style={{ textAlign: "-webkit-center" as "center" }}>
        <Calendar
          onChange={handleDateChange}
          value={date}
          className={classes.calendar}
          tileClassName={classes.calendarTile}
        />
      </div>
      <Grid container className={classes.dateContainer}>
        <Grid item>
          <AccessTimeIcon />
        </Grid>
        <Grid item>
          <Typography
            className={clsx(classes.mainContent, classes.mainContentDate)}
          >
            {dateString}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Session Name"
          className={classes.sessionName}
          InputLabelProps={{
            style: { color: "#757575" },
          }}
          value={name}
          onChange={(e) => handleNameChange(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="time"
          label="Session Time"
          type="time"
          value={time}
          placeholder="13:30"
          onChange={(e) => handleTimeChange(e.target.value)}
          className={classes.sessionName}
          InputLabelProps={{
            style: { color: "#757575" },
          }}
          inputProps={{
            step: 300, // 5 min
          }}
        />
      </Grid>
      <Grid container justify="center">
        <Button className={classes.btn} onClick={addSampleSession}>
          <Typography className={classes.h4}>Create sessions</Typography>
        </Button>
      </Grid>
    </Container>
  );
};
export default AddSession;

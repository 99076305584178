import { action, ActionType } from "typesafe-actions";
import { actions as userActions } from "../../user/redux/model";
import { actions as taskActions } from "../../task/redux/model";
import { actions as vidoeActions } from "../../videos/redux/model";
import { actions as adminActions } from "../../admin/redux/model";
import { actions as mentorActions } from "../../mentor/redux/model";
import { actions as sessionActions } from "../../sessions/redux/model";
export type ToastMessage = string | null;

export enum Severity {
  error = "error",
  warning = "warning",
  info = "info",
  success = "success",
}

export interface IModel {
  message: ToastMessage;
  severity: Severity;
  open: boolean;
  autoHideDuration: number | null;
}

const initialState: IModel = {
  message: null,
  severity: Severity.info,
  open: false,
  autoHideDuration: 1500,
};

export const actions = {
  setMessage: (p: ToastMessage) => action("SET_MESSAGE", p),
  setSeverity: (p: Severity) => action("SET_SEVERITY", p),
  resetToast: () => action("RESET_TOAST"),
  setOpen: (p: boolean) => action("SET_OPEN", p),
  setToastParams: (p: IModel) => action("SET_TOAST_PARAMS", p),
};

export type ToasterActionType = ActionType<typeof actions>;
type CombinedActionType =
  | ActionType<typeof actions>
  | ActionType<typeof userActions>
  | ActionType<typeof taskActions>
  | ActionType<typeof vidoeActions>
  | ActionType<typeof adminActions>
  | ActionType<typeof mentorActions>
  | ActionType<typeof sessionActions>;

export const reducer = (
  state: IModel = initialState,
  action: CombinedActionType
): IModel => {
  switch (action.type) {
    case "RESET_TOAST":
      return {
        ...state,
        message: null,
        severity: Severity.info,
      };
    case "SET_MESSAGE":
      return {
        ...state,
        message: action.payload,
      };
    case "SET_SEVERITY":
      return {
        ...state,
        severity: action.payload,
      };
    case "SET_TOAST_PARAMS": {
      return {
        ...state,
        severity: action.payload.severity,
        message: action.payload.message,
        autoHideDuration: action.payload.autoHideDuration,
        open: action.payload.open,
      };
    }
    case "SET_OPEN":
      return {
        ...state,
        open: action.payload,
      };

    case "RECEIVE_COMPLETED_MENTORSHIP_TASK":
      return {
        ...state,
        open: true,
        message: "Task completed!",
        severity: Severity.success,
        autoHideDuration: 1500,
      };
    case "RECEIVE_DELETED_MENTORSHIP_TASK":
      return {
        ...state,
        open: true,
        message: "Task Deleted!",
        severity: Severity.success,
        autoHideDuration: 1500,
      };

    case "RECEIVE_EDITED_MENTORSHIP_TASK":
      return {
        ...state,
        open: true,
        message: "Task updated!",
        severity: Severity.success,
        autoHideDuration: 1500,
      };
    // case "VERIFY_USER_CREDS_SUCCESS":
    //   return {
    //     ...state,
    //     open: true,
    //     message: "Login successful",
    //     severity: Severity.success,
    //     autoHideDuration: 6000,
    //   };

    // case "VERIFY_USER_CREDS_SUCCESS_WITH_GOOGLE":
    //   return {
    //     ...state,
    //     open: true,
    //     message: "Login successful",
    //     severity: Severity.success,
    //     autoHideDuration: 6000,
    //   };

    case "VERIFY_USER_CREDS_FAILURE":
      return {
        ...state,
        open: true,
        message: "Email/password is wrong. Please try again",
        severity: Severity.error,
        autoHideDuration: 6000,
      };

    case "VERIFY_USER_CREDS_FAILURE_WITH_GOOGLE":
      return {
        ...state,
        open: true,
        message: "Email/password is wrong. Please try again",
        severity: Severity.error,
        autoHideDuration: 6000,
      };

    case "RECEIVE_CREATED_VIDEO":
      return {
        ...state,
        open: true,
        message: "Video Added",
        severity: Severity.success,
        autoHideDuration: 1500,
      };

    case "RECEIVE_CREATED_RISING_STAR_PROGRAM":
      return {
        ...state,
        open: true,
        message: "Student Added!",
        severity: Severity.success,
        autoHideDuration: 1500,
      };

    case "CREATE_RISING_STAR_PROGRAM_ERROR":
      return {
        ...state,
        open: true,
        message: "Unable to add student at the moment",
        severity: Severity.error,
        autoHideDuration: 6000,
      };

    case "RECEIVE_CREATED_MENTORSHIP_PROGRAM":
      return {
        ...state,
        open: true,
        message: "Student Added!",
        severity: Severity.success,
        autoHideDuration: 1500,
      };

    case "CREATE_MENTORSHIP_PROGRAM_ERROR":
      return {
        ...state,
        open: true,
        message: "Unable to add student at the moment",
        severity: Severity.error,
        autoHideDuration: 6000,
      };

    case "RECEIVE_EDITED_RISING_STAR_SESSION":
      return {
        ...state,
        open: true,
        message: "Session was updated!",
        severity: Severity.success,
        autoHideDuration: 1500,
      };
    default:
      return state;
  }
};

import React from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { blackmintTheme, deviceDesktopBreakpoint } from "../../styles/theme";
import { Link } from "react-router-dom";
import { InputMentorshipTask, MentorshipTask } from "../../server/serverTypes";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useProgramInfo } from "../../mentor/redux/hooks";
import { useDispatch } from "react-redux";
import { actions } from "../redux/model";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 204px)",
    [theme.breakpoints.up(deviceDesktopBreakpoint)]: {
      padding: "0px 240px",
      height: "100vh",
    },
  },
  h3: {
    ...blackmintTheme.typographyBlackmint.h3,
    fontWeight: 600,
    color: blackmintTheme.paletteBlackmint.secondary,
    // margin: "20px 0px",
  },
  h4: {
    ...blackmintTheme.typographyBlackmint.h4,
  },
  taskDescription: {
    width: "100%",
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(0, 0, 0, 0.6)",
    },
  },
  datePicker: {
    width: "100%",
    "& .MuiInputBase-root": {
      padding: 0,
      "& .MuiButtonBase-root": {
        padding: 0,
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(0, 0, 0, 0.6)",
    },
  },
  btn: {
    backgroundColor: blackmintTheme.paletteBlackmint.primary,
    borderRadius: theme.spacing(1),
    padding: "8px 42px",
  },
  containerMargin: {
    margin: "15px 0px",
  },
}));

interface IEditTask {
  task: MentorshipTask;
  updateTask: (val: {
    id: number;
    title: string;
    description: string;
    dueDate: Date;
  }) => void;
}

const EditTask = ({ task, updateTask }: IEditTask) => {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState<Date>(task.dueDate);

  const [taskDescription, setTaskDescription] = React.useState(
    task.description
  );

  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (date !== null) setSelectedDate(date);
  };

  const handleUpdate = () => {
    updateTask({
      id: task.id,
      title: task.title,
      description: taskDescription,
      dueDate: selectedDate,
    });
  };

  return (
    <Container className={classes.container}>
      <Grid container>
        <Grid item className={classes.containerMargin}>
          <Typography className={classes.h3}>Edit task details</Typography>
        </Grid>
        <Grid item xs={12} className={classes.containerMargin}>
          <TextField
            label={"Description of the task"}
            className={classes.taskDescription}
            InputLabelProps={{
              style: { color: "#757575" },
            }}
            value={taskDescription}
            onChange={(e) => {
              setTaskDescription(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.containerMargin}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Due date"
              format="EEEE,dd MMMM"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              keyboardIcon={<ScheduleIcon />}
              InputAdornmentProps={{ position: "start" }}
              className={classes.datePicker}
              InputLabelProps={{
                style: { color: "#757575" },
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ textAlign: "center" }}
          className={classes.containerMargin}
        >
          <Button className={classes.btn} onClick={handleUpdate}>
            <Typography className={classes.h4}>Update task</Typography>
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
export default EditTask;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Firebase from "../../common/Firebase";
import MenteeComponentSlim from "../../components/MenteeComponentSlim";
import { UserType } from "../../server/serverTypes";
import { useUserObj } from "../../user/redux/hooks";
import AddMentee from "../AddMentee";
import { actions } from "../redux/model";
import OnboardMenteesView from "../views/OnboardMenteesView";

export interface firebaseUserDoc {
  id: string;
  email: string;
  name: string;
  username?: string;
  joined?: Date;
}

const AddMenteeContainer = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [mentees, setMentees] = useState<firebaseUserDoc[]>([]);
  const [showForm, setShowForm] = useState<boolean>(true);

  const user = useUserObj();

  const handleChangeName = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setName(event.target.value);
  };

  const handleChangeEmail = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmail(event.target.value);
  };

  const onSubmit = async () => {
    try {
      const userPromise = await Firebase.userByEmail(email)
        .get()
        .then(async (u) => {
          if (u.docs.length === 0) {
            throw new Error("no users");
          }
          const mappedUsers = u.docs.map((d) => {
            const data = d.data();
            const res: firebaseUserDoc = {
              id: data.id,
              email: data.email,
              name: data.personalInfo.name
                ? data.personalInfo.name
                : data.username,
              username: data.username,
              joined: data.joined,
            };
            return res;
          });
          setMentees(mappedUsers);
          setShowForm(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handlePageChange = () => {
    setShowForm(true);
  };

  const handleMenteeClick = (f: firebaseUserDoc) => {
    // Get user id
    console.log(f);
    const student = {
      name: name,
      firebaseId: f.id,
      email: f.email,
      type: UserType.student,
    };
    if (user) {
      dispatch(
        actions.createMentorshipProgram({ id: user.user.id, student: student })
      );
    }
  };

  return showForm ? (
    <AddMentee
      email={email}
      handleChangeEmail={handleChangeEmail}
      name={name}
      handleChangeName={handleChangeName}
      onSubmit={onSubmit}
    />
  ) : (
    <OnboardMenteesView
      mentees={mentees}
      handlePageChange={handlePageChange}
      handleMenteeClick={handleMenteeClick}
    />
  );
};

export default AddMenteeContainer;

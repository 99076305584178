import { useDispatch, useSelector } from "react-redux";
import { SRD } from "srd";
import { RootState } from "../../common/redux/reducers";
import { actions } from "./model";

export const useVideos = () => {
  const videoRemoteData = useSelector(
    (state: RootState) => state.videos.videos
  );
  const dispatch = useDispatch();

  if (videoRemoteData.tag === "NotAsked") {
    dispatch(actions.fetchVideos());
  }
  return videoRemoteData;
};

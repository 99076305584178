import { AxiosResponse } from "axios";
import gql from "graphql-tag";
import { takeEvery, all, put, call } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { axiosGqlRequest } from "../../server/common";
import { GqlUser, RisingStarProgram } from "../../server/serverTypes";
import { actions } from "./model";
import { goBack, push } from "connected-react-router";

function* fetchRisingStarProgram(
  action: ActionType<typeof actions.fetchRisingStarsProgram>
) {
  try {
    const data: AxiosResponse<{
      data: { queryRisingStarProgram: RisingStarProgram };
    }> = yield call(axiosGqlRequest, {
      query: RISING_STAR_PROGRAM_QUERY,
      variables: {
        params: {
          id: action.payload.id,
          firebaseHash: action.payload.firebaseHash,
          isStudent: action.payload.isStudent,
        },
      },
    });
    yield put(
      actions.receiveRisingStarsProgram(data.data.data.queryRisingStarProgram)
    );
  } catch (error) {
    yield put(actions.fetchRisingStarsProgramError(error as Error));
  }
}

const RISING_STAR_PROGRAM_QUERY = gql`
  query queryRisingStarProgram($params: InputRisingStarProgramParam!) {
    queryRisingStarProgram(params: $params) {
      id
      tasks {
        id
        title
        dueDate
        assignmentLink
        completed
      }
      sessions {
        id
        sessionDate
        topic
        description
        sessionLink
      }
      student {
        id
        name
        email
        firebaseHash
      }
      admin {
        id
        name
        email
        firebaseHash
      }
      isLive
      courseId
    }
  }
`;

function* watchFetchRisingStarProgram() {
  yield takeEvery("FETCH_RISING_STAR_PROGRAM", fetchRisingStarProgram);
}

const CREATE_RISING_STAR_PROGRAM = gql`
  mutation createRisingStarProgram($params: InputRisingStarProgramCreate!) {
    createRisingStarProgram(params: $params) {
      id
      name
      email
      firebaseHash
    }
  }
`;

function* createRisingStarProgram(
  action: ActionType<typeof actions.createRisingStarProgram>
) {
  try {
    const data: AxiosResponse<{
      data: { createRisingStarProgram: GqlUser };
    }> = yield call(axiosGqlRequest, {
      query: CREATE_RISING_STAR_PROGRAM,
      variables: {
        params: {
          id: action.payload.id,
          student: action.payload.student,
        },
      },
    });
    yield put(
      actions.receiveCreatedRisingStarProgram(
        data.data.data.createRisingStarProgram
      )
    );
    yield put(goBack());
  } catch (error) {
    yield put(actions.createRisingStarProgramError(error as Error));
  }
}

function* watchCreateRisingStarProgram() {
  yield takeEvery("CREATE_RISING_STAR_PROGRAM", createRisingStarProgram);
}

export default function* rootSaga() {
  yield all([watchFetchRisingStarProgram(), watchCreateRisingStarProgram()]);
}

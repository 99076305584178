import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BlackMintLogo } from "./BlackMintIcon";

const useStyles = makeStyles((theme) => ({
  footer: {
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "#272727",
    color: "#FFFFFF",
    textAlign: "center",
    height: "144px",
  },
  link: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textDecorationLine: "underline",
    color: "#FFFFFF",
  },
  linkContainer: {
    margin: "5px 0px",
    textAlign: "end",
    maxWidth: "33%",
    flexBasis: "33%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      flexBasis: "100%",
      textAlign: "justify",
    },
  },
  parentContainer: {
    justifyContent: "flex-end",
    height: "100%",
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "initial",
    },
  },
  container: {
    placeSelf: "center",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div className={classes.footer}>
      <Grid container className={classes.parentContainer}>
        {/* {desktop ? null : ( */}
        <>
          <Grid xs={6} sm={6} md={6} className={classes.container}>
            <Grid container>
              <div className={classes.linkContainer}>
                <a className={classes.link} href={"https://www.blackmint.io/"}>
                  About BlackMINT
                </a>
              </div>
              <div className={classes.linkContainer}>
                <a
                  className={classes.link}
                  href={"https://www.blackmint.io/get-involved"}
                >
                  Get Involved
                </a>
              </div>
              <div className={classes.linkContainer}>
                <a
                  className={classes.link}
                  href={"https://www.blackmint.io/our-programs"}
                >
                  Our Programs
                </a>
              </div>
            </Grid>
          </Grid>
        </>
        {/* )} */}
        <Grid xs={6} sm={6} md={6} className={classes.container}>
          <BlackMintLogo />
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;

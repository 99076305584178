import {
  Theme,
  createStyles,
  makeStyles,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { ComponentType } from "react";
import { deviceDesktopBreakpoint } from "../styles/theme";
import SideDrawer from "./SideDrawer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
    footer: {
      marginTop: "auto",
      width: "100%",
    },
    container: {
      display: "flex",
      flex: 1,
    },
    main: {
      flex: 1,
    },
  })
);

interface IWithHeaderFooter {
  Footer: React.ComponentType;
  Header: React.ComponentType;
}

const withFooter =
  ({ Footer, Header }: IWithHeaderFooter) =>
  <T extends Record<string, unknown>>(
    WrappedComponent: ComponentType<T>
  ): React.FC<T> =>
  ({ ...props }) => {
    const classes = useStyles();
    const theme = useTheme();
    const desktop = useMediaQuery(
      theme.breakpoints.up(deviceDesktopBreakpoint)
    );

    return (
      <div className={classes.root}>
        <Header />
        <Toolbar />
        <div className={classes.container}>
          {desktop && <SideDrawer />}
          <main className={classes.main}>
            <WrappedComponent {...(props as T)} />
          </main>
        </div>
        {!desktop && (
          <div className={classes.footer}>
            <Footer />
          </div>
        )}
      </div>
    );
  };

export default withFooter;

import {
  Mentor,
  MentorshipProgramConnection,
  MentorshipSession,
  MentorshipTask,
  Student,
} from "../server/serverTypes";
// import MentorMainPage from "../Main";

import MenteeDashboard from "./MenteeDashboard";
import { useMentorshipProgramFromStudentRD } from "./redux/hooks";
import { SRD } from "srd";
import { AxiosError } from "axios";
import ErrorPage from "../components/ErrorPage";
import LoadingPage from "../components/LoadingPage";

interface IMenteeContainer {
  id: string;
}

const MenteeContainer = ({ id }: IMenteeContainer) => {
  const rd = useMentorshipProgramFromStudentRD(id);

  return SRD.match(
    {
      notAsked: () => <LoadingPage />,
      loading: () => <LoadingPage />,
      failure: (msg: AxiosError) => <ErrorPage />,
      success: (mentorshipProgram: MentorshipProgramConnection) => {
        return (
          <MenteeDashboard
            tasks={mentorshipProgram.tasks}
            sessions={mentorshipProgram.sessions}
            mentor={mentorshipProgram.mentor}
          />
        );
      },
    },
    rd
  );
};

export default MenteeContainer;

import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  TextField,
} from "@material-ui/core";
import ReactPlayer from "react-player";
import { RisingStarVideo } from "../../server/serverTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    card: {
      margin: theme.spacing(2),
    },
    cardMedia: {
      height: "240px",
      width: "100%",
    },
  })
);

interface IVideosDashboard {
  videos: RisingStarVideo[];
}

const VideosDashboard = ({ videos }: IVideosDashboard) => {
  const classes = useStyles();
  return (
    <Grid container>
      {/* <TextField /> */}
      {videos.map((v) => (
        <Grid item xs={12} className={classes.card}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia className={classes.cardMedia}>
                <ReactPlayer
                  width={"100%"}
                  height={"100%"}
                  url={v.link}
                  controls
                />
              </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {v.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {v.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default VideosDashboard;

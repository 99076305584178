import { ActionType, action } from "typesafe-actions";
import { failure, loading, notAsked, RD, SRD, success } from "srd";
import {
  InputRisingStarVideo,
  RisingStarVideo,
} from "../../server/serverTypes";

interface IModel {
  videos: RD<Error, RisingStarVideo[]>;
}

const initialState: IModel = {
  videos: notAsked(),
};

export const actions = {
  fetchVideos: () => action("FETCH_VIDEOS"),
  receiveVideos: (p: RisingStarVideo[]) => action("RECEIVE_VIDEOS", p),
  fetchVideosError: (p: Error) => action("FETCH_VIDEOS_ERROR", p),

  addVideo: (p: InputRisingStarVideo) => action("CREATE_VIDEO", p),
  receiveAddedVideo: (p: RisingStarVideo) => action("RECEIVE_CREATED_VIDEO", p),
  addVideoError: (p: Error) => action("CREATE_VIDEO_ERROR", p),
};

export type VideosActionType = ActionType<typeof actions>;

export const reducer = (
  state: IModel = initialState,
  action: VideosActionType
): IModel => {
  switch (action.type) {
    case "FETCH_VIDEOS":
      return {
        ...state,
        videos: loading(),
      };

    case "RECEIVE_VIDEOS":
      return {
        ...state,
        videos: success(action.payload),
      };

    case "FETCH_VIDEOS_ERROR":
      return {
        ...state,
        videos: failure(action.payload),
      };

    case "RECEIVE_CREATED_VIDEO":
      return {
        ...state,
        videos: SRD.match(
          {
            notAsked: () => state.videos,
            loading: () => state.videos,
            failure: (e: Error) => state.videos,
            success: (v: RisingStarVideo[]) => {
              return success([action.payload, ...v]);
            },
          },
          state.videos
        ),
      };
    default:
      return state;
  }
};

import React from "react";
import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Student } from "../server/serverTypes";
import PersonIcon from "@material-ui/icons/Person";

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 370,
    maxHeight: 88,
    background: "#F1F1EE",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    margin: "10px 0px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  avatar: {
    color: "white",
    background: "#E6E6E6",
    border: "2px solid #C4C4C4",
  },
  cardAction: {
    margin: 0,
  },
  h3: {
    fontFamily: "Inter",
    fontWeight: "bold",
    fontSize: "20px",
    color: "#757575",
    whiteSpace: "nowrap",
  },
  content: {
    fontFamily: "Inter",
    fontWeight: "normal",
    fontSize: "16px",
    color: "#757575",
  },
}));

interface IMenteeComponentGrey {
  name: string;
  email: string;
}

const MenteeComponentGrey = ({ name, email }: IMenteeComponentGrey) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader
        classes={{ action: classes.cardAction }}
        avatar={
          <Avatar alt={name} className={classes.avatar} src="./">
            <PersonIcon />
          </Avatar>
        }
        title={
          <React.Fragment>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography className={classes.h3}>{name} (pending)</Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        }
        subheader={<Typography className={classes.content}>{email}</Typography>}
      />
    </Card>
  );
};

export default MenteeComponentGrey;

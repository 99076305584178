import { AxiosError, AxiosResponse } from "axios";
import { all, put, call, takeEvery } from "redux-saga/effects";
import gql from "graphql-tag";
import { actions } from "./model";
import {
  GqlUser,
  MentorshipProgramConnection,
  MentorshipProgramSlim,
  MentorshipProgramStudent,
  Student,
} from "../../server/serverTypes";
import { axiosGqlRequest } from "../../server/common";
import { ActionType } from "typesafe-actions";
import { goBack, push } from "connected-react-router";

function* fetchMentorProgram(
  action: ActionType<typeof actions.fetchMentorshipProgram>
) {
  try {
    const data: AxiosResponse<{
      data: {
        queryMentorshipProgramFromMentor: MentorshipProgramConnection;
      };
    }> = yield call(axiosGqlRequest, {
      query: MENTORSHIP_PROGRAM_CONNECTION_QUERY_2,
      variables: {
        params: {
          id: action.payload.id,
          firebaseHash: action.payload.firebaseHash,
        },
      },
    });
    yield put(
      actions.receiveMentorshipProgram(
        data.data.data.queryMentorshipProgramFromMentor
      )
    );
  } catch (error) {
    yield put(actions.fetchMentorshipProgramError(error as AxiosError));
  }
}

const MENTORSHIP_PROGRAM_CONNECTION_QUERY_2 = gql`
  query queryMentorshipProgramFromMentor(
    $params: InputMentorshipProgramParam!
  ) {
    queryMentorshipProgramFromMentor(params: $params) {
      tasks {
        id
        title
        dueDate
        assignmentLink
        completed
      }
      sessions {
        id
        studentRating
        sessionDate
        topic
      }
      student {
        id
        name
        email
        firebaseHash
      }
      mentor {
        id
        name
        email
        firebaseHash
      }
      isLive
      id
    }
  }
`;

const MENTORSHIP_PROGRAM_CONNECTION_QUERY = gql`
  query queryMentorshipProgram($id: Int!) {
    queryMentorshipProgram(id: $id) {
      tasks {
        id
        title
        dueDate
        assignmentLink
        completed
      }
      sessions {
        id
        studentRating
        sessionDate
        topic
      }
      student {
        id
        name
        email
      }
      mentor {
        id
        name
        email
      }
      id
    }
  }
`;

function* watchFetchMentorProgram() {
  yield takeEvery("FETCH_MENTORSHIP_PROGRAM", fetchMentorProgram);
}

function* fetchMentorStudents(
  action: ActionType<typeof actions.fetchMentorStudents>
) {
  try {
    const data: AxiosResponse<{
      data: {
        queryUserPrograms: MentorshipProgramSlim[];
      };
    }> = yield call(axiosGqlRequest, {
      query: MENTORSHIP_STUDENTS_QUERY,
      variables: action.payload,
    });
    yield put(actions.receiveMentorStudents(data.data.data.queryUserPrograms));
  } catch (error) {
    yield put(actions.fetchMentorStudentsError(error as AxiosError));
  }
}

const MENTORSHIP_STUDENTS_QUERY = gql`
  query queryUserPrograms($id: Int!) {
    queryUserPrograms(id: $id) {
      id
      name
      email
      firebaseHash
      live
      type
    }
  }
`;

function* createMentorshipProgram(
  action: ActionType<typeof actions.createMentorshipProgram>
) {
  try {
    const data: AxiosResponse<{
      data: {
        createMentorshipProgram: GqlUser;
      };
    }> = yield call(axiosGqlRequest, {
      query: CREATE_MENTORSHIP_PROGRAM,
      variables: {
        params: {
          id: action.payload.id,
          student: action.payload.student,
        },
      },
    });
    yield put(
      actions.receiveCreatedMentorshipProgram(
        data.data.data.createMentorshipProgram
      )
    );
    yield put(goBack());
  } catch (error) {
    yield put(actions.createMentorshipProgramError(error as Error));
  }
}

const CREATE_MENTORSHIP_PROGRAM = gql`
  mutation createMentorshipProgram($params: InputMentorshipProgramCreate!) {
    createMentorshipProgram(params: $params) {
      id
      name
      email
      firebaseHash
    }
  }
`;

function* watchFetchMentorStudents() {
  yield takeEvery("FETCH_MENTOR_STUDENTS", fetchMentorStudents);
}

function* watchCreateMentorshipProgram() {
  yield takeEvery("CREATE_MENTORSHIP_PROGRAM", createMentorshipProgram);
}

export default function* rootSaga() {
  yield all([
    watchFetchMentorProgram(),
    watchFetchMentorStudents(),
    watchCreateMentorshipProgram(),
  ]);
}

import { AxiosError, AxiosResponse } from "axios";
import gql from "graphql-tag";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { axiosGqlRequest } from "../../server/common";
import {
  GqlUser,
  MentorshipProgramConnection,
  StudentPrograms,
} from "../../server/serverTypes";
import { actions } from "./model";

function* fetchMenteePrograms(
  action: ActionType<typeof actions.fetchMenteePrograms>
) {
  try {
    const data: AxiosResponse<{
      data: {
        queryProgramsForStudent: GqlUser[];
      };
    }> = yield call(axiosGqlRequest, {
      query: MENTEE_PROGRAMS_QUERY,
      variables: { id: action.payload.id },
    });
    yield put(
      actions.receiveMenteePrograms(data.data.data.queryProgramsForStudent)
    );
  } catch (error) {
    yield put(actions.fetchMenteeProgramsError(error as AxiosError));
  }
}

const MENTEE_PROGRAMS_QUERY = gql`
  query queryProgramsForStudent($id: Int!) {
    queryProgramsForStudent(id: $id) {
      id
      name
      email
      firebaseHash
      type
    }
  }
`;

function* watchFetchMenteePorgrams() {
  yield takeEvery("FETCH_MENTEE_PROGRAMS", fetchMenteePrograms);
}

function* fetchMenteeProgram(
  action: ActionType<typeof actions.fetchMentorshipProgramMentee>
) {
  try {
    const data: AxiosResponse<{
      data: {
        queryMentorshipProgramFromMentee: MentorshipProgramConnection;
      };
    }> = yield call(axiosGqlRequest, {
      query: MENTORSHIP_PROGRAM_CONNECTION_QUERY_MENTEE,
      variables: {
        params: {
          id: action.payload.id,
          firebaseHash: action.payload.firebaseHash,
        },
      },
    });
    yield put(
      actions.receiveMentorshipProgramMentee(
        data.data.data.queryMentorshipProgramFromMentee
      )
    );
  } catch (error) {
    yield put(actions.fetchMentorshipProgramMenteeError(error as AxiosError));
  }
}

const MENTORSHIP_PROGRAM_CONNECTION_QUERY_MENTEE = gql`
  query queryMentorshipProgramFromMentee(
    $params: InputMentorshipProgramParam!
  ) {
    queryMentorshipProgramFromMentee(params: $params) {
      tasks {
        id
        title
        dueDate
        assignmentLink
        completed
      }
      sessions {
        id
        studentRating
        sessionDate
        topic
      }
      student {
        id
        name
        email
        firebaseHash
      }
      mentor {
        id
        name
        email
        firebaseHash
      }
      id
    }
  }
`;

function* watchFetchMenteeProgram() {
  yield takeEvery("FETCH_MENTEE_PROGRAM", fetchMenteeProgram);
}

export default function* rootSaga() {
  yield all([watchFetchMenteePorgrams(), watchFetchMenteeProgram()]);
}

import axios from "axios";
import { DocumentNode, print } from "graphql";

export interface IAxiosGqlRequest {
  query: DocumentNode;
  variables?: Object;
}

export const axiosGqlRequest = async ({
  query,
  variables,
}: IAxiosGqlRequest) => {
  console.log(process.env.NODE_ENV);
  return axios.post(
    process.env.NODE_ENV !== "production"
      ? "http://localhost:3001/api/graphql"
      : "https://mentor-portal.azurewebsites.net/api/graphql",
    {
      query: print(query),
      variables: {
        ...variables,
      },
    }
  );
};
// http://localhost:7071/api/
// https://mentor-portal.azurewebsites.net/api/graphql
// http://localhost:3001/api/graphql

import React from "react";
import { useDispatch } from "react-redux";
import { SRD } from "srd";
import ErrorPage from "../../components/ErrorPage";
import LoadingPage from "../../components/LoadingPage";
import { useMenteeProgramInfo } from "../../mentee/redux/hooks";
import { useProgramInfo } from "../../mentor/redux/hooks";
import {
  InputMentorshipSession,
  MentorshipSession,
} from "../../server/serverTypes";
import { useUserObj } from "../../user/redux/hooks";
import { useSession } from "../redux/hooks";
import { actions } from "../redux/model";
import MentorSessionPage from "../views/SessionPage";

interface IMentorSessionPageContainer {
  id: string;
  isMentor: boolean;
}

const MentorSessionPageContainer = ({
  id,
  isMentor,
}: IMentorSessionPageContainer) => {
  const dispatch = useDispatch();
  const sessionRd = useSession(Number(id));

  const mp = useProgramInfo();
  const menteeProgram = useMenteeProgramInfo();

  const updateSession = (session: {
    id: number;
    topic: string;
    description?: string;
    sessionDate: Date;
    notes?: string;
    sessionLink?: string;
    rating?: number;
  }) => {
    if (mp) {
      const sessionObj: InputMentorshipSession = {
        // ...session,
        id: session.id,
        topic: session.topic,
        description: session.description,
        sessionDate: session.sessionDate,
        notes: session.notes,
        sessionLink: session.sessionLink,
        fkMentorshipId: mp.id,
        studentRating: session.rating,
      };
      dispatch(actions.editMentorshipSession(sessionObj));
    } else if (menteeProgram) {
      const sessionObj: InputMentorshipSession = {
        id: session.id,
        topic: session.topic,
        description: session.description,
        sessionDate: session.sessionDate,
        notes: session.notes,
        sessionLink: session.sessionLink,
        fkMentorshipId: menteeProgram.id,
        studentRating: session.rating,
      };
      dispatch(actions.editMentorshipSession(sessionObj));
    }
  };

  return SRD.match(
    {
      notAsked: () => <LoadingPage />,
      loading: () => <LoadingPage />,
      failure: (msg: Error) => <ErrorPage />,
      success: (session: MentorshipSession) => (
        <MentorSessionPage
          session={session}
          isMentor={isMentor}
          updateSession={updateSession}
          rating={isMentor ? session.studentRating : session.mentorRating}
        />
      ),
    },
    sessionRd
  );
};

export default MentorSessionPageContainer;

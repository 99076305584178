import { AxiosResponse } from "axios";
import { push, goBack } from "connected-react-router";
import gql from "graphql-tag";
import { all, call, put, takeEvery, select } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { axiosGqlRequest } from "../../server/common";
import {
  MentorshipProgramConnection,
  MentorshipSession,
} from "../../server/serverTypes";
import { actions } from "./model";
import { RootState } from "../../common/redux/reducers";

function* fetchMentorshipSession(
  action: ActionType<typeof actions.fetchMentorshipSession>
) {
  try {
    const data: AxiosResponse<{
      data: {
        queryMentorshipSession: MentorshipSession;
      };
    }> = yield call(axiosGqlRequest, {
      query: MENTORSHIP_SESSION_QUERY,
      variables: action.payload,
    });
    yield put(
      actions.receiveMentorshipSession(data.data.data.queryMentorshipSession)
    );
  } catch (error) {
    yield put(actions.fetchMentorshipSessionError(error as Error));
  }
}

const MENTORSHIP_SESSION_QUERY = gql`
  query queryMentorshipSession($id: Int!) {
    queryMentorshipSession(id: $id) {
      id
      studentRating
      sessionDate
      topic
      description
      sessionLink
      mentorRating
      description
      notes
      sessionLink
    }
  }
`;

function* addMentorshipSession(
  action: ActionType<typeof actions.addMentorshipSession>
) {
  try {
    const data: AxiosResponse<{
      data: {
        createMentorshipSession: MentorshipSession;
      };
    }> = yield call(axiosGqlRequest, {
      query: ADD_MENTORSHIP_SESSION,
      variables: { session: action.payload },
    });
    yield put(
      actions.receiveAddedMentorshipSession(
        data.data.data.createMentorshipSession
      )
    );
    const a: MentorshipProgramConnection | undefined = yield select(
      (state: RootState) => state.mentor.programInfo
    );
    if (a) yield put(push("/mentor/" + a.student.firebaseHash));
  } catch (error) {
    yield put(actions.addMentorshipSessionError(error as Error));
  }
}

const ADD_MENTORSHIP_SESSION = gql`
  mutation createMentorshipSession($session: InputMentorshipSession!) {
    createMentorshipSession(session: $session) {
      id
      topic
      sessionDate
      description
    }
  }
`;

function* addRisingStarSession(
  action: ActionType<typeof actions.addRisingStarSession>
) {
  try {
    const data: AxiosResponse<{
      data: {
        createRisingStarSession: MentorshipSession;
      };
    }> = yield call(axiosGqlRequest, {
      query: ADD_RISING_STAR_SESSION,
      variables: { session: action.payload },
    });
    yield put(
      actions.receiveAddedRisingStarSession(
        data.data.data.createRisingStarSession
      )
    );
    yield put(goBack());
  } catch (error) {
    yield put(actions.addRisingStarSessionError(error as Error));
  }
}

const ADD_RISING_STAR_SESSION = gql`
  mutation createRisingStarSession($session: InputSession!) {
    createRisingStarSession(session: $session) {
      id
      topic
      sessionDate
      description
    }
  }
`;

function* deleteMentorshipSession(
  action: ActionType<typeof actions.deleteMentorshipSession>
) {
  try {
    const data: AxiosResponse<{
      data: {
        deleteMentorshipSession: { id: number };
      };
    }> = yield call(axiosGqlRequest, {
      query: DELETE_MENTORSHIP_SESSION,
      variables: action.payload,
    });
    yield put(
      actions.receiveDeletedMentorshipSession(
        data.data.data.deleteMentorshipSession
      )
    );
  } catch (error) {
    yield put(actions.deleteMentorshipSessionError(error as Error));
  }
}

const DELETE_MENTORSHIP_SESSION = gql`
  mutation deleteMentorshipSession($id: Int!) {
    deleteMentorshipSession(id: $id) {
      id
    }
  }
`;

function* editMentorshipSession(
  action: ActionType<typeof actions.editMentorshipSession>
) {
  try {
    const data: AxiosResponse<{
      data: {
        updateMentorshipSession: MentorshipSession;
      };
    }> = yield call(axiosGqlRequest, {
      query: EDIT_MENTORSHIP_SESSION,
      variables: { session: action.payload },
    });
    yield put(
      actions.receiveEditedMentorshipSession(
        data.data.data.updateMentorshipSession
      )
    );
    const a: MentorshipProgramConnection | undefined = yield select(
      (state: RootState) => state.mentor.programInfo
    );
    if (a) yield put(push("/mentor/" + a.student.firebaseHash));
  } catch (error) {
    yield put(actions.editMentorshipSessionError(error as Error));
  }
}

const EDIT_MENTORSHIP_SESSION = gql`
  mutation updateMentorshipSession($session: InputMentorshipSession!) {
    updateMentorshipSession(session: $session) {
      id
      topic
      sessionDate
      description
      notes
    }
  }
`;

function* fetchRisingStarSession(
  action: ActionType<typeof actions.fetchRisingStarSession>
) {
  try {
    const data: AxiosResponse<{
      data: {
        queryRisingStarSession: MentorshipSession;
      };
    }> = yield call(axiosGqlRequest, {
      query: RISING_STAR_SESSION_QUERY,
      variables: {
        id: action.payload.id,
        firebaseId: action.payload.userFirebaseId,
      },
    });
    yield put(
      actions.receiveRisingStarSession(data.data.data.queryRisingStarSession)
    );
  } catch (error) {
    yield put(actions.fetchRisingStarSessionError(error as Error));
  }
}

const RISING_STAR_SESSION_QUERY = gql`
  query queryRisingStarSession($id: Int!, $firebaseId: String!) {
    queryRisingStarSession(id: $id, firebaseId: $firebaseId) {
      id
      studentRating
      sessionDate
      topic
      description
      sessionLink
      mentorRating
      description
      notes
      sessionLink
    }
  }
`;

function* editRisingStarSession(
  action: ActionType<typeof actions.editRisingStarSession>
) {
  try {
    const data: AxiosResponse<{
      data: { updateRisingStarSession: MentorshipSession };
    }> = yield call(axiosGqlRequest, {
      query: EDIT_RISING_STAR_SESSION,
      variables: {
        session: action.payload.session,
        firebaseId: action.payload.hash,
      },
    });
    yield put(
      actions.receiveEditedRisingStarSession(
        data.data.data.updateRisingStarSession
      )
    );
  } catch (error) {
    yield put(actions.editRisingStarSessionError(error as Error));
  }
}

const EDIT_RISING_STAR_SESSION = gql`
  mutation updatingRisingStarSession(
    $session: InputSession!
    $firebaseId: String!
  ) {
    updateRisingStarSession(session: $session, firebaseId: $firebaseId) {
      id
      studentRating
      sessionDate
      topic
      description
      sessionLink
      mentorRating
      description
      notes
      sessionLink
    }
  }
`;

function* watchFetchMentorshipSession() {
  yield takeEvery("FETCH_MENTORSHIP_SESSION", fetchMentorshipSession);
}

function* watchAddMentorshipSession() {
  yield takeEvery("ADD_MENTORSHIP_SESSION", addMentorshipSession);
}

function* watchDeleteMentorshipSession() {
  yield takeEvery("DELETE_MENTORSHIP_SESSION", deleteMentorshipSession);
}

function* watchEditMentorshipSession() {
  yield takeEvery("EDIT_MENTORSHIP_SESSION", editMentorshipSession);
}

function* watchAddRisingStarSession() {
  yield takeEvery("ADD_RISING_STAR_SESSION", addRisingStarSession);
}

function* watchFetchRisingStarSession() {
  yield takeEvery("FETCH_RISING_STAR_SESSION", fetchRisingStarSession);
}

function* watchEditRisingStarSession() {
  yield takeEvery("EDIT_RISING_STAR_SESSION", editRisingStarSession);
}

export default function* rootSaga() {
  yield all([
    watchFetchMentorshipSession(),
    watchAddMentorshipSession(),
    watchAddRisingStarSession(),
    watchDeleteMentorshipSession(),
    watchEditMentorshipSession(),
    watchFetchRisingStarSession(),
    watchEditRisingStarSession(),
  ]);
}

import React from "react";
import { useDispatch } from "react-redux";
import { SRD } from "srd";
import ErrorPage from "../../components/ErrorPage";
import LoadingPage from "../../components/LoadingPage";
import { InputSession, MentorshipSession } from "../../server/serverTypes";
import { useUserObj } from "../../user/redux/hooks";
import { useSessionAdmin } from "../redux/hooks";
import { actions } from "../redux/model";
import SessionPage from "../views/SessionPage";

interface IAdminSessionPageContainer {
  adminHash: string;
  sessionId: string;
}

const AdminSessionPageContainer = ({
  adminHash,
  sessionId,
}: IAdminSessionPageContainer) => {
  const dispatch = useDispatch();
  const user = useUserObj();

  const sessionRd = useSessionAdmin(Number(sessionId), adminHash);

  const updateSession = (val: {
    id: number;
    topic: string;
    description?: string;
    sessionDate: Date;
    notes?: string;
    sessionLink?: string;
    rating?: number;
  }) => {
    const sessionObj: InputSession = {
      id: val.id,
      title: val.topic,
      description: val.description ? val.description : "",
      sessionDate: val.sessionDate,
      notes: val.notes,
      sessionLink: val.sessionLink ? val.sessionLink : "",
      courseId: 1,
      studentRating: val.rating,
    };
    if (user) {
      dispatch(
        actions.editRisingStarSession({
          session: sessionObj,
          hash: adminHash,
        })
      );
    }
  };
  return SRD.match(
    {
      notAsked: () => <LoadingPage />,
      loading: () => <LoadingPage />,
      failure: (msg: Error) => <ErrorPage />,
      success: (session: MentorshipSession) => {
        return (
          <SessionPage
            session={session}
            isMentor={true}
            updateSession={updateSession}
            rating={session.studentRating}
          />
        );
      },
    },
    sessionRd
  );
};

export default AdminSessionPageContainer;

import { ActionType, action } from "typesafe-actions";
import { SRD, RD, notAsked, loading, failure, success } from "srd";
import {
  InputMentorshipSession,
  InputSession,
  MentorshipSession,
} from "../../server/serverTypes";

interface IModel {
  sessionRemoteData: RD<Error, MentorshipSession>;
}

const initialState: IModel = {
  sessionRemoteData: notAsked(),
};

export const actions = {
  fetchMentorshipSession: (p: { id: number }) =>
    action("FETCH_MENTORSHIP_SESSION", p),
  receiveMentorshipSession: (p: MentorshipSession) =>
    action("RECEIVE_MENTORSHIP_SESSION", p),
  fetchMentorshipSessionError: (p: Error) =>
    action("FETCH_MENTORSHIP_SESSION_ERROR", p),

  fetchRisingStarSession: (p: { id: number; userFirebaseId: string }) =>
    action("FETCH_RISING_STAR_SESSION", p),
  receiveRisingStarSession: (p: MentorshipSession) =>
    action("RECEIVE_RISING_STAR_SESSION", p),
  fetchRisingStarSessionError: (p: Error) =>
    action("FETCH_RISING_STAR_SESSION_ERROR", p),

  addMentorshipSession: (p: InputMentorshipSession) =>
    action("ADD_MENTORSHIP_SESSION", p),
  receiveAddedMentorshipSession: (p: MentorshipSession) =>
    action("RECEIVE_ADDED_MENTORSHIP_SESSION", p),
  addMentorshipSessionError: (p: Error) =>
    action("ADD_MENTORSHIP_SESSION_ERROR", p),

  addRisingStarSession: (p: InputSession) =>
    action("ADD_RISING_STAR_SESSION", p),
  receiveAddedRisingStarSession: (p: MentorshipSession) =>
    action("RECEIVE_ADDED_RISING_STAR_SESSION", p),
  addRisingStarSessionError: (p: Error) =>
    action("ADD_RISING_STAR_SESSION_ERROR", p),

  deleteMentorshipSession: (p: { id: number }) =>
    action("DELETE_MENTORSHIP_SESSION", p),
  receiveDeletedMentorshipSession: (p: { id: number }) =>
    action("RECEIVE_DELETED_MENTORSHIP_SESSION", p),
  deleteMentorshipSessionError: (p: Error) =>
    action("DELETE_MENTORSHIP_SESSION_ERROR", p),

  editMentorshipSession: (p: InputMentorshipSession) =>
    action("EDIT_MENTORSHIP_SESSION", p),
  receiveEditedMentorshipSession: (p: MentorshipSession) =>
    action("RECEIVE_EDITED_MENTORSHIP_SESSION", p),
  editMentorshipSessionError: (p: Error) =>
    action("EDIT_MENTORSHIP_SESSION_ERROR"),

  editRisingStarSession: (p: { session: InputSession; hash: string }) =>
    action("EDIT_RISING_STAR_SESSION", p),
  receiveEditedRisingStarSession: (p: MentorshipSession) =>
    action("RECEIVE_EDITED_RISING_STAR_SESSION", p),
  editRisingStarSessionError: (p: Error) =>
    action("EDIT_RISING_STAR_SESSION_ERROR", p),
};

type MentorshipSessionActionType = ActionType<typeof actions>;

export const reducer = (
  state: IModel = initialState,
  action: MentorshipSessionActionType
): IModel => {
  switch (action.type) {
    case "FETCH_MENTORSHIP_SESSION":
      return {
        ...state,
        sessionRemoteData: loading(),
      };

    case "RECEIVE_MENTORSHIP_SESSION":
      return {
        ...state,
        sessionRemoteData: success(action.payload),
      };

    case "FETCH_MENTORSHIP_SESSION_ERROR":
      return {
        ...state,
        sessionRemoteData: failure(action.payload),
      };

    case "ADD_MENTORSHIP_SESSION":
      return {
        ...state,
      };

    case "RECEIVE_ADDED_MENTORSHIP_SESSION":
      return {
        ...state,
      };

    case "ADD_MENTORSHIP_SESSION_ERROR":
      return {
        ...state,
      };

    case "RECEIVE_DELETED_MENTORSHIP_SESSION":
      return {
        ...state,
      };

    case "RECEIVE_ADDED_RISING_STAR_SESSION":
      return {
        ...state,
      };

    case "FETCH_RISING_STAR_SESSION":
      return {
        ...state,
        sessionRemoteData: loading(),
      };
    case "RECEIVE_RISING_STAR_SESSION":
      return {
        ...state,
        sessionRemoteData: success(action.payload),
      };

    case "FETCH_RISING_STAR_SESSION_ERROR":
      return {
        ...state,
        sessionRemoteData: failure(action.payload),
      };

    case "RECEIVE_EDITED_RISING_STAR_SESSION":
      return {
        ...state,
        sessionRemoteData: success(action.payload),
      };

    default:
      return state;
  }
};

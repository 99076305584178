import { ApolloError, useQuery } from "@apollo/client";
import React from "react";
import { getMentorshipTaskById } from "../../server/queryBuilder";
import { InputMentorshipTask, MentorshipTask } from "../../server/serverTypes";
import AssignmentPage from "../views/AssignmentPage";
import { useTask } from "../redux/hooks";
import { SRD } from "srd";
import { useUserObj } from "../../user/redux/hooks";
import { useDispatch } from "react-redux";
import { useProgramInfo } from "../../mentor/redux/hooks";
import { actions } from "../redux/model";
import ErrorPage from "../../components/ErrorPage";
import LoadingPage from "../../components/LoadingPage";

interface IMentorAssignmentPageContainer {
  id: string;
}
const MentorAssignmentPageContainer = ({
  id,
}: IMentorAssignmentPageContainer) => {
  const dispatch = useDispatch();
  const mp = useProgramInfo();

  const taskRd = useTask(Number(id));
  const user = useUserObj();

  const handleUpdate = (val: {
    id: number;
    title: string;
    description: string;
    dueDate: Date;
    resources?: string;
    notes?: string;
    assignmentLink?: string;
  }) => {
    if (mp) {
      const taskObj: InputMentorshipTask = {
        id: val.id,
        title: val.title,
        description: val.description,
        dueDate: val.dueDate,
        resources: val.resources,
        notes: val.notes,
        assignmentLink: val.assignmentLink,
        fkMentorshipId: mp.id,
      };
      dispatch(actions.editMentorshipTask(taskObj));
    }
  };

  return SRD.match(
    {
      notAsked: () => <LoadingPage />,
      loading: () => <LoadingPage />,
      failure: (msg: Error) => <ErrorPage />,
      success: (task: MentorshipTask) => {
        return (
          <AssignmentPage
            task={task}
            isMentor={true}
            updateTask={handleUpdate}
          />
        );
      },
    },
    taskRd
  );
};

export default MentorAssignmentPageContainer;

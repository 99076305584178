import React from "react";
import { Container, Typography, Grid, Tabs, Tab, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MentorComponentLarge from "../components/MentorComponentLarge";
import MenteeTaskView from "./MenteeTaskView";
import { blackmintTheme, deviceDesktopBreakpoint } from "../styles/theme";
import clsx from "clsx";
import {
  Mentor,
  MentorshipSession,
  MentorshipTask,
} from "../server/serverTypes";
import MentorComponent from "../components/MentorComponent";
import MenteeSessionView from "./views/MenteeSessionView";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "20px",
    minHeight: "calc(100vh - 204px)",
    [theme.breakpoints.up(deviceDesktopBreakpoint)]: {
      // padding: "0px 120px",
      padding: "0px 120px",
      // height: "100vh",
    },
  },
  h3: { ...blackmintTheme.typographyBlackmint.h3 },
  h5: {
    ...blackmintTheme.typographyBlackmint.h5,
  },
  tabRoot: {
    width: "50%",
    fontWeight: 400,
    color: "#757575",
  },
  indicator: {
    border: "1px solid #00664F",
  },
  selected: {
    fontFamily: "Inter",
    fontWeight: 800,
    color: "#00664F !important",
  },
}));

interface TabPanelProps {
  readonly children?: React.ReactNode;
  readonly index: number;
  readonly value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0} style={{ padding: "10px 0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface IMenteeDashboard {
  tasks: MentorshipTask[];
  sessions: MentorshipSession[];
  mentor: Mentor;
}

const MenteeDashboard = ({ tasks, sessions, mentor }: IMenteeDashboard) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Container className={classes.container}>
      {/* <MentorComponentLarge /> */}
      <MentorComponent
        withArrow={false}
        name={mentor.name}
        email={mentor.email}
      />
      <Grid container style={{ margin: "12px 0px" }}>
        <Grid item xs={12}>
          <Typography className={classes.h3}>Mentee Dashboard</Typography>
        </Grid>
        <Grid item xs={12}>
          <div style={{ position: "static" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
              classes={{ indicator: classes.indicator }}
              style={{ borderBottom: "solid grey" }}
            >
              <Tab
                label="Tasks"
                classes={{
                  root: clsx(classes.h5, classes.tabRoot),
                  selected: classes.selected,
                }}
              />
              <Tab
                label="Sessions"
                classes={{
                  root: clsx(classes.h5, classes.tabRoot),
                  selected: classes.selected,
                }}
              />
            </Tabs>
          </div>
          <TabPanel value={value} index={0}>
            <MenteeTaskView tasks={tasks} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MenteeSessionView sessions={sessions} />
          </TabPanel>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MenteeDashboard;

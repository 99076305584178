import { ActionType, action } from "typesafe-actions";
import {
  InputMentorshipTask,
  InputTask,
  Mentor,
  MentorshipProgramConnection,
  MentorshipProgramStudent,
  MentorshipTask,
  Student,
} from "../../server/serverTypes";
import { SRD, RD, notAsked, loading, failure, success } from "srd";

interface IModel {
  taskRemoteData: RD<Error, MentorshipTask>;
  addTaskRemoteData: RD<Error, MentorshipTask>;
}

const initialState: IModel = {
  taskRemoteData: notAsked(),
  addTaskRemoteData: notAsked(),
};

export const actions = {
  fetchMentorshipTask: (p: { id: number }) =>
    action("FETCH_MENTORSHIP_TASK", p),
  receiveMentorshipTask: (p: MentorshipTask) =>
    action("RECEIVE_MENTORSHIP_TASK", p),
  fetchMentorshipTaskError: (p: Error) =>
    action("FETCH_MENTORSHIP_TASK_ERROR", p),

  fetchRisingStarTask: (p: { id: number; userFirebaseId: string }) =>
    action("FETCH_RISING_STAR_TASK", p),
  receiveRisingStarTask: (p: MentorshipTask) =>
    action("RECEIVE_RISING_STAR_TASK", p),
  fetchRisingStarTaskError: (p: Error) =>
    action("FETCH_RISING_STAR_TASK_ERROR", p),

  addMentorshipTask: (p: InputMentorshipTask) =>
    action("ADD_MENTORSHIP_TASK", p),
  receiveAddedMentorshipTask: (p: MentorshipTask) =>
    action("RECEIVE_ADDED_MENTORSHIP_TASK", p),
  addMentorshipTaskError: (p: Error) => action("ADD_MENTORSHIP_TASK_ERROR", p),

  addRisingStarTask: (p: InputTask) => action("ADD_RISING_STAR_TASK", p),
  receiveAddedRisingStarTask: (p: MentorshipTask) =>
    action("RECEIVE_ADDED_RISING_STAR_TASK", p),
  addRisingStarTaskError: (p: Error) => action("ADD_RISING_STAR_TASK_ERROR", p),

  editMentorshipTask: (p: InputMentorshipTask) =>
    action("EDIT_MENTORSHIP_TASK", p),
  receiveEditedMentorshipTask: (p: MentorshipTask) =>
    action("RECEIVE_EDITED_MENTORSHIP_TASK", p),
  editMentorshipTaskError: (p: Error) =>
    action("EDIT_MENTORSHIP_TASK_ERROR", p),

  editRisingStarTask: (p: { task: InputTask; hash: string }) =>
    action("EDIT_RISING_STAR_TASK", p),
  receiveEditedRisingStarTask: (p: MentorshipTask) =>
    action("RECEIVE_EDITED_RISING_STAR_TASK", p),
  editRisingStarTaskError: (p: Error) =>
    action("EDIT_RISING_STAR_TASK_ERROR", p),

  completeTask: (p: { id: number }) => action("COMPLETE_MENTORSHIP_TASK", p),
  receiveCompletedTask: (p: MentorshipTask) =>
    action("RECEIVE_COMPLETED_MENTORSHIP_TASK", p),
  completeTaskError: (p: Error) => action("COMPLETE_MENTORSHIP_TASK_ERROR", p),

  completeRisingStarTask: (p: { id: number; studentId: number }) =>
    action("COMPLETE_RISING_STAR_TASK", p),
  receiveCompletedRisingStarTask: (p: MentorshipTask) =>
    action("RECEIVE_COMPLETED_RISING_STAR_TASK", p),
  completeRisingStarTaskError: (p: Error) =>
    action("COMPLETE_RISING_STAR_TASK_ERROR", p),

  deleteMentorshipTask: (p: { id: number }) =>
    action("DELETE_MENTORSHIP_TASK", p),
  receiveDeletedMentorshipTask: (p: { id: number }) =>
    action("RECEIVE_DELETED_MENTORSHIP_TASK", p),
  deleteMentorshipTaskError: (p: Error) =>
    action("DELETE_MENTORSHIP_TASK_ERROR", p),

  deleteRisingStarTask: (p: { id: number }) =>
    action("DELETE_RISING_STAR_TASK", p),
  receiveDeletedRisingStarTask: (p: { id: number }) =>
    action("RECEIVE_DELETED_RISING_STAR_TASK", p),
  deleteRisingStarTaskError: (p: Error) =>
    action("DELETE_RISING_STAR_TASK_ERROR", p),
};

type MentorshipTaskActionType = ActionType<typeof actions>;

export const reducer = (
  state: IModel = initialState,
  action: MentorshipTaskActionType
): IModel => {
  switch (action.type) {
    case "ADD_MENTORSHIP_TASK":
      return {
        ...state,
        addTaskRemoteData: loading(),
      };
    case "RECEIVE_ADDED_MENTORSHIP_TASK":
      return {
        ...state,
        addTaskRemoteData: success(action.payload),
      };
    case "ADD_MENTORSHIP_TASK_ERROR":
      return {
        ...state,
        addTaskRemoteData: failure(action.payload),
      };

    case "FETCH_MENTORSHIP_TASK":
      return {
        ...state,
        taskRemoteData: loading(),
      };

    case "RECEIVE_MENTORSHIP_TASK":
      return {
        ...state,
        taskRemoteData: success(action.payload),
      };

    case "FETCH_MENTORSHIP_TASK_ERROR":
      return {
        ...state,
        taskRemoteData: failure(action.payload),
      };

    case "FETCH_RISING_STAR_TASK":
      return {
        ...state,
        taskRemoteData: loading(),
      };
    case "RECEIVE_RISING_STAR_TASK":
      return {
        ...state,
        taskRemoteData: success(action.payload),
      };
    case "RECEIVE_EDITED_RISING_STAR_TASK":
      return {
        ...state,
        taskRemoteData: success(action.payload),
      };

    case "RECEIVE_ADDED_RISING_STAR_TASK":
      return {
        ...state,
        taskRemoteData: success(action.payload),
      };

    case "COMPLETE_MENTORSHIP_TASK":
      return {
        ...state,
      };
    case "RECEIVE_COMPLETED_MENTORSHIP_TASK":
      return {
        ...state,
      };
    case "COMPLETE_MENTORSHIP_TASK_ERROR":
      return {
        ...state,
      };

    default:
      return state;
  }
};

import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
// import { AuthUserContext, useAuth } from "../../../../Providers";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import VideoLibraryOutlinedIcon from "@material-ui/icons/VideoLibraryOutlined";
import QueueOutlinedIcon from "@material-ui/icons/QueueOutlined";
// import {
//   HomeIcon,
//   BookmarkIcon,
//   FavoriteIcon,
//   SettingIcon,
//   PlayIcon,
//   PersonIcon,
//   BriefcaseIcon,
//   GraduationIcon,
// } from "./NavBarIcons";

import { BlackMintLogo } from "./BlackMintWhiteIcon";
import { useHistory, useLocation } from "react-router-dom";
// import WithAuthorizationElement from "../../../Auth/WithAuthorization/WithAuthorizationElement";
// import { ROUTES } from "../../../../constants";
// import { blackmintTheme } from "../../../../style/theme";

import { blackmintTheme } from "../styles/theme";
import { useMenorMentees, useMentorInfo } from "../mentor/redux/hooks";
import { actions as userActions } from "../user/redux/model";
import { useDispatch } from "react-redux";
import { useRelatedPrograms, useUserObj } from "../user/redux/hooks";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useMenteeMentors } from "../mentee/redux/hooks";
import { UserType } from "../server/serverTypes";
import Firebase from "../common/Firebase";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    root: {
      flexGrow: 1,
    },
    navBar: {
      padding: "0 var(--padding-screen-current-side)",
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "#FFFFFF",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "1.7rem",
      height: "118px",
      width: "100%",
      zIndex: 100,
      boxShadow: "0 1px 5px 0px rgba(0, 0, 0, 0.4)",
    },
    logoBox: {
      marginLeft: "50px",
    },
    dropTrigger: {
      width: "30px",
      height: "30px",
      cursor: "pointer",
      color: "white",
      margin: "15px",
    },
    back: {
      padding: "1rem",
      width: "30px",
      height: "30px",
      color: "white",
      margin: "15px",
    },
    list: {
      width: 293,
    },
    sidebarText: {
      // ...blackmintTheme.typographyBlackmint.link,
      textDecorationLine: "underline",
    },
    sidebarHeader: {
      // ...blackmintTheme.typographyBlackmint.h3,
      color: "#4DC591",
      margin: "0.5rem 2rem",
    },
    menuIcon: {
      // fontSize: "4rem",
      "&:hover": {
        color: "#4DC591",
      },
    },
    username: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "24px",
    },
    listIcon: {
      minWidth: "44px",
      placeContent: "center",
      alignItems: "center",
    },
    btn: {
      width: "100%",
      alignItems: "center",
      height: "38px",
      background: "#4DC591",
      borderRadius: "8px",
      margin: "40px 0px",
    },
    h4: {
      // ...blackmintTheme.typographyBlackmint.h4,
    },
    accordionRoot: {
      boxShadow: "none",
    },
    accordionSummary: {
      padding: "0px",
    },
  })
);

const NavBar = () => {
  const classes = useStyles();

  const userInfo = useUserObj();
  const isMentor = userInfo?.user.type === UserType.mentor ? true : false;

  const dispatch = useDispatch();
  const history = useHistory();

  const navigateToPage = (route: string) => {
    history.push(route);
  };

  const [expanded, setExpanded] = React.useState<boolean>(false);

  const programs = useRelatedPrograms();

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.navBar}>
          <div
            className={classes.logoBox}
            onClick={() => {
              history.push("/");
            }}
          >
            <BlackMintLogo />
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar style={{ marginBottom: "4px" }} />
    </React.Fragment>
  );
};

export default NavBar;

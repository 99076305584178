import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";

import { reducer as mentorReducer } from "../../../mentor/redux/model";
import { reducer as userReducer } from "../../../user/redux/model";
import { reducer as menteeReducer } from "../../../mentee/redux/model";
import { reducer as taskReducer } from "../../../task/redux/model";
import { reducer as toasterReducer } from "../../../toaster/redux/model";
import { reducer as sessionReducer } from "../../../sessions/redux/model";
import { reducer as videosReducer } from "../../../videos/redux/model";
import { reducer as adminReducer } from "../../../admin/redux/model";

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    mentor: mentorReducer,
    user: userReducer,
    mentee: menteeReducer,
    task: taskReducer,
    toaster: toasterReducer,
    session: sessionReducer,
    videos: videosReducer,
    admin: adminReducer,
  });

export default createRootReducer;
export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;

import {
  Button,
  Container,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import MenteeComponentSlim from "../../components/MenteeComponentSlim";
import { firebaseUserDoc } from "../containers/AddMenteeContainer";
import { makeStyles } from "@material-ui/core/styles";
import { LaptopPlants } from "../../components/Icons";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useSending } from "../redux/hooks";
import { deviceDesktopBreakpoint } from "../../styles/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 204px)",
    [theme.breakpoints.up(deviceDesktopBreakpoint)]: {
      padding: "0px 240px",
    },
  },
  h3: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    color: "#00664F",
  },
}));

interface IOnboardMenteesView {
  mentees: firebaseUserDoc[];
  handlePageChange: () => void;
  handleMenteeClick: (f: firebaseUserDoc) => void;
}

const OnboardMenteesView = ({
  mentees,
  handlePageChange,
  handleMenteeClick,
}: IOnboardMenteesView) => {
  const classes = useStyles();
  const sending = useSending();

  return (
    <Container className={classes.container}>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <LaptopPlants />
      </Grid>
      <Grid container justify="center">
        <Typography className={classes.h3}>
          Please select the mentee you would like to invite
        </Typography>
      </Grid>
      {sending ? (
        <Grid container justify="center">
          <Grid item xs={12} style={{ textAlign: "center" }}>
            Sending...
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <CircularProgress
              size={100}
              style={{ color: "#00664F" }}
              color={"primary"}
            />
          </Grid>
        </Grid>
      ) : (
        <>
          {mentees.map((m) => (
            <div
              onClick={() => {
                handleMenteeClick({ id: m.id, name: m.name, email: m.email });
              }}
            >
              <MenteeComponentSlim
                name={m.name}
                email={m.email}
                handleMenteeClick={handleMenteeClick}
                id={m.id}
              />
            </div>
          ))}
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              onClick={handlePageChange}
              style={{ backgroundColor: "#4DC591" }}
            >
              <ArrowBackIcon />
              <Typography className={classes.h3} style={{ color: "black" }}>
                Go back
              </Typography>
            </Button>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default OnboardMenteesView;

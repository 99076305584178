import React from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  Fab,
  Divider,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ComeBackLater } from "../components/Icons";
import { Link } from "react-router-dom";
import MenteeComponent from "../components/MenteeComponent";
import AddIcon from "@material-ui/icons/Add";
import {
  GqlUser,
  MentorshipProgramSlim,
  MentorshipProgramStudent,
  Student,
} from "../server/serverTypes";
import { blackmintTheme, deviceDesktopBreakpoint } from "../styles/theme";
import clsx from "clsx";
import { useUserObj } from "../user/redux/hooks";
import MenteeComponentGrey from "../components/MenteeComponentGrey";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // minHeight: "calc(100vh - 204px)",
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
      [theme.breakpoints.up(deviceDesktopBreakpoint)]: {
        padding: "0px 120px",
        height: "100vh",
      },
    },
    title: {
      ...blackmintTheme.typographyBlackmint.h3,
      fontWeight: 600,
      color: "#00664F",
    },
    h3: {
      ...blackmintTheme.typographyBlackmint.h3,
      fontWeight: 600,
    },
    main: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      color: "#272727",
    },
    button: {
      background: "#4DC591",
      borderRadius: "8px",
      padding: "8px 42px",
      fontFamily: "Inter",
      fontWeight: 800,
      fontSize: "16px",
      alignItems: "center",
      color: "#272727",
    },
    addBtn: {
      // position: "absolute",
      // bottom: "175px",
      // right: theme.spacing(3),
      left: "80%",
      background: "#4DC591",
      boxShadow:
        "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)",
      color: "black",
    },
    img: {
      width: "50%",
      margin: "10px 0px",
    },
    newTitle: {
      ...blackmintTheme.typographyBlackmint.h2,
      fontWeight: 700,
    },
    h4: {
      ...blackmintTheme.typographyBlackmint.h4,
      fontWeight: 400,
    },
    subContent: {
      ...blackmintTheme.typographyBlackmint.h5,
      color: "#757575",
    },
  })
);

interface IMentorDashboard {
  students: MentorshipProgramSlim[];
}

const MentorDashboard = ({ students }: IMentorDashboard) => {
  const classes = useStyles();
  const user = useUserObj();

  return (
    <Container className={classes.container}>
      <Typography className={classes.newTitle}>
        Rising Star’s Mentorship Portal
      </Typography>
      <div
        style={{
          border: "3px solid #4DC591",
          width: "72px",
          margin: "16px 0px",
        }}
      ></div>
      <Typography className={classes.h4}>
        Welcome! Be sure to check the “Resources” link in the menu for updated
        mentorship news and guides.
      </Typography>
      <Typography className={classes.main}>You are paired with:</Typography>
      {students.length > 0 ? (
        <React.Fragment>
          {students.map((s) =>
            s.live ? (
              <Link
                to={`/mentor/${s.firebaseHash}`}
                style={{ textDecoration: "none" }}
              >
                <MenteeComponent name={s.name} email={s.email} />
              </Link>
            ) : (
              <MenteeComponentGrey name={s.name} email={s.email} />
            )
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Grid container style={{ margin: "10px 0px" }}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <ComeBackLater />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography className={classes.main}>No pairings yet</Typography>
            </Grid>
          </Grid>
          <Grid container style={{ margin: "10px 0px" }}>
            <Typography className={classes.main}>
              Start the mentorship by sending them an invite!
            </Typography>
          </Grid>
          <Grid container style={{ placeContent: "center" }}>
            <Grid item>
              <Link to={"/mentor-onboard"} style={{ textDecoration: "none" }}>
                <Button className={classes.button}>Add a mentee</Button>
              </Link>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      <Divider style={{ margin: "40px 0px" }} />
      <Grid container style={{ margin: "0px" }}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography className={classes.main} style={{ color: "#00664F" }}>
            Home of the Rising Star Program
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography
            className={classes.main}
            style={{ color: "#272727", margin: "16px 0px" }}
          >
            As the hub to manage BlackMINT’s ongoing mentorships and supporting
            the Rising Star Program, Rising Star’s Mentorship Portal is an
            important communications tool in the mentorship journey.
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography
            className={classes.subContent}
            style={{ color: "#272727" }}
          >
            Tuition free program powered by:
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <img
            src={process.env.PUBLIC_URL + "/blackmint_logo.png"}
            className={classes.img}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <img
            src={process.env.PUBLIC_URL + "/atg-removebg.png"}
            className={classes.img}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <img
            src={process.env.PUBLIC_URL + "/rbc-launch-removebg.png"}
            className={classes.img}
          />
        </Grid>
      </Grid>
      {students.length > 0 ? (
        <Link to={"/mentor-onboard"}>
          <Fab color="primary" aria-label="add" className={classes.addBtn}>
            <AddIcon />
          </Fab>
        </Link>
      ) : null}
    </Container>
  );
};

export default MentorDashboard;

import firebase from "firebase";
import { all, put, call, takeEvery } from "redux-saga/effects";
import { findUserV2, loginFunc, loginWithGoogleFunc } from "../utils";
import { ActionType } from "typesafe-actions";
import { actions } from "./model";
import { AxiosResponse } from "axios";
import { axiosGqlRequest } from "../../server/common";
import {
  GqlUser,
  MentorshipProgramSlim,
  Student,
  UserType,
} from "../../server/serverTypes";
import gql from "graphql-tag";
import { push } from "connected-react-router";

export interface UserFirebaseData {
  // firebaseAuth?: firebase.auth.UserCredential;
  user: GqlUser;
}

function* loginSaga(action: ActionType<typeof actions.verifyUserCredential>) {
  try {
    const data: {
      firebaseAuth: firebase.auth.UserCredential;
      user?: {
        name: string;
        id: string;
        email: string;
        isMentor: boolean;
      };
      admin: boolean;
    } = yield call(loginFunc, action.payload);
    if (!data.user) {
      yield put(actions.verifyUserCredentialFailure("User not found"));
    }

    // if (data.user) {
    //   const gqlRes: AxiosResponse<{
    //     data: {
    //       queryUser: GqlUser;
    //     };
    //   }> = yield call(axiosGqlRequest, {
    //     query: USER_QUERY,
    //     variables: {
    //       user: {
    //         name: data.user.name,
    //         firebaseId: data.user.id,
    //         email: data.user.email,
    //         type: data.admin
    //           ? UserType.admin
    //           : data.user.isMentor
    //           ? UserType.mentor
    //           : UserType.student,
    //       },
    //     },
    //   });
    //   yield put(
    //     actions.verifyUserCredentialSuccess({
    //       // firebaseAuth: data.firebaseAuth,
    //       user: gqlRes.data.data.queryUser,
    //     })
    //   );
    // } else {
    //   yield put(actions.verifyUserCredentialFailure("User not found"));
    // }
  } catch (error) {
    yield put(actions.verifyUserCredentialFailure(error));
  }
}

function* loginWithGoogleSaga() {
  try {
    const data: {
      firebaseAuth: firebase.auth.UserCredential;
      user?: {
        name: string;
        id: string;
        email: string;
        isMentor: boolean;
      };
      admin: boolean;
    } = yield call(loginWithGoogleFunc);
    if (!data.user) {
      yield put(actions.verifyUserCredentialFailure("User not found"));
    }

    // if (data.user) {
    //   const gqlRes: AxiosResponse<{
    //     data: {
    //       queryUser: GqlUser;
    //     };
    //   }> = yield call(axiosGqlRequest, {
    //     query: USER_QUERY,
    //     variables: {
    //       user: {
    //         name: data.user.name,
    //         firebaseId: data.user.id,
    //         email: data.user.email,
    //         type: data.admin
    //           ? "admin"
    //           : data.user.isMentor
    //           ? "mentor"
    //           : "student",
    //       },
    //     },
    //   });
    //   yield put(
    //     actions.verifyUserCredentialWithGoogleSuccess({
    //       // firebaseAuth: data.firebaseAuth,
    //       user: gqlRes.data.data.queryUser,
    //     })
    //   );
    // } else {
    //   yield put(
    //     actions.verifyUserCredentialWithGoogleFailure("User not found")
    //   );
    // }
  } catch (error) {
    yield put(actions.verifyUserCredentialWithGoogleFailure(error));
  }
}

const STUDENT_QUERY = gql`
  query queryStudent($user: FirebaseUser!) {
    queryStudent(user: $user) {
      id
      name
      email
      firbaseId
      isMentor
    }
  }
`;

const MENTOR_QUERY = gql`
  query queryMentor($user: FirebaseUser!) {
    queryMentor(user: $user) {
      id
      name
      email
      firbaseId
      isMentor
    }
  }
`;

const USER_QUERY = gql`
  query queryUser($user: InputUser!) {
    queryUser(user: $user) {
      id
      name
      email
      firebaseHash
      type
    }
  }
`;

function* watchLoginUser() {
  yield takeEvery("VERIFY_USER_CREDS", loginSaga);
}

function* watchLoginUserWithGoogle() {
  yield takeEvery("VERIFY_USER_CREDS_WITH_GOOGLE", loginWithGoogleSaga);
}

function* signOut() {
  yield put(push("/"));
}

function* watchSignOut() {
  yield takeEvery("SIGN_OUT", signOut);
}

function* fetchUserPrograms(
  action: ActionType<typeof actions.fetchUserPrograms>
) {
  try {
    const data: AxiosResponse<{
      data: {
        queryUserPrograms: MentorshipProgramSlim[];
      };
    }> = yield call(axiosGqlRequest, {
      query: MENTORSHIP_USERS_QUERY,
      variables: action.payload,
    });
    yield put(actions.receiveUserPrograms(data.data.data.queryUserPrograms));
  } catch (error) {
    yield put(actions.fetchMentorStudentsError(error as Error));
  }
}

const MENTORSHIP_USERS_QUERY = gql`
  query queryUserPrograms($id: Int!) {
    queryUserPrograms(id: $id) {
      id
      name
      email
      firebaseHash
      live
      type
    }
  }
`;

function* watchFetchUserPrograms() {
  yield takeEvery("FETCH_USER_PROGRAMS", fetchUserPrograms);
}

function* verifyToken(action: ActionType<typeof actions.verifyToken>) {
  try {
    const data:
      | {
          name: string;
          firebaseId: string;
          email: string;
          type: UserType;
        }
      | undefined = yield call(findUserV2, action.payload);

    if (data) {
      const gqlRes: AxiosResponse<{
        data: {
          queryUser: GqlUser;
        };
      }> = yield call(axiosGqlRequest, {
        query: USER_QUERY,
        variables: {
          user: {
            name: data.name,
            firebaseId: data.firebaseId,
            email: data.email,
            type: data.type,
          },
        },
      });
      yield put(
        actions.verifyUserCredentialSuccess({
          user: gqlRes.data.data.queryUser,
        })
      );
    } else {
      throw new Error("error");
    }
  } catch (e) {
    yield put(actions.verifyUserCredentialFailure("User not found"));
  }
}

function* watchVerifyToken() {
  yield takeEvery("VERIFY_TOKEN", verifyToken);
}

export default function* rootSaga() {
  yield all([
    watchLoginUser(),
    watchLoginUserWithGoogle(),
    watchSignOut(),
    watchFetchUserPrograms(),
    watchVerifyToken(),
  ]);
}

import React, { useState } from "react";
import { Container, Typography, Grid, Tabs, Tab, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenteeComponent from "../../components/MenteeComponent";
import { blackmintTheme, deviceDesktopBreakpoint } from "../../styles/theme";
import clsx from "clsx";
import MentorTaskView from "../../mentor/views/MentorTaskView";
import MentorSessionView from "../../mentor/views/MentorSessionView";
import {
  GqlUser,
  MentorshipSession,
  MentorshipTask,
  RisingStarSession,
  Student,
} from "../../server/serverTypes";
import RisingStarMentorSessionView from "../../mentor/views/RisingStarMentorSessionView";
import { actions as taskActions } from "../../task/redux/model";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "20px",
    minHeight: "calc(100vh - 204px)",
    [theme.breakpoints.up(deviceDesktopBreakpoint)]: {
      padding: "0px 120px",
    },
  },
  h3: { ...blackmintTheme.typographyBlackmint.h3 },
  h5: {
    ...blackmintTheme.typographyBlackmint.h5,
  },
  tabRoot: {
    width: "50%",
    fontWeight: 400,
    color: "#757575",
  },
  indicator: {
    border: "1px solid #00664F",
  },
  selected: {
    fontFamily: "Inter",
    fontWeight: 800,
    color: "#00664F !important",
  },
}));

interface TabPanelProps {
  readonly children?: React.ReactNode;
  readonly index: number;
  readonly value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0} style={{ padding: "10px 0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface IAdminDashboard {
  tasks: MentorshipTask[];
  sessions: RisingStarSession[];
  student: GqlUser;
  courseId: number;
}

const AdminDashboard = ({
  tasks,
  sessions,
  student,
  courseId,
}: IAdminDashboard) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
  };

  const handleDelete = (id: number) => {
    dispatch(taskActions.deleteRisingStarTask({ id: id }));
  };

  const handleUpdate = (id: number) => {
    dispatch(
      taskActions.completeRisingStarTask({
        id: id,
        studentId: student.id,
      })
    );
  };

  return (
    <Container className={classes.container}>
      <MenteeComponent
        withArrow={false}
        name={student.name}
        email={student.email}
      />
      <Grid container style={{ margin: "12px 0px" }}>
        <Grid item xs={12}>
          <Typography className={classes.h3}>Admin Dashboard</Typography>
        </Grid>
        <Grid item xs={12}>
          <div style={{ position: "static" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
              classes={{ indicator: classes.indicator }}
            >
              <Tab
                label="Tasks"
                classes={{
                  root: clsx(classes.h5, classes.tabRoot),
                  selected: classes.selected,
                }}
              />
              <Tab
                label="Sessions"
                classes={{
                  root: clsx(classes.h5, classes.tabRoot),
                  selected: classes.selected,
                }}
              />
            </Tabs>
          </div>
          <TabPanel value={value} index={0}>
            <MentorTaskView
              tasks={tasks}
              addUrl={`/${courseId}/add-task`}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* <MentorSessionView
              sessions={[]}
              addUrl={`/${courseId}/add-session`}
            /> */}
            <RisingStarMentorSessionView
              sessions={sessions}
              addUrl={`/${courseId}/add-session`}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminDashboard;

import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import { useDispatch } from "react-redux";
import { useMentorshipProgram } from "../../mentor/redux/hooks";
import { InputMentorshipSession } from "../../server/serverTypes";
import { actions } from "../redux/model";
import AddSession from "../views/AddSession";

const AddSessionContainer = () => {
  const dispatch = useDispatch();
  const [name, setName] = React.useState<string>("");
  const [date, setDate] = React.useState<Date>(new Date());
  const [time, setTime] = React.useState("13:30");
  const mp = useMentorshipProgram();

  const onSubmit = () => {
    if (mp.tag === "Success") {
      const createDate = new Date(date.toDateString() + " " + time);
      const sessionObj: InputMentorshipSession = {
        topic: name,
        sessionDate: createDate,
        fkMentorshipId: mp.data.id,
      };
      dispatch(actions.addMentorshipSession(sessionObj));
    }
  };

  const handleDateChange = (dateParam: Date) => {
    if (dateParam !== null) setDate(dateParam);
  };

  const handleNameChange = (val: string) => {
    setName(val);
  };

  const handleTimeChange = (val: string) => {
    setTime(val);
  };

  return (
    <AddSession
      name={name}
      date={date}
      time={time}
      handleNameChange={handleNameChange}
      handleDateChange={handleDateChange}
      handleTimeChange={handleTimeChange}
      addSampleSession={onSubmit}
    />
  );
};

export default AddSessionContainer;

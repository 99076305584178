import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SRD } from "srd";
import { RootState } from "../../common/redux/reducers";
import { MentorshipTask } from "../../server/serverTypes";
import { actions } from "./model";

export const useTask = (id: number) => {
  const history = useHistory();
  const rd = useSelector((state: RootState) => state.task.taskRemoteData);
  const userRemoteData = useSelector(
    (state: RootState) => state.user.userRemoteData
  );
  const mentorshipProgramRemoteData = useSelector(
    (state: RootState) => state.mentor.mentorProgramRD
  );

  const dispatch = useDispatch();

  SRD.match(
    {
      notAsked: () => {
        if (SRD.isSuccess(userRemoteData)) {
          dispatch(actions.fetchMentorshipTask({ id: id }));
        } else {
          history.push("/");
        }
      },
      loading: () => {
        //
      },
      failure: (msg: Error) => {
        //
      },
      success: (mentorshipTask: MentorshipTask) => {
        if (SRD.isSuccess(userRemoteData)) {
          if (id !== mentorshipTask.id) {
            dispatch(actions.fetchMentorshipTask({ id: id }));
          }
        } else {
          history.push("/");
        }
      },
    },
    rd
  );

  return rd;
};

export const useTaskAdmin = (id: number, firebaseId: string) => {
  const rd = useSelector((state: RootState) => state.task.taskRemoteData);
  const dispatch = useDispatch();
  const history = useHistory();

  const userRemoteData = useSelector(
    (state: RootState) => state.user.userRemoteData
  );

  SRD.match(
    {
      notAsked: () => {
        if (SRD.isSuccess(userRemoteData)) {
          dispatch(
            actions.fetchRisingStarTask({ id: id, userFirebaseId: firebaseId })
          );
        } else {
          history.push("/");
        }
      },
      loading: () => {
        //
      },
      failure: (msg: Error) => {
        //
      },
      success: (mentorshipTask: MentorshipTask) => {
        if (SRD.isSuccess(userRemoteData)) {
          if (id !== mentorshipTask.id) {
            dispatch(
              actions.fetchRisingStarTask({
                id: id,
                userFirebaseId: firebaseId,
              })
            );
          }
        } else {
          history.push("/");
        }
      },
    },
    rd
  );

  return rd;
};

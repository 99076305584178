import React, { memo } from "react";
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemIcon,
} from "@material-ui/core";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import { makeStyles } from "@material-ui/core/styles";
import { MeetingIcon, DeleteIcon as CustomDeleteIcon, MenuDot } from "./Icons";
import { Link, useRouteMatch } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { MentorshipSession } from "../server/serverTypes";
import { useDispatch } from "react-redux";
import { actions as sessionActions } from "../sessions/redux/model";
import { stripTime } from "../user/utils";
const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 370,
    height: "100px",
    background: "#FCFAF5",
    borderRadius: "5px",
    margin: "10px 0px",
    filter: "drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.25))",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  cardAction: {
    margin: 0,
  },
  cardHeaderTitle: {
    height: "100%",
  },
  cardHeaderContent: {
    height: "56.5px",
  },
  main: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    color: "#272727",
  },
  tinyText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#757575",
  },
  menuBtn: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  subContent: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

interface ISessionComponent {
  session: MentorshipSession;
  withArrow?: boolean;
  completed?: boolean;
  isMentee?: boolean;
}

const SessionComponent = ({
  withArrow = true,
  session,
  completed = false,
  isMentee = false,
}: ISessionComponent) => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleClose = (event: any, action: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);

    if (action === "delete") {
      handleDelete();
    }
  };

  const handleDelete = () => {
    dispatch(sessionActions.deleteMentorshipSession({ id: session.id }));
  };

  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Card className={classes.root}>
      <CardHeader
        classes={{
          action: classes.cardAction,
          title: classes.cardHeaderTitle,
          content: classes.cardHeaderContent,
        }}
        style={{ padding: "8px 16px", height: "100%" }}
        avatar={<MeetingIcon completed={completed} />}
        action={
          <React.Fragment>
            <div style={{ textAlign: "right" }}>
              {isMentee === true ? (
                <CustomDeleteIcon />
              ) : (
                <>
                  <IconButton
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    classes={{ root: classes.menuBtn }}
                  >
                    <MenuDot />
                  </IconButton>
                  <Popper open={open} anchorEl={anchorRef.current} transition>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClickAway}>
                            <MenuList
                              autoFocusItem={open}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              <Link
                                to={`${url}/edit-session/${session.id}`}
                                style={{ textDecoration: "none" }}
                              >
                                <MenuItem
                                  onClick={(e) => handleClose(e, "edit")}
                                >
                                  <ListItemIcon style={{ minWidth: "30px" }}>
                                    <EditIcon fontSize="small" />
                                  </ListItemIcon>
                                  <Typography className={classes.subContent}>
                                    Edit
                                  </Typography>
                                </MenuItem>
                              </Link>
                              <MenuItem
                                onClick={(e) => handleClose(e, "delete")}
                              >
                                <ListItemIcon style={{ minWidth: "30px" }}>
                                  <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography className={classes.subContent}>
                                  Delete
                                </Typography>
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              )}
            </div>
            {withArrow ? (
              <Link to={`${url}/session/${session.id}`}>
                <IconButton aria-label="settings" style={{ padding: 0 }}>
                  <ChevronRightRoundedIcon fontSize="large" />
                </IconButton>
              </Link>
            ) : null}
          </React.Fragment>
        }
        title={
          <React.Fragment>
            <Grid
              container
              alignItems="center"
              spacing={1}
              style={{ height: "100%" }}
            >
              <Grid container>
                <Typography className={classes.main}>
                  {session.topic}
                </Typography>
              </Grid>
              <Grid container alignContent="center">
                {completed ? (
                  <Grid item>
                    <CheckCircleIcon
                      style={{ width: 13.33, height: 13.33, fill: "#4DC591" }}
                    />
                  </Grid>
                ) : null}
                <Grid item>
                  <Typography className={classes.tinyText}>
                    Date: {stripTime(session.sessionDate)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        }
      />
    </Card>
  );
};

export default memo(SessionComponent);

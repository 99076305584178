import React from "react";
import { useDispatch } from "react-redux";
import { actions } from "../redux/model";
import AddVideo from "../views/AddVideo";

const AddVideoContainer = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = React.useState("");
  const [videoLink, setVideoLink] = React.useState("");
  const [description, setDescription] = React.useState("");

  const handleTitleChange = (val: string) => {
    setTitle(val);
  };

  const handleVideoLinkChange = (val: string) => {
    setVideoLink(val);
  };

  const handleDescriptionChange = (val: string) => {
    setDescription(val);
  };

  const addVideo = () => {
    dispatch(
      actions.addVideo({
        title: title,
        description: description,
        link: videoLink,
      })
    );
  };

  return (
    <AddVideo
      title={title}
      videoLink={videoLink}
      description={description}
      addVideo={addVideo}
      handleTitleChange={handleTitleChange}
      handleVideoLinkChange={handleVideoLinkChange}
      handleDescriptionChange={handleDescriptionChange}
    />
  );
};

export default AddVideoContainer;

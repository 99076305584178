export interface MentorshipSession {
  id: number;
  sessionDate: Date;
  topic: string;
  resources: string;
  studentRating?: number;
  mentorRating: number;
  description?: string;
  notes?: string;
  sessionLink: string;
}

export interface InputMentorshipSession {
  id?: number;
  sessionDate: Date;
  topic: string;
  resources?: string;
  notes?: string;
  sessionLink?: string;
  studentRating?: number;
  mentorRating?: number;
  fkMentorshipId: number;
  description?: string;
}

export interface MentorshipTask {
  id: number;
  title: string;
  description: string;
  dueDate: Date;
  resources?: string;
  notes?: string;
  assignmentLink?: string;
  completed: boolean;
}

export interface InputMentorshipTask {
  id?: number;
  title: string;
  description: string;
  dueDate: Date;
  resources?: string;
  notes?: string;
  assignmentLink?: string;
  completed?: boolean;
  fkMentorshipId: number;
}

export interface Student {
  id: number;
  name: string;
  email: string;
  firebaseHash: string;
}

export interface Mentor {
  id: number;
  name: string;
  email: string;
  firebaseHash: string;
}

export interface MentorshipProgramConnection {
  sessions: MentorshipSession[];
  tasks: MentorshipTask[];
  student: Student;
  mentor: Mentor;
  id: number;
}

export interface GqlUser {
  id: number;
  name: string;
  firebaseHash: string;
  email: string;
  type: UserType;
}

export interface StudentPrograms {
  student: Student;
  mentors: Mentor[];
}

export interface MentorshipProgramStudent {
  student: Student;
  isLive: boolean;
}

export enum UserType {
  student = "student",
  mentor = "mentor",
  admin = "admin",
}

export interface MentorshipProgramSlim {
  live: boolean;
  id: number;
  name: string;
  firebaseHash: string;
  email: string;
  type: UserType;
}

export interface RisingStarVideo {
  id: number;
  title: string;
  description: string;
  link: string;
}

export interface InputRisingStarVideo {
  title: string;
  description: string;
  link: string;
}

export interface RisingStarSession {
  id: number;
  topic: string;
  description: string;
  sessionLink: string;
  sessionDate: Date;
}

export interface RisingStarProgram {
  id: number;
  tasks: MentorshipTask[];
  sessions: RisingStarSession[];
  admin: GqlUser;
  student: GqlUser;
  isLive: boolean;
  courseId: number;
}

export interface InputTask {
  id?: number;
  title: string;
  description: string;
  dueDate: Date;
  resources?: string;
  notes?: string;
  assignmentLink?: string;
  completed?: boolean;
  courseId: number;
}

export interface InputSession {
  id?: number;
  title: string;
  description: string;
  sessionLink: string;
  sessionDate: Date;
  courseId: number;
  notes?: string;
  studentRating?: number;
  mentorRating?: number;
}

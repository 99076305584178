import React from "react";
import { useRisingStarsProgramRDForStudent } from "../redux/hooks";
import RisingStarDashboard from "../views/RisingStarDashboard";
import { SRD } from "srd";
import { RisingStarProgram } from "../../server/serverTypes";
import LoadingPage from "../../components/LoadingPage";
import ErrorPage from "../../components/ErrorPage";

interface IRisingStarDashboardContainer {
  id: string;
}

const RisingStarDashboardContainer = ({
  id,
}: IRisingStarDashboardContainer) => {
  const rd = useRisingStarsProgramRDForStudent(id, true);
  return SRD.match(
    {
      notAsked: () => <LoadingPage />,
      loading: () => <LoadingPage />,
      failure: (msg: Error) => <ErrorPage />,
      success: (risingStarProgram: RisingStarProgram) => {
        return (
          <RisingStarDashboard
            tasks={risingStarProgram.tasks}
            sessions={risingStarProgram.sessions}
            admin={risingStarProgram.admin}
          />
        );
      },
    },
    rd
  );
};

export default RisingStarDashboardContainer;

import { ApolloError } from "apollo-boost";
import { ActionType, action } from "typesafe-actions";
import {
  GqlUser,
  Mentor,
  MentorshipProgramConnection,
  Student,
  StudentPrograms,
} from "../../server/serverTypes";
import { SRD, RD, notAsked, loading, failure, success } from "srd";
import { AxiosError } from "axios";
import { actions as taskActions } from "../../task/redux/model";
import { actions as sessionActions } from "../../sessions/redux/model";
import { actions as userActions } from "../../user/redux/model";

interface IModel {
  menteePrograms: RD<AxiosError, GqlUser[]>;
  menteeProgramRD: RD<AxiosError, MentorshipProgramConnection>;
  menteeMentors: GqlUser[];
  menteeProgramInfo: MentorshipProgramConnection | undefined; // do better later
}

const initialState: IModel = {
  menteePrograms: notAsked(),
  menteeProgramRD: notAsked(),
  menteeMentors: [],
  menteeProgramInfo: undefined,
};

export const actions = {
  fetchMenteePrograms: (p: { id: number }) =>
    action("FETCH_MENTEE_PROGRAMS", p),
  receiveMenteePrograms: (p: GqlUser[]) => action("RECEIVE_MENTEE_PROGRAMS", p),
  fetchMenteeProgramsError: (p: AxiosError) =>
    action("FETCH_MENTEE_PROGRAMS_ERROR", p),

  fetchMentorshipProgramMentee: (p: { id: number; firebaseHash: string }) =>
    action("FETCH_MENTEE_PROGRAM", p),
  receiveMentorshipProgramMentee: (p: MentorshipProgramConnection) =>
    action("RECEIVE_MENTEE_PROGRAM", p),
  fetchMentorshipProgramMenteeError: (p: AxiosError) =>
    action("FETCH_MENTEE_PROGRAM_ERROR", p),
};

type MenteeActionType =
  | ActionType<typeof actions>
  | ActionType<typeof taskActions>
  | ActionType<typeof sessionActions>
  | ActionType<typeof userActions>;

export const reducer = (
  state: IModel = initialState,
  action: MenteeActionType
): IModel => {
  switch (action.type) {
    case "SIGN_OUT":
      return {
        ...state,
        menteeProgramInfo: undefined,
        menteeProgramRD: notAsked(),
      };
    case "FETCH_MENTEE_PROGRAMS":
      return {
        ...state,
        menteePrograms: loading(),
      };
    case "RECEIVE_MENTEE_PROGRAMS":
      return {
        ...state,
        menteePrograms: success(action.payload),
        menteeMentors: action.payload,
      };
    case "FETCH_MENTEE_PROGRAMS_ERROR":
      return {
        ...state,
        menteePrograms: failure(action.payload),
      };

    case "FETCH_MENTEE_PROGRAM":
      return {
        ...state,
        menteeProgramRD: loading(),
      };
    case "RECEIVE_MENTEE_PROGRAM":
      return {
        ...state,
        menteeProgramRD: success(action.payload),
        menteeProgramInfo: action.payload,
      };
    case "FETCH_MENTEE_PROGRAM_ERROR":
      return {
        ...state,
        menteeProgramRD: failure(action.payload),
      };

    case "RECEIVE_DELETED_MENTORSHIP_TASK":
      return {
        ...state,
        menteeProgramRD: SRD.match(
          {
            notAsked: () => {
              return state.menteeProgramRD;
            },
            loading: () => {
              return state.menteeProgramRD;
            },
            failure: (msg: AxiosError) => {
              return state.menteeProgramRD;
            },
            success: (mentorshipProgram: MentorshipProgramConnection) => {
              return success({
                ...mentorshipProgram,
                tasks: mentorshipProgram.tasks.filter(
                  (s) => s.id !== action.payload.id
                ),
              });
            },
          },
          state.menteeProgramRD
        ),
      };

    default:
      return state;
  }
};

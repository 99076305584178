import React from "react";
import { Typography, Grid, Tabs, Tab, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { blackmintTheme } from "../styles/theme";
import clsx from "clsx";
import { MentorshipTask } from "../server/serverTypes";
import PendingTaskComponent from "../components/PendingTaskComponent";
import { CompletedTaskComponent } from "../components/CompletedTaskComponent";
import { ordinal } from "../common/utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  h5: {
    ...blackmintTheme.typographyBlackmint.h5,
  },
  tabRoot: {
    width: "50%",
    fontWeight: 400,
    color: "#757575",
  },
  indicator: {
    border: "1px solid #272727",
  },
  selected: {
    fontFamily: "Inter",
    fontWeight: 800,
    color: "#272727 !important",
  },
  yellowBox: {
    background: "#FCFAF5",
    borderRadius: 20,
    height: 150,
    // width: 200,
    // margin: "0px 10px",
    textAlign: "center",
    // padding: "20px 0px",
  },
  boxHeader: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "16px",
    color: "#272727",
  },
  boxNumber: {
    color: "#4DC591",
    fontSize: "45px",
    fontWeight: "bold",
    fontFamily: "Inter",
    lineHeight: "63px",
  },
  boxNumberDate: {
    color: "#4DC591",
    fontSize: "30px",
    fontWeight: "bold",
    fontFamily: "Inter",
    lineHeight: "42px",
  },
  boxSubHeader: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    color: "rgba(39, 39, 39, 0.8)",
  },
}));

interface TabPanelProps {
  readonly children?: React.ReactNode;
  readonly index: number;
  readonly value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && (
        <Box p={0} style={{ width: "100%" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface IMenteeTaskView {
  tasks: MentorshipTask[];
}

const MenteeTaskView = ({ tasks }: IMenteeTaskView) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
  };
  const incompletedTasks = tasks.filter((t) => t.completed === false);
  const completedTasks = tasks.filter((t) => t.completed === true);

  const latestIncDate = new Date(
    incompletedTasks[0] ? incompletedTasks[0].dueDate : ""
  );
  const monthName = latestIncDate.toLocaleDateString(undefined, {
    month: "long",
  });
  const dayName = latestIncDate.toLocaleDateString(undefined, {
    weekday: "short",
  });
  const date = latestIncDate.getDate();

  return (
    <React.Fragment>
      <Grid container>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ margin: "5px 0px" }}
        >
          <Grid item>Tasks</Grid>
        </Grid>
        <Grid container justify="space-between">
          <Grid item xs={5}>
            <Grid
              container
              className={classes.yellowBox}
              alignContent="space-around"
            >
              <Grid item xs={12}>
                <Typography className={classes.boxHeader}>To Dos</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.boxNumber}>
                  {incompletedTasks.length}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.boxSubHeader}>Tasks</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid
              container
              className={classes.yellowBox}
              alignContent="space-around"
            >
              {incompletedTasks.length > 0 ? (
                <>
                  <Grid item xs={12}>
                    <Typography className={classes.boxHeader}>
                      Upcoming Due Date
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.boxNumberDate}>
                      {`${dayName}. ${ordinal(date)}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.boxSubHeader}>
                      {monthName}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Typography className={classes.boxHeader}>
                      Upcoming Due Date
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.boxNumberDate}>
                      N/A
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.boxSubHeader}>
                      N/A
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <div style={{ width: "100%", position: "static" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
              classes={{ indicator: classes.indicator }}
              style={{ width: "100%" }}
            >
              <Tab
                label="To Do"
                classes={{
                  root: clsx(classes.h5, classes.tabRoot),
                  selected: classes.selected,
                }}
              />
              <Tab
                label="Completed"
                classes={{
                  root: clsx(classes.h5, classes.tabRoot),
                  selected: classes.selected,
                }}
              />
            </Tabs>
          </div>
          <TabPanel value={value} index={0}>
            {incompletedTasks.map((t) => {
              return (
                <PendingTaskComponent
                  task={t}
                  isMentee={true}
                  handleDelete={(id: number) => {}}
                  handleUpdate={(id: number) => {}}
                />
              );
            })}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {completedTasks.map((t) => {
              return (
                <CompletedTaskComponent
                  id={t.id}
                  taskName={t.title}
                  isMentee={true}
                />
              );
            })}
          </TabPanel>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default MenteeTaskView;

import { ActionType, action } from "typesafe-actions";
// import Cookies from "universal-cookie";
import firebase from "firebase";
import { UserFirebaseData } from "./sagas";
import { failure, loading, notAsked, RD, SRD, success } from "srd";
import { MentorshipProgramSlim } from "../../server/serverTypes";
import { actions as mentorActions } from "../../mentor/redux/model";
import { actions as adminActions } from "../../admin/redux/model";
import { AxiosError } from "axios";

export interface CredentialsInput {
  email: string;
  password: string;
}

interface IModel {
  verifiedUser: boolean;
  userInfo: UserFirebaseData | undefined;
  userRemoteData: RD<null, UserFirebaseData>;
  authenticating: boolean;
  userProgramsRemoteData: RD<Error, MentorshipProgramSlim[]>;
}

const initialState: IModel = {
  verifiedUser: false,
  userInfo: undefined,
  authenticating: false,
  userRemoteData: notAsked(),
  userProgramsRemoteData: notAsked(),
};

export const actions = {
  // User Verification
  verifyUserCredential: (p: CredentialsInput) => action("VERIFY_USER_CREDS", p),
  verifyUserCredentialSuccess: (p: UserFirebaseData) =>
    action("VERIFY_USER_CREDS_SUCCESS", p),
  verifyUserCredentialFailure: (p: any) => action("VERIFY_USER_CREDS_FAILURE"),

  verifyUserCredentialWithGoogle: () => action("VERIFY_USER_CREDS_WITH_GOOGLE"),
  verifyUserCredentialWithGoogleSuccess: (p: UserFirebaseData) =>
    action("VERIFY_USER_CREDS_SUCCESS_WITH_GOOGLE", p),
  verifyUserCredentialWithGoogleFailure: (p: any) =>
    action("VERIFY_USER_CREDS_FAILURE_WITH_GOOGLE"),

  verifyToken: (p: string) => action("VERIFY_TOKEN", p),

  signOut: () => action("SIGN_OUT"),

  fetchUserPrograms: (p: { id: number }) => action("FETCH_USER_PROGRAMS", p),
  receiveUserPrograms: (p: MentorshipProgramSlim[]) =>
    action("RECEIVE_USER_PROGRAMS", p),
  fetchMentorStudentsError: (p: Error) =>
    action("FETCH_USER_PROGRAMS_ERROR", p),
};

export type UserActionType =
  | ActionType<typeof actions>
  | ActionType<typeof mentorActions>
  | ActionType<typeof adminActions>;

export const reducer = (
  state: IModel = initialState,
  action: UserActionType
): IModel => {
  switch (action.type) {
    case "VERIFY_USER_CREDS":
      return {
        ...state,
        authenticating: true,
        userRemoteData: loading(),
      };

    case "VERIFY_USER_CREDS_SUCCESS":
      return {
        ...state,
        userInfo: action.payload,
        authenticating: false,
        verifiedUser: true,
        userRemoteData: success(action.payload),
      };

    case "VERIFY_USER_CREDS_FAILURE":
      return {
        ...state,
        authenticating: false,
        verifiedUser: false,
      };

    case "VERIFY_USER_CREDS_WITH_GOOGLE":
      return {
        ...state,
        authenticating: true,
        userRemoteData: loading(),
      };

    case "VERIFY_USER_CREDS_SUCCESS_WITH_GOOGLE":
      return {
        ...state,
        userInfo: action.payload,
        authenticating: false,
        verifiedUser: true,
        userRemoteData: success(action.payload),
      };

    case "VERIFY_USER_CREDS_FAILURE_WITH_GOOGLE":
      return {
        ...state,
        authenticating: false,
      };

    case "SIGN_OUT":
      return {
        ...initialState,
      };

    case "FETCH_USER_PROGRAMS":
      return {
        ...state,
      };

    case "RECEIVE_USER_PROGRAMS":
      return {
        ...state,
        userProgramsRemoteData: success(action.payload),
      };

    case "RECEIVE_CREATED_MENTORSHIP_PROGRAM":
      return {
        ...state,
        userProgramsRemoteData: SRD.match(
          {
            notAsked: () => {
              return state.userProgramsRemoteData;
            },
            loading: () => {
              return state.userProgramsRemoteData;
            },
            failure: (msg: Error) => {
              return state.userProgramsRemoteData;
            },
            success: (users: MentorshipProgramSlim[]) => {
              // id, name, hash, email, type
              return success([...users, { ...action.payload, live: false }]);
            },
          },
          state.userProgramsRemoteData
        ),
      };

    case "RECEIVE_CREATED_RISING_STAR_PROGRAM":
      return {
        ...state,
        userProgramsRemoteData: SRD.match(
          {
            notAsked: () => {
              return state.userProgramsRemoteData;
            },
            loading: () => {
              return state.userProgramsRemoteData;
            },
            failure: (msg: Error) => {
              return state.userProgramsRemoteData;
            },
            success: (users: MentorshipProgramSlim[]) => {
              return success([...users, { ...action.payload, live: false }]);
            },
          },
          state.userProgramsRemoteData
        ),
      };

    case "CREATE_MENTORSHIP_PROGRAM":
      return {
        ...state,
      };

    case "FETCH_USER_PROGRAMS_ERROR":
      return {
        ...state,
        userProgramsRemoteData: failure(action.payload),
      };

    default:
      return state;
  }
};

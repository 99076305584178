import { SRD, RD, notAsked, loading, failure, success } from "srd";
import { MentorshipProgramSlim } from "../../server/serverTypes";
import MenteeHome from "../MenteeHome";
import { useUserPrograms } from "../../user/redux/hooks";
import ErrorPage from "../../components/ErrorPage";
import LoadingPage from "../../components/LoadingPage";

const MenteeHomeContainer = () => {
  const studentProgramsRemoteData = useUserPrograms();

  return SRD.match(
    {
      notAsked: () => <LoadingPage />,
      loading: () => <LoadingPage />,
      failure: (msg) => <ErrorPage />,
      success: (users: MentorshipProgramSlim[]) => (
        <MenteeHome mentors={users} />
      ),
    },
    studentProgramsRemoteData
  );
};

export default MenteeHomeContainer;

import React from "react";
import { Grid, IconButton, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import SessionComponent from "../../components/SessionComponent";
import { Link } from "react-router-dom";
import { blackmintTheme } from "../../styles/theme";
import { MentorshipSession } from "../../server/serverTypes";
import { formatAMPM, ordinal } from "../../common/utils";

const useStyles = makeStyles((theme) => ({
  iconBtn: {
    width: "24px",
    height: "24px",
    backgroundColor: "#4DC591",
    margin: "5px 10px",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    color: "#272727",
  },
  subContent: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    color: "#000000",
  },
  greenText: {
    fontWeight: 800,
    color: "#4DC591",
  },
  btn: {
    borderRadius: "20px",
    border: "1px solid #C4C4C4",
  },
  h5: {
    ...blackmintTheme.typographyBlackmint.h5,
    color: "#757575",
    margin: "0px 10px",
    textTransform: "none",
  },
}));

interface IMentorSessionView {
  sessions: MentorshipSession[];
  addUrl: string;
}

const MentorSessionView = ({ sessions, addUrl }: IMentorSessionView) => {
  const classes = useStyles();

  const d = new Date();

  const incompletedSessions = sessions.filter(
    (s) => d < new Date(s.sessionDate)
  );
  const completedSessions = sessions.filter((s) => d > new Date(s.sessionDate));

  const latestIncDate =
    incompletedSessions.length > 0
      ? new Date(incompletedSessions[0].sessionDate)
      : null;

  const dayName = latestIncDate
    ? latestIncDate.toLocaleDateString(undefined, {
        weekday: "short",
      })
    : null;

  const monthName = latestIncDate
    ? latestIncDate.toLocaleDateString(undefined, {
        month: "short",
      })
    : null;

  const date = latestIncDate ? latestIncDate.getDate() : null;

  const formattedTime = latestIncDate ? formatAMPM(latestIncDate) : null;

  return (
    <React.Fragment>
      <Grid container>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ margin: "5px 0px" }}
        >
          <Grid item>Sessions</Grid>
          <Grid item>
            <Link to={addUrl}>
              <IconButton
                aria-label="delete"
                size="small"
                className={classes.iconBtn}
              >
                <AddIcon />
              </IconButton>
            </Link>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ margin: "5px 0px" }}
        >
          <Grid item>
            <Typography className={classes.subContent}>
              Upcoming session:{" "}
              <span className={classes.greenText}>
                {latestIncDate && date
                  ? `${dayName}. ${monthName}. ${ordinal(
                      date
                    )} at ${formattedTime}`
                  : "No upcoming sessions"}
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          {incompletedSessions.map((s) => (
            <SessionComponent session={s} />
          ))}
          {completedSessions.map((s) => (
            <SessionComponent session={s} completed={true} />
          ))}
        </Grid>
        <Grid container justify="center">
          <Button className={classes.btn}>
            <Typography className={classes.h5}>See More Sessions</Typography>
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default MentorSessionView;

import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

const theme = {
  palette: {
    paletteBlackmint: {
      variant: "#1F4F3A",
      variant2: "#B8E8D3",
      primary: "#4DC591",
      primary2: "#272727",
      primary3: "#FFFFFF",
      secondary: "#00664F",
      tooltip: "#113438",
      background: "#F1F1EE",
      background2: "#DECABA",
    },
  },
  typography: {
    title: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "40px",
      color: "#00664F",
    },
    h2: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "800",
      fontSize: "24px",
      color: "#272727",
    },
    h3: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "20px",
      color: "#272727",
    },
    h4: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "800",
      fontSize: "16px",
      color: "#272727",
    },
    h5: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "14px",
      color: "#272727",
    },
    h6: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "12px",
      color: "#272727",
    },
    main: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      color: "#757575",
    },
    mainContent: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      color: "#272727",
    },
  },
};

// export default responsiveFontSizes(theme);

export const deviceDesktopBreakpoint = 768;

export const blackmintTheme = {
  typographyBlackmint: {
    title: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "40px",
      color: "#00664F",
    },
    h2: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "800",
      fontSize: "24px",
      color: "#272727",
    },
    h3: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "20px",
      color: "#272727",
    },
    h4: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "16px",
      color: "#272727",
    },
    h5: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "14px",
      color: "#272727",
    },
    h6: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "12px",
      color: "#272727",
    },
    main: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      color: "#757575",
    },
    mainContent: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      color: "#272727",
    },
  },
  paletteBlackmint: {
    variant: "#1F4F3A",
    variant2: "#B8E8D3",
    primary: "#4DC591",
    primary2: "#272727",
    primary3: "#FFFFFF",
    secondary: "#00664F",
    tooltip: "#113438",
    background: "#F1F1EE",
    background2: "#DECABA",
  },
};

import React from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { LaptopPlants } from "../components/Icons";
import { deviceDesktopBreakpoint } from "../styles/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 204px)",
    [theme.breakpoints.up(deviceDesktopBreakpoint)]: {
      padding: "0px 240px",
    },
  },
  h3: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    color: "#00664F",
  },
  button: {
    background: "#4DC591",
    borderRadius: "8px",
    padding: "8px 42px",
    fontFamily: "Inter",
    fontWeight: 800,
    fontSize: "16px",
    alignItems: "center",
    color: "#272727",
  },
  textField: {
    border: "2px solid #4DC591",
    borderRadius: "10px",
  },
  textFieldOutline: {},
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  sendBtn: {
    background: "#4DC591",
    borderRadius: "8px",
    padding: "8px 42px",
    fontFamily: "Inter",
    fontWeight: 800,
    fontSize: "16px",
    alignItems: "center",
    color: "#272727",
  },
}));

interface IAddMentee {
  email: string;
  handleChangeEmail: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  name: string;
  handleChangeName: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onSubmit: () => void;
}

const AddMentee = ({
  email,
  handleChangeEmail,
  name,
  handleChangeName,
  onSubmit,
}: IAddMentee) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <LaptopPlants />
      </Grid>
      <Grid container justify="center">
        <Typography className={classes.h3}>
          Add your mentee’s contact details
        </Typography>
      </Grid>
      <Grid container>
        <TextField
          placeholder="Mentee's name"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          InputProps={{ classes }}
          inputProps={{
            style: {
              padding: "6px 10px 7px",
            },
          }}
          value={name}
          onChange={(e) => {
            handleChangeName(e);
          }}
        />
      </Grid>
      <Grid container>
        <TextField
          placeholder="Mentee’s email address"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.textField}
          InputProps={{ classes }}
          inputProps={{
            style: {
              padding: "6px 10px 7px",
            },
          }}
          value={email}
          onChange={(e) => {
            handleChangeEmail(e);
          }}
        />
      </Grid>
      <Grid container justify="center" style={{ margin: "40px 0px" }}>
        {/* <Link to={"/mentor"} style={{ textDecoration: "none" }}> */}
        <Button
          className={classes.sendBtn}
          onClick={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          Send Invite
        </Button>
        {/* </Link> */}
      </Grid>
    </Container>
  );
};

export default AddMentee;

import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  OutlinedInput,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { blackmintTheme, deviceDesktopBreakpoint } from "../../styles/theme";
import PendingTaskComponent from "../../components/PendingTaskComponent";
import { actions } from "../redux/model";
import { InputMentorshipTask, MentorshipTask } from "../../server/serverTypes";
import { useProgramInfo } from "../../mentor/redux/hooks";
import { useDispatch } from "react-redux";
import { useMenteeProgramInfo } from "../../mentee/redux/hooks";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up(deviceDesktopBreakpoint)]: {
      padding: "0px 240px",
      height: "100vh",
    },
  },
  inputOutline: {
    border: "1px solid #4DC591 !important",
    borderRadius: "5px",
  },
  linkInputOutline: {
    border: "2px solid #4DC591 !important",
    borderRadius: "10px",
  },
  btn: {
    alignItems: "center",
    width: "212px",
    height: "38px",
    background: "#4DC591",
    borderRadius: "8px",
    margin: "40px 0px",
  },
  h4: {
    ...blackmintTheme.typographyBlackmint.h4,
  },
  mainContent: {
    ...blackmintTheme.typographyBlackmint.mainContent,
    fontWeight: 600,
  },
}));

interface IMentorAssignmentPage {
  task: MentorshipTask;
  isMentor: boolean;
  updateTask: (val: {
    id: number;
    title: string;
    description: string;
    dueDate: Date;
    resources?: string;
    notes?: string;
    assignmentLink?: string;
  }) => void;
}

const MentorAssignmentPage = ({
  task,
  isMentor,
  updateTask,
}: IMentorAssignmentPage) => {
  const classes = useStyles();

  const [description, setDescription] = useState(task.description);
  const [notes, setNotes] = useState(task.notes);
  const [resources, setResources] = useState(task.resources);
  const [assignmentLink, setAssignmentLink] = useState(task.assignmentLink);

  const handleUpdate = () => {
    updateTask({
      id: task.id,
      title: task.title,
      description: description,
      dueDate: task.dueDate,
      resources: resources,
      notes: notes,
      assignmentLink: assignmentLink,
    });
  };

  return (
    <Container className={classes.container}>
      <PendingTaskComponent
        withArrow={false}
        task={task}
        handleDelete={(id: number) => {}}
        handleUpdate={(id: number) => {}}
        hideOption={true}
      />
      <Grid container>
        <Typography className={classes.mainContent}>Task Details</Typography>
      </Grid>
      <Grid container>
        <OutlinedInput
          id="standard-multiline-static"
          label="Multiline"
          multiline
          placeholder="Description"
          value={description}
          fullWidth
          notched={false}
          rows={6}
          classes={{
            notchedOutline: classes.inputOutline,
          }}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          readOnly={!isMentor}
        />
      </Grid>
      <Grid container>
        <Typography className={classes.mainContent}>Notes</Typography>
      </Grid>
      <Grid container>
        <OutlinedInput
          id="standard-multiline-static"
          label="Multiline"
          multiline
          placeholder=" - Ask questions if you need help 
          - Feel free to use resources below
          - Stuck? Leave a note here for your mentor
          - Etc........"
          value={notes}
          fullWidth
          notched={false}
          rows={6}
          classes={{
            notchedOutline: classes.inputOutline,
          }}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
        />
      </Grid>
      <Grid container>
        <Typography className={classes.mainContent}>Resources</Typography>
      </Grid>
      <Grid container>
        <OutlinedInput
          id="standard-multiline-static"
          label="Multiline"
          multiline
          placeholder=" - https://www.codecademy.com/learn/learn-html 
          - https://htmldog.com/guides/html/beginner/
          - https://www.coderepublics.com/HTML/html-tutorial.php
          - https://www.w3schools.com/"
          value={resources}
          fullWidth
          notched={false}
          rows={6}
          classes={{
            notchedOutline: classes.inputOutline,
          }}
          onChange={(e) => {
            setResources(e.target.value);
          }}
          readOnly={!isMentor}
        />
      </Grid>
      <Grid container>
        <Typography className={classes.mainContent}>
          Link to Assignment
        </Typography>
      </Grid>
      <Grid container>
        <OutlinedInput
          id="standard-multiline-static"
          label="Multiline"
          placeholder="Google drive shared link"
          value={assignmentLink}
          fullWidth
          notched={false}
          classes={{
            notchedOutline: classes.linkInputOutline,
          }}
          onChange={(e) => {
            setAssignmentLink(e.target.value);
          }}
        />
      </Grid>
      <Grid container justify="center">
        <Button className={classes.btn} onClick={handleUpdate}>
          <Typography className={classes.h4}>Save Changes</Typography>
        </Button>
      </Grid>
    </Container>
  );
};

export default MentorAssignmentPage;

import { ActionType, action } from "typesafe-actions";
import {
  GqlUser,
  Mentor,
  MentorshipProgramConnection,
  MentorshipProgramSlim,
  MentorshipProgramStudent,
  Student,
  UserType,
} from "../../server/serverTypes";
import { SRD, RD, notAsked, loading, failure, success } from "srd";
import { AxiosError } from "axios";
import { actions as taskActions } from "../../task/redux/model";
import { actions as sessionActions } from "../../sessions/redux/model";
import { actions as userActions } from "../../user/redux/model";
import { actions as adminActions } from "../../admin/redux/model";

interface IModel {
  mentorProgramRD: RD<AxiosError, MentorshipProgramConnection>;
  mentorInfo: Mentor | undefined;
  mentorStudents: RD<AxiosError, MentorshipProgramSlim[]>;
  programInfo: MentorshipProgramConnection | undefined;
  mentorMentees: MentorshipProgramSlim[];
  sendingInvite: boolean;
}

const initialState: IModel = {
  mentorProgramRD: notAsked(),
  mentorInfo: undefined,
  mentorStudents: notAsked(),
  programInfo: undefined,
  mentorMentees: [],
  sendingInvite: false,
};

export const actions = {
  fetchMentorshipProgram: (p: { id: number; firebaseHash: string }) =>
    action("FETCH_MENTORSHIP_PROGRAM", p),
  receiveMentorshipProgram: (p: MentorshipProgramConnection) =>
    action("RECEIVE_MENTORSHIP_PROGRAM", p),
  fetchMentorshipProgramError: (p: AxiosError) =>
    action("FETCH_MENTORSHIP_PROGRAM_ERROR", p),

  fetchMentorStudents: (p: { id: number }) =>
    action("FETCH_MENTOR_STUDENTS", p),
  receiveMentorStudents: (p: MentorshipProgramSlim[]) =>
    action("RECEIVE_MENTOR_STUDENTS", p),
  fetchMentorStudentsError: (p: AxiosError) =>
    action("FETCH_MENTOR_STUDENTS_ERROR", p),

  createMentorshipProgram: (p: {
    id: number;
    student: {
      name: string;
      firebaseId: string;
      email: string;
      type: UserType;
    };
  }) => action("CREATE_MENTORSHIP_PROGRAM", p),
  receiveCreatedMentorshipProgram: (p: GqlUser) =>
    action("RECEIVE_CREATED_MENTORSHIP_PROGRAM", p),
  createMentorshipProgramError: (p: Error) =>
    action("CREATE_MENTORSHIP_PROGRAM_ERROR", p),
};

type MentorActionType =
  | ActionType<typeof actions>
  | ActionType<typeof taskActions>
  | ActionType<typeof sessionActions>
  | ActionType<typeof userActions>
  | ActionType<typeof adminActions>;

export const reducer = (
  state: IModel = initialState,
  action: MentorActionType
): IModel => {
  switch (action.type) {
    case "SIGN_OUT":
      return {
        ...state,
        mentorProgramRD: notAsked(),
        mentorStudents: notAsked(),
        mentorInfo: undefined,
        programInfo: undefined,
      };
    case "FETCH_MENTORSHIP_PROGRAM":
      return {
        ...state,
        mentorProgramRD: loading(),
      };
    case "FETCH_MENTORSHIP_PROGRAM_ERROR":
      return {
        ...state,
        mentorProgramRD: failure(action.payload),
      };
    case "RECEIVE_MENTORSHIP_PROGRAM":
      return {
        ...state,
        mentorProgramRD: success(action.payload),
        mentorInfo: action.payload.mentor,
        programInfo: action.payload,
      };

    case "FETCH_MENTOR_STUDENTS":
      return {
        ...state,
      };

    case "RECEIVE_MENTOR_STUDENTS":
      return {
        ...state,
        mentorStudents: success(action.payload),
        mentorMentees: action.payload,
      };

    case "FETCH_MENTOR_STUDENTS_ERROR":
      return {
        ...state,
        mentorStudents: failure(action.payload),
      };

    case "RECEIVE_ADDED_MENTORSHIP_TASK":
      const upt = SRD.match(
        {
          notAsked: () => {
            return state.mentorProgramRD;
          },
          loading: () => {
            return state.mentorProgramRD;
          },
          failure: (msg: AxiosError) => {
            return state.mentorProgramRD;
          },
          success: (mentorshipProgram: MentorshipProgramConnection) => {
            return success({
              ...mentorshipProgram,
              tasks: [...mentorshipProgram.tasks, action.payload],
            });
          },
        },
        state.mentorProgramRD
      );
      return {
        ...state,
        mentorProgramRD: upt,
      };

    case "RECEIVE_EDITED_MENTORSHIP_TASK":
      return {
        ...state,
        mentorProgramRD: SRD.match(
          {
            notAsked: () => {
              return state.mentorProgramRD;
            },
            loading: () => {
              return state.mentorProgramRD;
            },
            failure: (msg: AxiosError) => {
              return state.mentorProgramRD;
            },
            success: (mentorshipProgram: MentorshipProgramConnection) => {
              return success({
                ...mentorshipProgram,
                tasks: mentorshipProgram.tasks.map((m) => {
                  if (m.id === action.payload.id) {
                    return {
                      ...m,
                      ...action.payload,
                    };
                  } else {
                    return m;
                  }
                }),
              });
            },
          },
          state.mentorProgramRD
        ),
      };

    case "RECEIVE_COMPLETED_MENTORSHIP_TASK":
      return {
        ...state,
        mentorProgramRD: SRD.match(
          {
            notAsked: () => {
              return state.mentorProgramRD;
            },
            loading: () => {
              return state.mentorProgramRD;
            },
            failure: (msg: AxiosError) => {
              return state.mentorProgramRD;
            },
            success: (mentorshipProgram: MentorshipProgramConnection) => {
              return success({
                ...mentorshipProgram,
                tasks: mentorshipProgram.tasks.map((m) => {
                  if (m.id === action.payload.id) {
                    return {
                      ...m,
                      completed: true,
                    };
                  } else {
                    return m;
                  }
                }),
              });
            },
          },
          state.mentorProgramRD
        ),
      };

    case "RECEIVE_DELETED_MENTORSHIP_TASK":
      return {
        ...state,
        mentorProgramRD: SRD.match(
          {
            notAsked: () => {
              return state.mentorProgramRD;
            },
            loading: () => {
              return state.mentorProgramRD;
            },
            failure: (msg: AxiosError) => {
              return state.mentorProgramRD;
            },
            success: (mentorshipProgram: MentorshipProgramConnection) => {
              return success({
                ...mentorshipProgram,
                tasks: mentorshipProgram.tasks.filter(
                  (t) => t.id !== action.payload.id
                ),
              });
            },
          },
          state.mentorProgramRD
        ),
      };

    case "RECEIVE_ADDED_MENTORSHIP_SESSION":
      return {
        ...state,
        mentorProgramRD: SRD.match(
          {
            notAsked: () => {
              return state.mentorProgramRD;
            },
            loading: () => {
              return state.mentorProgramRD;
            },
            failure: (msg: AxiosError) => {
              return state.mentorProgramRD;
            },
            success: (mentorshipProgram: MentorshipProgramConnection) => {
              return success({
                ...mentorshipProgram,
                sessions: [...mentorshipProgram.sessions, action.payload],
              });
            },
          },
          state.mentorProgramRD
        ),
      };

    case "RECEIVE_DELETED_MENTORSHIP_SESSION":
      return {
        ...state,
        mentorProgramRD: SRD.match(
          {
            notAsked: () => {
              return state.mentorProgramRD;
            },
            loading: () => {
              return state.mentorProgramRD;
            },
            failure: (msg: AxiosError) => {
              return state.mentorProgramRD;
            },
            success: (mentorshipProgram: MentorshipProgramConnection) => {
              return success({
                ...mentorshipProgram,
                sessions: mentorshipProgram.sessions.filter(
                  (s) => s.id !== action.payload.id
                ),
              });
            },
          },
          state.mentorProgramRD
        ),
      };

    case "RECEIVE_CREATED_MENTORSHIP_PROGRAM":
      return {
        ...state,
        sendingInvite: false,
        mentorStudents: SRD.match(
          {
            notAsked: () => {
              return state.mentorStudents;
            },
            loading: () => {
              return state.mentorStudents;
            },
            failure: (msg: AxiosError) => {
              return state.mentorStudents;
            },
            success: (users: MentorshipProgramSlim[]) => {
              return success([...users, { ...action.payload, live: false }]);
            },
          },
          state.mentorStudents
        ),
      };

    case "CREATE_MENTORSHIP_PROGRAM_ERROR":
      return {
        ...state,
        sendingInvite: false,
      };

    case "CREATE_MENTORSHIP_PROGRAM":
      return {
        ...state,
        sendingInvite: true,
      };

    case "CREATE_RISING_STAR_PROGRAM":
      return {
        ...state,
        sendingInvite: true,
      };

    case "RECEIVE_CREATED_RISING_STAR_PROGRAM":
      return {
        ...state,
        sendingInvite: false,
      };

    case "CREATE_RISING_STAR_PROGRAM_ERROR":
      return {
        ...state,
        sendingInvite: false,
      };

    default:
      return state;
  }
};

import React from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { blackmintTheme } from "../../styles/theme";
import {
  VisibilityOff as HiddenPasswordIcon,
  Visibility as ShowPasswordIcon,
} from "@material-ui/icons";
import { useAuthenticating } from "../redux/hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minHeight: "calc(100vh - 204px)",
      paddingTop: theme.spacing(5),
      [theme.breakpoints.up("md")]: {
        height: "100vh",
      },
    },
    title: {
      ...blackmintTheme.typographyBlackmint.h2,
      fontWeight: 600,
      color: "#00664F",
      textAlign: "center",
    },
    textField: {
      border: "2px solid #4DC591",
      borderRadius: "10px",
    },
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    iconBtn: {},
    btn: {
      backgroundColor: "#4DC591",
      borderRadius: theme.spacing(1),
      height: "38px",
      color: "#272727",
      margin: "4px 0px",
      "&:hover": {
        backgroundColor: "#3ab27e",
      },
    },
    btnText: {
      ...blackmintTheme.typographyBlackmint.h3,
      fontWeight: 600,
    },
  })
);

interface ILogin {
  email: string;
  handleChangeEmail: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  password: string;
  handleChangePassword: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleClickShowPassword: () => void;
  showPassword: boolean;
  onSubmit: () => void;
  onSubmitSocial: () => void;
}

const Login = ({
  email,
  handleChangeEmail,
  password,
  handleChangePassword,
  handleClickShowPassword,
  showPassword,
  onSubmit,
  onSubmitSocial,
}: ILogin) => {
  const classes = useStyles();
  const authenticating = useAuthenticating();

  return (
    <Container className={classes.container} maxWidth="xs">
      <Typography className={classes.title}>Sign In</Typography>
      <TextField
        placeholder="Email Address"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        className={classes.textField}
        InputProps={{ classes }}
        inputProps={{
          style: {
            padding: "6px 10px 7px",
            borderRadius: "10px",
          },
        }}
        value={email}
        onChange={(e) => {
          handleChangeEmail(e);
        }}
      />
      <TextField
        placeholder="Password"
        type={showPassword ? "text" : "password"}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        className={classes.textField}
        InputProps={{
          classes,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                className={classes.iconBtn}
                disableRipple={true}
              >
                {showPassword ? <HiddenPasswordIcon /> : <ShowPasswordIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{
          style: {
            padding: "6px 10px 7px",
            borderRadius: "10px",
          },
        }}
        value={password}
        onChange={(e) => {
          handleChangePassword(e);
        }}
      />
      <Grid container justify="center">
        <Grid item xs={12}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.btn}
          >
            {authenticating ? (
              <CircularProgress size={30} color={"inherit"} />
            ) : (
              <Typography className={classes.btnText}>Login</Typography>
            )}
          </Button>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              onSubmitSocial();
            }}
            className={classes.btn}
          >
            {authenticating ? (
              <CircularProgress size={30} color={"inherit"} />
            ) : (
              <Typography className={classes.btnText}>
                Sign in with Google
              </Typography>
            )}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;

const config = {
  apiKey: "AIzaSyAv2YvI4eqGmP5LSyj1U-cMVjXXRbKH_i0",
  authDomain: "black-mint-24253.firebaseapp.com",
  databaseURL: "https://black-mint-24253.firebaseio.com",
  projectId: "black-mint-24253",
  storageBucket: "black-mint-24253.appspot.com",
  messagingSenderId: "996294361696",
  appId: "1:996294361696:web:d8303075f2298faf84b905",
  measurementId: "G-GFLYCKFYH7",
};

export default config;

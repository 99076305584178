import { ApolloError, useQuery } from "@apollo/client";
import { AxiosError } from "axios";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SRD, RD, notAsked, loading, failure, success } from "srd";
import { RootState } from "../../common/redux/reducers";
import { MentorshipProgramConnection } from "../../server/serverTypes";
import { actions } from "./model";

export const useMentorshipProgramRD = (firebaseId: string) => {
  const rd = useSelector((state: RootState) => state.mentor.mentorProgramRD);
  const userRemoteData = useSelector(
    (state: RootState) => state.user.userRemoteData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    SRD.match(
      {
        notAsked: () => {
          if (SRD.isSuccess(userRemoteData)) {
            dispatch(
              actions.fetchMentorshipProgram({
                id: userRemoteData.data.user.id,
                firebaseHash: firebaseId,
              })
            );
          }
        },
        loading: () => {
          //
        },
        failure: (msg: AxiosError) => {
          //
        },
        success: (mentorshipProgram: MentorshipProgramConnection) => {
          if (
            SRD.isSuccess(userRemoteData) &&
            firebaseId !== mentorshipProgram.student.firebaseHash
          ) {
            dispatch(
              actions.fetchMentorshipProgram({
                id: userRemoteData.data.user.id,
                firebaseHash: firebaseId,
              })
            );
          }
        },
      },
      rd
    );
  }, [rd, dispatch, firebaseId]);

  return rd;
};

export const useMentorshipProgram = () =>
  useSelector((state: RootState) => state.mentor.mentorProgramRD);

export const useMentorInfo = () => {
  return useSelector((state: RootState) => state.mentor.mentorInfo);
};

export const useMentorStudentsRD = () => {
  const dispatch = useDispatch();
  const students = useSelector(
    (state: RootState) => state.mentor.mentorStudents
  );
  const userRemoteData = useSelector(
    (state: RootState) => state.user.userRemoteData
  );

  if (SRD.isNotAsked(students) && SRD.isSuccess(userRemoteData)) {
    dispatch(actions.fetchMentorStudents({ id: userRemoteData.data.user.id }));
  }

  return students;
};

export const useMentorshipTask = (id: number) => {};

export const useProgramInfo = () =>
  useSelector((state: RootState) => state.mentor.programInfo);

export const useMenorMentees = () =>
  useSelector((state: RootState) => state.mentor.mentorMentees);

export const useSending = () =>
  useSelector((state: RootState) => state.mentor.sendingInvite);

import { statsReporterIncludeStat } from "@firebase/database/dist/src/core/stats/StatsReporter";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../common/redux/reducers";
import { SRD, RD, notAsked, loading, failure, success } from "srd";
import { actions } from "./model";
import { RisingStarProgram } from "../../server/serverTypes";

export const useRisingStarsProgramRD = (
  firebaseId: string,
  isStudent: boolean
) => {
  const rd = useSelector(
    (state: RootState) => state.admin.risingStarProgramRemoteData
  );
  const userRemoteData = useSelector(
    (state: RootState) => state.user.userRemoteData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    SRD.match(
      {
        notAsked: () => {
          if (SRD.isSuccess(userRemoteData)) {
            dispatch(
              actions.fetchRisingStarsProgram({
                id: userRemoteData.data.user.id,
                firebaseHash: firebaseId,
                isStudent: isStudent,
              })
            );
          }
        },
        loading: () => {
          //
        },
        failure: (msg: Error) => {
          //
        },
        success: (risingStarProgram: RisingStarProgram) => {
          if (
            SRD.isSuccess(userRemoteData) &&
            firebaseId !== risingStarProgram.student.firebaseHash
          ) {
            dispatch(
              actions.fetchRisingStarsProgram({
                id: userRemoteData.data.user.id,
                firebaseHash: firebaseId,
                isStudent: isStudent,
              })
            );
          }
        },
      },
      rd
    );
  }, [rd, dispatch, firebaseId]);

  return rd;
};

export const useRisingStarsProgramRDForStudent = (
  firebaseId: string,
  isStudent: boolean
) => {
  const rd = useSelector(
    (state: RootState) => state.admin.risingStarProgramRemoteData
  );
  const userRemoteData = useSelector(
    (state: RootState) => state.user.userRemoteData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    SRD.match(
      {
        notAsked: () => {
          if (SRD.isSuccess(userRemoteData)) {
            dispatch(
              actions.fetchRisingStarsProgram({
                id: userRemoteData.data.user.id,
                firebaseHash: firebaseId,
                isStudent: isStudent,
              })
            );
          }
        },
        loading: () => {
          //
        },
        failure: (msg: Error) => {
          //
        },
        success: (risingStarProgram: RisingStarProgram) => {
          if (
            SRD.isSuccess(userRemoteData) &&
            firebaseId !== risingStarProgram.admin.firebaseHash
          ) {
            dispatch(
              actions.fetchRisingStarsProgram({
                id: userRemoteData.data.user.id,
                firebaseHash: firebaseId,
                isStudent: isStudent,
              })
            );
          }
        },
      },
      rd
    );
  }, [rd, dispatch, firebaseId]);

  return rd;
};

import React from "react";
import ErrorPage from "../../components/ErrorPage";
import LoadingPage from "../../components/LoadingPage";
import { InputTask, MentorshipTask } from "../../server/serverTypes";
import { useTaskAdmin } from "../redux/hooks";
import { SRD } from "srd";
import AssignmentPage from "../views/AssignmentPage";
import { useDispatch } from "react-redux";
import { actions } from "../redux/model";
import { useUserObj } from "../../user/redux/hooks";

interface IRisingStarAssignmentPageContainer {
  assignmentId: string;
  userHash: string;
}

const RisingStarAssignmentPageContainer = ({
  assignmentId,
  userHash,
}: IRisingStarAssignmentPageContainer) => {
  const dispatch = useDispatch();
  const user = useUserObj();

  const taskRd = useTaskAdmin(
    Number(assignmentId),
    user ? user.user.firebaseHash : ""
  );

  const updateTask = (val: {
    id: number;
    title: string;
    description: string;
    dueDate: Date;
    resources?: string;
    notes?: string;
    assignmentLink?: string;
  }) => {
    const taskObj: InputTask = {
      id: val.id,
      title: val.title,
      description: val.description,
      dueDate: val.dueDate,
      courseId: 1,
      resources: val.resources,
      notes: val.notes,
      assignmentLink: val.assignmentLink,
    };
    if (user) {
      dispatch(
        actions.editRisingStarTask({
          task: taskObj,
          hash: user.user.firebaseHash,
        })
      );
    }
  };

  return SRD.match(
    {
      notAsked: () => <LoadingPage />,
      loading: () => <LoadingPage />,
      failure: (msg: Error) => <ErrorPage />,
      success: (task: MentorshipTask) => {
        return (
          <AssignmentPage
            task={task}
            isMentor={false}
            updateTask={updateTask}
          />
        );
      },
    },
    taskRd
  );
};

export default RisingStarAssignmentPageContainer;

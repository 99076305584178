import React from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  TextField,
  OutlinedInput,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { CreateTaskIcon } from "../../components/Icons";
import { blackmintTheme } from "../../styles/theme";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { deviceDesktopBreakpoint } from "../../styles/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 204px)",
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up(deviceDesktopBreakpoint)]: {
      padding: "0px 240px",
      height: "100vh",
    },
  },
  iconContainer: {
    margin: theme.spacing(5, 0),
  },
  h3: {
    ...blackmintTheme.typographyBlackmint.h3,
    fontWeight: 600,
    color: blackmintTheme.paletteBlackmint.secondary,
  },
  h4: {
    ...blackmintTheme.typographyBlackmint.h4,
  },
  mainContent: {
    ...blackmintTheme.typographyBlackmint.mainContent,
    fontWeight: 600,
    lineHeight: "22px",
  },
  taskDescription: {
    width: "100%",
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(0, 0, 0, 0.6)",
    },
  },
  datePicker: {
    width: "100%",
    "& .MuiInputBase-root": {
      padding: 0,
      "& .MuiButtonBase-root": {
        padding: 0,
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(0, 0, 0, 0.6)",
    },
  },
  btn: {
    backgroundColor: blackmintTheme.paletteBlackmint.primary,
    borderRadius: theme.spacing(1),
    padding: "8px 42px",
  },
  inputOutline: {},
}));

interface IAddTask {
  name: string;
  description: string;
  date: Date;
  addSampleTask: () => void;
  handleNameChange: (val: string) => void;
  handleDescriptionChange: (val: string) => void;
  handleDateChange: (date: MaterialUiPickersDate) => void;
}

const AddTask = ({
  name,
  description,
  date,
  addSampleTask,
  handleNameChange,
  handleDateChange,
  handleDescriptionChange,
}: IAddTask) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Grid container justify="center" className={classes.iconContainer}>
        <Grid item>
          <CreateTaskIcon />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Typography className={classes.h3}>Create a new task</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.mainContent}>
            Help your mentee keep track of their goals and assignments by
            setting some deadlines for them.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Title"
            className={classes.taskDescription}
            InputLabelProps={{
              style: { color: "#757575" },
            }}
            value={name}
            onChange={(e) => {
              handleNameChange(e.target.value);
            }}
          />
        </Grid>
        <Grid container>
          <TextField
            id="standard-multiline-static"
            label="Task Details"
            multiline
            value={description}
            onChange={(e) => {
              handleDescriptionChange(e.target.value);
            }}
            fullWidth
            className={classes.taskDescription}
            InputLabelProps={{
              style: { color: "#757575" },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Due date"
              format="EEEE,dd MMMM"
              value={date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              keyboardIcon={<CalendarTodayIcon />}
              InputAdornmentProps={{ position: "start" }}
              className={classes.datePicker}
              InputLabelProps={{
                style: { color: "#757575" },
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button className={classes.btn} onClick={addSampleTask}>
            <Typography className={classes.h4}>Create task</Typography>
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
export default AddTask;

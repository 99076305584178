import { SRD } from "srd";
import AdminHomeContainer from "../../admin/containers/AdminHomeContainer";
import ErrorPage from "../../components/ErrorPage";
import LoadingPage from "../../components/LoadingPage";
import MenteeHomeContainer from "../../mentee/containers/MenteeHomeContainer";
import HomeContainer from "../../mentor/containers/HomeContainer";
import { UserType } from "../../server/serverTypes";
import { useUserInfo } from "../redux/hooks";
import { UserFirebaseData } from "../redux/sagas";

const InitialPageContainer = () => {
  const userRemoteData = useUserInfo();
  return SRD.match(
    {
      notAsked: () => <LoadingPage />,
      loading: () => <LoadingPage />,
      failure: (msg) => <ErrorPage />,
      success: (user: UserFirebaseData) => {
        if (user.user.type == UserType.mentor) {
          return <HomeContainer />;
        } else if (user.user.type === UserType.admin) {
          return <AdminHomeContainer />;
        } else {
          return <MenteeHomeContainer />;
        }
      },
    },
    userRemoteData
  );
};

export default InitialPageContainer;

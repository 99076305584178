import React, { useState } from "react";
import Login from "../views/Login";
import Firebase from "../../common/Firebase";
import "firebase/auth";
import { useDispatch } from "react-redux";
import { actions } from "../redux/model";
import firebase from "firebase";
import ErrorPage from "../../components/ErrorPage";
import LoadingPage from "../../components/LoadingPage";

const LoginContainer = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleChangePassword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPassword(event.target.value);
  };

  const handleChangeEmail = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmail(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = () => {
    dispatch(
      actions.verifyUserCredential({
        email,
        password,
      })
    );
  };

  const onSubmitSocial = () => {
    dispatch(actions.verifyUserCredentialWithGoogle());
  };

  return (
    <Login
      email={email}
      handleChangeEmail={handleChangeEmail}
      password={password}
      handleChangePassword={handleChangePassword}
      handleClickShowPassword={handleClickShowPassword}
      showPassword={showPassword}
      onSubmit={onSubmit}
      onSubmitSocial={onSubmitSocial}
    />
  );
};

export default LoginContainer;

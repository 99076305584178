import React from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import DesktopNavBar from "./DesktopNavBar";
import NavBar from "./NavBar";
import { deviceDesktopBreakpoint } from "../styles/theme";
const HeaderContainer = () => {
  const theme = useTheme();

  const desktop = useMediaQuery(theme.breakpoints.up(deviceDesktopBreakpoint));

  if (desktop) {
    return <DesktopNavBar />;
  }
  return <NavBar />;
};

export default HeaderContainer;

import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import createRootReducer from "../reducers";
import mentorsRootSaga from "../../../mentor/redux/sagas";
import usersRootSaga from "../../../user/redux/sagas";
import menteesRootSaga from "../../../mentee/redux/sagas";
import tasksRootSaga from "../../../task/redux/sagas";
import sessionsRootSaga from "../../../sessions/redux/sagas";
import videosRootSaga from "../../../videos/redux/sagas";
import adminRootSaga from "../../../admin/redux/sagas";
import navigationRootSaga from "../../../navigation/sagas";
console.log(process.env.NODE_ENV);
const logger = createLogger({
  predicate: () => process.env.NODE_ENV !== "production",
});

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  createRootReducer(history),
  applyMiddleware(logger, sagaMiddleware, routerMiddleware(history))
);
sagaMiddleware.run(mentorsRootSaga);
sagaMiddleware.run(usersRootSaga);
sagaMiddleware.run(menteesRootSaga);
sagaMiddleware.run(tasksRootSaga);
sagaMiddleware.run(sessionsRootSaga);
sagaMiddleware.run(videosRootSaga);
sagaMiddleware.run(adminRootSaga);
sagaMiddleware.run(navigationRootSaga);
export default store;

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SRD } from "srd";
import { RootState } from "../../common/redux/reducers";
import { MentorshipSession } from "../../server/serverTypes";
import { actions } from "./model";

export const useSession = (id: number) => {
  const history = useHistory();
  const rd = useSelector((state: RootState) => state.session.sessionRemoteData);
  const userRemoteData = useSelector(
    (state: RootState) => state.user.userRemoteData
  );
  const mentorshipProgramRemoteData = useSelector(
    (state: RootState) => state.mentor.mentorProgramRD
  );
  const menteeProgramRemoteData = useSelector(
    (state: RootState) => state.mentee.menteeProgramRD
  );
  const dispatch = useDispatch();

  SRD.match(
    {
      notAsked: () => {
        if (
          SRD.isSuccess(userRemoteData) &&
          (SRD.isSuccess(mentorshipProgramRemoteData) ||
            SRD.isSuccess(menteeProgramRemoteData))
        ) {
          dispatch(actions.fetchMentorshipSession({ id: id }));
        } else {
          history.push("/");
        }
      },
      loading: () => {
        //
      },
      failure: (msg: Error) => {
        //
      },
      success: (mentorshipSession: MentorshipSession) => {
        if (
          SRD.isSuccess(userRemoteData) &&
          (SRD.isSuccess(mentorshipProgramRemoteData) ||
            SRD.isSuccess(menteeProgramRemoteData))
        ) {
          if (id !== mentorshipSession.id) {
            dispatch(actions.fetchMentorshipSession({ id: id }));
          }
        } else {
          history.push("/");
        }
      },
    },
    rd
  );
  return rd;
};

export const useSessionAdmin = (id: number, firebaseId: string) => {
  const rd = useSelector((state: RootState) => state.session.sessionRemoteData);
  const userRemoteData = useSelector(
    (state: RootState) => state.user.userRemoteData
  );
  const history = useHistory();

  const dispatch = useDispatch();

  SRD.match(
    {
      notAsked: () => {
        if (SRD.isSuccess(userRemoteData)) {
          dispatch(
            actions.fetchRisingStarSession({
              id: id,
              userFirebaseId: firebaseId,
            })
          );
        } else {
          history.push("/");
        }
      },
      loading: () => {
        //
      },
      failure: (msg: Error) => {
        //
      },
      success: (session: MentorshipSession) => {
        //
      },
    },
    rd
  );

  return rd;
};

import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { actions } from "../redux/model";
import gql from "graphql-tag";
import {
  MentorshipProgramConnection,
  MentorshipTask,
  UserType,
} from "../../server/serverTypes";
import { AxiosResponse } from "axios";
import { axiosGqlRequest } from "../../server/common";
import { push, replace, goBack } from "connected-react-router";
import { RootState } from "../../common/redux/reducers";
import { UserFirebaseData } from "../../user/redux/sagas";

function* addMentorshipTask(
  action: ActionType<typeof actions.addMentorshipTask>
) {
  try {
    const data: AxiosResponse<{
      data: {
        createMentorshipTask: MentorshipTask;
      };
    }> = yield call(axiosGqlRequest, {
      query: ADD_MENTORSHIP_TASK,
      variables: { task: action.payload },
    });
    yield put(
      actions.receiveAddedMentorshipTask(data.data.data.createMentorshipTask)
    );
    yield put(goBack());
  } catch (error) {
    yield put(actions.addMentorshipTaskError(error as Error));
  }
}

function* addRisingStarTask(
  action: ActionType<typeof actions.addRisingStarTask>
) {
  try {
    const data: AxiosResponse<{
      data: {
        createRisingStarTask: MentorshipTask;
      };
    }> = yield call(axiosGqlRequest, {
      query: ADD_RISING_STAR_TASK,
      variables: { task: action.payload },
    });
    yield put(
      actions.receiveAddedRisingStarTask(data.data.data.createRisingStarTask)
    );
    yield put(goBack());
  } catch (error) {
    yield put(actions.addRisingStarTaskError(error as Error));
  }
}

const ADD_MENTORSHIP_TASK = gql`
  mutation createMentorshipTask($task: InputMentorshipTask!) {
    createMentorshipTask(task: $task) {
      id
      title
      dueDate
      description
      completed
    }
  }
`;

const ADD_RISING_STAR_TASK = gql`
  mutation createRisingStarTask($task: InputTask!) {
    createRisingStarTask(task: $task) {
      id
      title
      dueDate
      description
      completed
    }
  }
`;

function* fetchMentorshipTask(
  action: ActionType<typeof actions.fetchMentorshipTask>
) {
  try {
    const data: AxiosResponse<{
      data: {
        queryMentorshipTask: MentorshipTask;
      };
    }> = yield call(axiosGqlRequest, {
      query: MENTORSHIP_TASK_QUERY,
      variables: action.payload,
    });
    yield put(
      actions.receiveMentorshipTask(data.data.data.queryMentorshipTask)
    );
  } catch (error) {
    yield put(actions.fetchMentorshipTaskError(error as Error));
  }
}

const MENTORSHIP_TASK_QUERY = gql`
  query queryMentorshipTask($id: Int!) {
    queryMentorshipTask(id: $id) {
      id
      title
      dueDate
      assignmentLink
      completed
      description
      notes
      resources
    }
  }
`;

function* fetchRisingStarTask(
  action: ActionType<typeof actions.fetchRisingStarTask>
) {
  try {
    const data: AxiosResponse<{
      data: {
        queryRisingStarTask: MentorshipTask;
      };
    }> = yield call(axiosGqlRequest, {
      query: RISING_STAR_TASK_QUERY,
      variables: {
        id: action.payload.id,
        firebaseId: action.payload.userFirebaseId,
      },
    });
    yield put(
      actions.receiveRisingStarTask(data.data.data.queryRisingStarTask)
    );
  } catch (error) {
    yield put(actions.fetchRisingStarTaskError(error as Error));
  }
}

const RISING_STAR_TASK_QUERY = gql`
  query queryRisingStarTask($id: Int!, $firebaseId: String!) {
    queryRisingStarTask(id: $id, firebaseId: $firebaseId) {
      id
      title
      dueDate
      assignmentLink
      completed
      description
      notes
      resources
    }
  }
`;

function* editMentorshipTask(
  action: ActionType<typeof actions.editMentorshipTask>
) {
  try {
    const data: AxiosResponse<{
      data: {
        updateMentorshipTask: MentorshipTask;
      };
    }> = yield call(axiosGqlRequest, {
      query: EDIT_MENTORSHIP_TASK,
      variables: { task: action.payload },
    });
    yield put(
      actions.receiveEditedMentorshipTask(data.data.data.updateMentorshipTask)
    );
    yield put(goBack());
  } catch (error) {
    yield put(actions.editMentorshipTaskError(error as Error));
  }
}

const EDIT_MENTORSHIP_TASK = gql`
  mutation updateMentorshipTask($task: InputMentorshipTask!) {
    updateMentorshipTask(task: $task) {
      id
      title
      dueDate
      description
      completed
      assignmentLink
      notes
    }
  }
`;

function* editRisingStarTask(
  action: ActionType<typeof actions.editRisingStarTask>
) {
  try {
    const data: AxiosResponse<{
      data: { updateRisingStarTask: MentorshipTask };
    }> = yield call(axiosGqlRequest, {
      query: EDIT_RISING_STAR_TASK,
      variables: { task: action.payload.task, firebaseId: action.payload.hash },
    });
    yield put(
      actions.receiveEditedRisingStarTask(data.data.data.updateRisingStarTask)
    );
    yield put(goBack());
  } catch (error) {
    yield put(actions.editRisingStarTaskError(error as Error));
  }
}

const EDIT_RISING_STAR_TASK = gql`
  mutation updateRisingStarTask($task: InputTask!, $firebaseId: String!) {
    updateRisingStarTask(task: $task, firebaseId: $firebaseId) {
      id
      title
      dueDate
      description
      completed
      assignmentLink
      notes
      resources
    }
  }
`;

function* completeMentorshipTask(
  action: ActionType<typeof actions.completeTask>
) {
  try {
    const data: AxiosResponse<{
      data: {
        completeTask: MentorshipTask;
      };
    }> = yield call(axiosGqlRequest, {
      query: SET_MENTORSHIP_TASK_COMPLETED,
      variables: action.payload,
    });
    yield put(actions.receiveCompletedTask(data.data.data.completeTask));
  } catch (error) {
    yield put(actions.completeTaskError(error as Error));
  }
}

const SET_MENTORSHIP_TASK_COMPLETED = gql`
  mutation completeTask($id: Int!) {
    completeTask(id: $id) {
      id
      title
    }
  }
`;

function* completeRisingStarTask(
  action: ActionType<typeof actions.completeRisingStarTask>
) {
  try {
    const data: AxiosResponse<{
      data: {
        completeRisingStarTask: MentorshipTask;
      };
    }> = yield call(axiosGqlRequest, {
      query: SET_RISING_STAR_TASK_COMPLETED,
      variables: action.payload,
    });
    yield put(
      actions.receiveCompletedRisingStarTask(
        data.data.data.completeRisingStarTask
      )
    );
  } catch (error) {
    yield put(actions.completeRisingStarTaskError(error as Error));
  }
}

const SET_RISING_STAR_TASK_COMPLETED = gql`
  mutation completeRisingStarTask($id: Int!, $studentId: Int!) {
    completeRisingStarTask(id: $id, studentId: $studentId) {
      id
      title
    }
  }
`;

function* deleteMentorshipTask(
  action: ActionType<typeof actions.deleteMentorshipTask>
) {
  try {
    const data: AxiosResponse<{
      data: {
        deleteMentorshipTask: { id: number };
      };
    }> = yield call(axiosGqlRequest, {
      query: DELETE_MENTORSHIP_TASK,
      variables: action.payload,
    });
    yield put(
      actions.receiveDeletedMentorshipTask(data.data.data.deleteMentorshipTask)
    );
  } catch (error) {
    yield put(actions.deleteMentorshipTaskError(error as Error));
  }
}

const DELETE_MENTORSHIP_TASK = gql`
  mutation deleteMentorshipTask($id: Int!) {
    deleteMentorshipTask(id: $id) {
      id
    }
  }
`;

function* deleteRisingStarTask(
  action: ActionType<typeof actions.deleteRisingStarTask>
) {
  try {
    const data: AxiosResponse<{
      data: { deleteRisingStarTask: { id: number } };
    }> = yield call(axiosGqlRequest, {
      query: DELETE_RISING_STAR_TASK,
      variables: action.payload,
    });
    yield put(
      actions.receiveDeletedRisingStarTask(data.data.data.deleteRisingStarTask)
    );
  } catch (error) {
    yield put(actions.deleteRisingStarTaskError(error as Error));
  }
}

const DELETE_RISING_STAR_TASK = gql`
  mutation deleteRisingStarTask($id: Int!) {
    deleteRisingStarTask(id: $id) {
      id
    }
  }
`;

function* watchAddMentorshipTask() {
  yield takeEvery("ADD_MENTORSHIP_TASK", addMentorshipTask);
}

function* watchFetchMentorshipTask() {
  yield takeEvery("FETCH_MENTORSHIP_TASK", fetchMentorshipTask);
}

function* watchEditMentorshipTask() {
  yield takeEvery("EDIT_MENTORSHIP_TASK", editMentorshipTask);
}

function* watchCompleteMentorshipTask() {
  yield takeEvery("COMPLETE_MENTORSHIP_TASK", completeMentorshipTask);
}

function* watchDeleteMentorshipTask() {
  yield takeEvery("DELETE_MENTORSHIP_TASK", deleteMentorshipTask);
}

function* watchAddRisingStarTask() {
  yield takeEvery("ADD_RISING_STAR_TASK", addRisingStarTask);
}

function* watchDeleteRisingStarTask() {
  yield takeEvery("DELETE_RISING_STAR_TASK", deleteRisingStarTask);
}

function* watchCompleteRisingStarTask() {
  yield takeEvery("COMPLETE_RISING_STAR_TASK", completeRisingStarTask);
}

function* watchFetchRisingStarTask() {
  yield takeEvery("FETCH_RISING_STAR_TASK", fetchRisingStarTask);
}

function* watchEditRisingStarTask() {
  yield takeEvery("EDIT_RISING_STAR_TASK", editRisingStarTask);
}

export default function* rootSaga() {
  yield all([
    watchAddMentorshipTask(),
    watchAddRisingStarTask(),
    watchFetchMentorshipTask(),
    watchFetchRisingStarTask(),
    watchEditMentorshipTask(),
    watchEditRisingStarTask(),
    watchCompleteMentorshipTask(),
    watchCompleteRisingStarTask(),
    watchDeleteMentorshipTask(),
    watchDeleteRisingStarTask(),
  ]);
}
